import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListSetting,
    FetchSettings,
    EditSettingsData,
    AddPendingApprovals,
    FetchMastersLog,
    ListCustdata, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListSettings = () => {
    const [hasError, setErrors] = useState(false);
    const [modalIn, setModalIn] = useState(false);
    const [settings, setSettings] = useState([]);
    const [selsettingsid, setSelmstatusid] = useState('');
    const [selectedsettings, setSelectedsettings] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [searchval, setSearchVal] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [valuesloader, setValuesloader] = useState(false);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {settingskey: '', keyvalue:'' , updatedsettings: {"status": "pending"}}
    );

    const settingsdata = useSelector(state => state.UserReducer.listsettingsdata) ;
    const selsettingsdata = useSelector(state => state.UserReducer.fetchsettingsdata) ;
    const updsettingsdata = useSelector(state => state.UserReducer.updsettingsdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(ListSetting(searchval)) ;
    },[dispatch]);

    useEffect(() => {
        setValuesloader(false);
        setSettings(settingsdata)
    },[settingsdata]);

    useEffect(() => {
        setInputValues({ "updatedsettings" : updsettingsdata });
    },[updsettingsdata]);

    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        setValuesloader(true);
        dispatch( ListSetting(searchval));
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    }


    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);
    useEffect(() => {

        //console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);

            }
        }
    },[newdatarow]);


    useEffect(() => {
        setSelectedsettings(selsettingsdata)
        if(selsettingsdata.length > 0 ){
            setInputValues({ "settingskey" : selsettingsdata[0].Key, "keyvalue" : selsettingsdata[0].Value, "updatedsettings" : {"status": "pending"} });
        }

    },[selsettingsdata]);


    const toggleModal = (id) => {
        console.log(id) ;
        setStatus(false);
        setButtonStatus(false);
        if(id == 0){
            dispatch(ListSetting(searchval)) ;
        }else{
            setSelmstatusid(id) ;
            setStatusloader(true);
            dispatch(FetchSettings(id)) ;
            dispatch(FetchMastersLog('Settings',id)) ;

        }
        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "settingskey" : '', "keyvalue" : '', "updatedsettings":[]});
    }
    const submitForm = () => {
        //dispatch(EditSettingsData(selsettingsid,inputValues.settingskey,inputValues.keyvalue)) ;

        setStatusloader(true);
        const myObj = {
            "id": selsettingsid,
            "Key": inputValues.settingskey,
            "Value":inputValues.keyvalue
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        const sevice = 'Settings:'+inputValues.settingskey
        setSubmit(true);
        dispatch(AddPendingApprovals(sevice,enc_myObjStr,user,'Update',selsettingsid)) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

    console.log(inputValues) ;
    return (
        <React.Fragment>
            <Pagesidebar menu="System Settings" mainmenu="System Settings" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="System Settings"  filter="No" search="Yes"   filterlist={filterlist}  searchChange = {handleChange} searchValChange = {searchValChange} searchLabel = {'Search by key'} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Settings</h2>
                        {/*<Link to="/add-settings" className="btn btn--primaryG btn--capsule">(+) Settings</Link>*/}
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Key
                                </th>
                                <th>
                                    Value
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            {settings && settings.length > 0 ?
                                <tbody>
                                {settings && settings.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>

                                                {index + 1}
                                            </td>
                                            <td>
                                                {item.Key}
                                            </td>
                                            <td>
                                                {item.Value}
                                            </td>
                                            <td>
                                                {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '6' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                                <span className="iconEdit" onClick={() => toggleModal(item.id)}/>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody> :
                                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Settings</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Key</label>
                                <input value={inputValues.settingskey} name="settingskey" className="csForm__item__input" onChange={handleInput} type="text" disabled/>

                            </div>

                            <div className="csForm__item">
                                <label className="csForm__item__label">Value</label>
                                <input value={inputValues.keyvalue} name="keyvalue" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>


                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&
                            <div className="flexCC">
                                {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                    <span className="messageIn">Waiting for Approval</span>
                                }
                            </div>
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListSettings
