import React, {useEffect, useReducer, useState} from 'react';
import {Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import logo from '../assets/images/logo.png';
import user from "../assets/images/user.png";
import {FetchMenu } from "../actions/Menu";
import  {SidebarData} from "../data/menu"
import {getToken,getUrlSegment} from "../utilities/AuthService";

import {ListNewCount, ListDashboard, ListRolesectionPermission} from "../actions/User";


const Pagesidebar = (props) => {
    const [sidebar, setSidebar] = useState(false);
    const [version, setVersion] = useState(false);
    const [menu, setMenu] = useState('Roles');
    const [mastermenu, setMastermenu] = useState('Masters');
    const [selectedmenu, setSelectedMenu] = useState(props.mainmenu);
    const [selectedsubmenu, setSelectedSubMenu] = useState(props.menu);
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);
    const [roleid, setRoleid] = useState(getToken().roleid);
    const [pagecount, setPagecount] = useState([]);

    /*  const [userrole, setUserrole] = useState("Admin");  */
    const [menuItems, setMenuItems] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {menu: '', master: '' }
    );


    const dispatch = useDispatch();
    const menudata = useSelector(state => state.MenuReducer.fetchmenudata) ;
    const dashboarddata = useSelector(state => state.UserReducer.dashboarddata) ;
    const listcounts = useSelector(state => state.UserReducer.listcounts) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;

    useEffect(() => {
        dispatch(ListNewCount(userid)) ;
        dispatch(FetchMenu(menu,mastermenu)) ;
        dispatch(ListNewCount(userid)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;
        console.log(menu+" "+mastermenu) ;
    }, dispatch);



    useEffect(() => {
        if(menudata !== undefined){
            setMenuItems({ "menu" : menudata.cmenu, "master" : menudata.pmenu});
        }

    }, [menudata]);
    const activeSet = (menu) => {
        console.log(menu);
        console.log(selectedmenu);
        if(selectedmenu=== menu ){
            console.log(878788);
            setSelectedMenu('');
        }else{
            setSelectedMenu(menu);
        }
    }
    const activeSetSubenu = (submenu='') => {
        setSelectedSubMenu(submenu);
    }

    // const activeSet = (idIn) => {
    //     var items_in = document.querySelectorAll('.iconMasters');
    //     if (document.getElementById(`listItem-`+idIn).classList.contains('active')) {
    //         document.getElementById(`listItem-`+idIn).classList.remove('active');
    //  //       return false
    //     }
    //     else {
    //         // let pendingCount = kycCount ? kycCount.filter(item => item.status === "Pending")[0] : 0;
    //         // let approvedCount = kycCount ? kycCount.filter(item => item.status === "Approved")[0] : 0;
    //         // let rejectedCount = kycCount ? kycCount.filter(item => item.status === "Rejected")[0] : 0;
    //         // let forwardedCount = kycCount ? kycCount.filter(item => item.status === "Forward")[0] : 0;
    //         // let onholdCount = kycCount ? kycCount.filter(item => item.status === "On Hold")[0] : 0;
    //         // setPagecount({ "Pending" : pendingCount ? pendingCount.count : 0, "Approved" : approvedCount ? approvedCount.count : 0 , "Rejected" : rejectedCount ? rejectedCount.count : 0, "Forwarded" : forwardedCount ? forwardedCount.count : 0, "On Hold" : onholdCount ? onholdCount.count : 0});
    //         for (var i=0; i< items_in.length; i++) {
    //             items_in[i].classList.remove('active');
    //         }
    //         document.getElementById(`listItem-`+idIn).classList.add('active');
    //     }
    // }

    useEffect(() => {
        console.log("userrole: "+userrole) ;
        dispatch(ListDashboard(userrole)) ;
    },[]);

    const setmenuItem = (item) =>{
        setMenu(item);
        setMastermenu('Masters');
        console.log(menu+" "+mastermenu) ;
    }
    const changeMenu = () =>{
        setSidebar(!sidebar) ;
        props.changeMenu();
    }
    const showVersion = () =>{
       // window.location.href = ('/dashboard');
        setVersion(!version) ;
    }

    let kycCount = dashboarddata[0] !== undefined && dashboarddata[0].nonstp !== undefined ? dashboarddata[0].nonstp.result : [];
    useEffect(() => {
        if(userrole === 'Admin') {
            let pendingCount = kycCount ? kycCount.filter(item => item.status === "Pending")[0] : 0;
            let approvedCount = kycCount ? kycCount.filter(item => item.status === "Approved")[0] : 0;
            let rejectedCount = kycCount ? kycCount.filter(item => item.status === "Rejected")[0] : 0;
            let forwardedCount = kycCount ? kycCount.filter(item => item.status === "Forward")[0] : 0;
            let onholdCount = kycCount ? kycCount.filter(item => item.status === "On Hold")[0] : 0;
            setPagecount({
                "Pending": pendingCount ? pendingCount.count : 0,
                "Approved": approvedCount ? approvedCount.count : 0,
                "Rejected": rejectedCount ? rejectedCount.count : 0,
                "Forwarded": forwardedCount ? forwardedCount.count : 0,
                "On Hold": onholdCount ? onholdCount.count : 0
            });
        } else {
            let kycCount = listcounts ? listcounts : [];
            console.log(kycCount);
            setPagecount({
                "Pending":  kycCount.pendingcount ? kycCount.pendingcount : 0,
                "Approved":  kycCount.approvedcount ?  kycCount.approvedcount : 0,
                "Rejected":  kycCount.rejectedcount ? kycCount.rejectedcount : 0,
                "Forwarded":  kycCount.forwdedcount ?  kycCount.forwdedcount : 0,
                "On Hold": kycCount.onholdcount ?  kycCount.onholdcount : 0
            });
        }

    },[kycCount,userrole] );
    useEffect(()=>{
        let sidebar = SidebarData ? SidebarData[1].submenus : [];
        if(userrole === 'Maker') {
            let docIndex = sidebar.findIndex(obj => obj.title === 'Forwarded');
            if (docIndex > -1) {
                sidebar.splice(docIndex, 1);
            }
        }
        if(userrole === 'Checker') {
            let docIndex = sidebar.findIndex(obj => obj.title === 'Pending');
            if (docIndex > -1) {
                sidebar.splice(docIndex, 1);
            }
        }
        }

    )

console.log(selectedmenu);
console.log(props.mainmenu);
console.log(props.menu);
console.log(selectedsubmenu);
// console.log(rolesectiondata);
console.log(userrole);
    return (
        <React.Fragment>
            {rolesectiondata !== undefined &&


            <div className="pageSidebar" className={`pageSidebar ${props.showsidebar ? "show" : "hide"} `}>
                <Link onClick={() => showVersion()} className="logoIn">
                    <img src={logo} className="logo__image"/>

                </Link>
                <ul className="sidebarList toggleSideBar__contents">
                    <li id="listItem-0" onClick={() => activeSet('Dashboard')}
                        className={`hasInnerList ${(selectedmenu) === 'Dashboard' ? "activeIn" : ""}  closed`}>
                        <Link to="/dashboard">{'Dashboard'}</Link></li>
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '2' && (item.actionId === '1' || item.actionId === '2'|| item.actionId === '3')) >-1)) &&
                    <>
                        <li id="listItem-1" onClick={() => activeSet('ReKYC')} menu={selectedmenu}
                            className={`iconMasters ${selectedmenu == 'ReKYC' ? "active" : ""}  `}>
                            {'ReKYC'}</li>
                        <ul>
                            {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '2' && item.actionId === '1' )) > -1) &&
                            <li className={`hasInnerList ${(props.menu) === 'Pending' ? "activeIn" : ""} `}>
                                <Link to="/rekyc?status=pending"
                                      onClick={() => activeSetSubenu
                                      ('Pending')}>{'Pending(' + pagecount['Pending'] + ')'}
                                </Link>
                            </li>
                            }

                            <li className={`hasInnerList ${(selectedsubmenu) === 'Approved' ? "activeIn" : ""} `}>
                                <Link to="/rekyc?status=approved"
                                      onClick={() => activeSetSubenu
                                      ('Approved')}>{'Approved(' + pagecount['Approved'] + ')'}
                                </Link>
                            </li>
                            {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '2' && item.actionId === '2')) > -1) &&
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Forwarded' ? "activeIn" : ""} `}>
                                <Link to="/rekyc?status=forward"
                                      onClick={() => activeSetSubenu
                                      ('Forwarded')}>{'Forwarded(' + pagecount['Forwarded'] + ')'}
                                </Link>
                            </li>
                            }
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Rejected' ? "activeIn" : ""} `}>
                                <Link to="/rekyc?status=rejected"
                                      onClick={() => activeSetSubenu
                                      ('Rejected')}>{'Rejected(' + pagecount['Rejected'] + ')'}
                                </Link>
                            </li>
                            {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '2' && item.actionId === '1' )) > -1) &&
                            <li className={`hasInnerList ${(selectedsubmenu) === 'On Hold' ? "activeIn" : ""} `}>
                                <Link to="/rekyc?status=onhold"
                                      onClick={() => activeSetSubenu
                                      ('On Hold')}>{'Return to Customer(' + pagecount['On Hold'] + ')'}
                                </Link>
                            </li>
                        }
                        </ul>
                    </>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '3') > -1)) &&

                    <li id="listItem-2" onClick={() => activeSet('Incomplete')}
                        className={`hasInnerList ${(selectedmenu) === 'Incomplete' ? "activeIn" : ""}  closed`}>
                        <Link to="/incompletestage">{'Incomplete'}</Link></li>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '4') > -1)) &&
                    <>
                        <li id="listItem-3" onClick={() => activeSet('Administration')} menu={selectedmenu}
                        className={`iconMasters ${selectedmenu == 'Administration' ? "active" : ""}  `}>
                        {'Administration'}</li>
                        <ul>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'Roles' ? "activeIn" : ""} `}>
                        <Link to="/list-roles"
                        onClick={() => activeSetSubenu
                        ('Roles')}>{'Roles'}
                        </Link>
                        </li>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'Users' ? "activeIn" : ""} `}>
                        <Link to="/list-users"
                        onClick={() => activeSetSubenu
                        ('Users')}>{'Users'}
                        </Link>
                        </li>
                        </ul>
                    </>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5') > -1)) &&
                    <>
                        <li id="listItem-4" onClick={() => activeSet('Masters')} menu={selectedmenu}
                            className={`iconMasters ${selectedmenu == 'Masters' ? "active" : ""}  `}>
                            {'Masters'}</li>
                        <ul>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Country' ? "activeIn" : ""} `}>
                                <Link to="/list-country"
                                      onClick={() => activeSetSubenu
                                      ('Country')}>{'Country'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Status' ? "activeIn" : ""} `}>
                                <Link to="/list-status"
                                      onClick={() => activeSetSubenu
                                      ('Status')}>{'Status'}
                                </Link>
                            </li>

                            <li className={`hasInnerList ${(selectedsubmenu) === 'Marital Status' ? "activeIn" : ""} `}>
                                <Link to="/list-maritalstatus"
                                      onClick={() => activeSetSubenu
                                      ('Marital Status')}>{'Marital Status'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Income Range' ? "activeIn" : ""} `}>
                                <Link to="/list-monthlyincome"
                                      onClick={() => activeSetSubenu
                                      ('Income Range')}>{'Income Range'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Unemployment' ? "activeIn" : ""} `}>
                                <Link to="/list-unemployedstatuses"
                                      onClick={() => activeSetSubenu
                                      ('Unemployment')}>{'Unemployment'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Employment Status' ? "activeIn" : ""} `}>
                                <Link to="/list-employmentstatuses"
                                      onClick={() => activeSetSubenu
                                      ('Employment Status')}>{'Employment Status'}
                                </Link>
                            </li>
                        </ul>
                    </>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '6') > -1)) &&
                    <li id="listItem-5" onClick={() => activeSet('System Settings')}
                        className={`hasInnerList ${(selectedmenu) === 'System Settings' ? "activeIn" : ""}  closed`}>
                        <Link to="/list-settings">{'System Settings'}</Link></li>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '7') > -1)) &&
                    <>
                        <li id="listItem-6" onClick={() => activeSet('Approval List')} menu={selectedmenu}
                            className={`iconMasters ${selectedmenu == 'Approval List' ? "active" : ""}  `}>
                            {'Masters Approval'}</li>
                        <ul>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Pending' ? "activeIn" : ""} `}>
                                <Link to="/list-masterslog?status=pending"
                                      onClick={() => activeSetSubenu
                                      ('Pending')}>{'Pending'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Approved' ? "activeIn" : ""} `}>
                                <Link to="/list-masterslog?status=approved"
                                      onClick={() => activeSetSubenu
                                      ('Approved')}>{'Approved'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'Rejected' ? "activeIn" : ""} `}>
                                <Link to="/list-masterslog?status=rejected"
                                      onClick={() => activeSetSubenu
                                      ('Rejected')}>{'Rejected'}
                                </Link>
                            </li>
                        </ul>
                    </>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '9') > -1)) &&
                    <>
                        <li id="listItem-6" onClick={() => activeSet('System Approval')} menu={selectedmenu}
                            className={`iconMasters ${selectedmenu == 'System Approval' ? "active" : ""}  `}>
                            {'System Settings Approval'}</li>
                        <ul>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'SystemPending' ? "activeIn" : ""} `}>
                                <Link to="/list-systemslog?status=pending"
                                      onClick={() => activeSetSubenu
                                      ('SystemPending')}>{'Pending'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'SystemApproved' ? "activeIn" : ""} `}>
                                <Link to="/list-systemslog?status=approved"
                                      onClick={() => activeSetSubenu
                                      ('SystemApproved')}>{'Approved'}
                                </Link>
                            </li>
                            <li className={`hasInnerList ${(selectedsubmenu) === 'SystemRejected' ? "activeIn" : ""} `}>
                                <Link to="/list-systemslog?status=rejected"
                                      onClick={() => activeSetSubenu
                                      ('SystemRejected')}>{'Rejected'}
                                </Link>
                            </li>
                        </ul>
                    </>
                    }
                    {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '8') > -1)) &&
                    <>
                    <li id="listItem-7" onClick={() => activeSet('Reports')} menu={selectedmenu}
                        className={`iconMasters ${selectedmenu == 'Reports' ? "active" : ""}  `}>
                        {'Reports'}</li>
                    <ul>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'STP' ? "activeIn" : ""} `}>
                            <Link to="/stp-reports"
                                  onClick={() => activeSetSubenu
                                  ('STP')}>{'STP'}
                            </Link>
                        </li>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'NON STP' ? "activeIn" : ""} `}>
                            <Link to="/non-stp-reports"
                                  onClick={() => activeSetSubenu
                                  ('NON STP')}>{'NON STP'}
                            </Link>
                        </li>
                        <li className={`hasInnerList ${(selectedsubmenu) === 'LID' ? "activeIn" : ""} `}>
                            <Link to="/lid-reports"
                                  onClick={() => activeSetSubenu
                                  ('LID')}>{'LID'}
                            </Link>
                        </li>
                    </ul>
                        </>
                    }


                </ul>

                {version &&
                <p className="flexCE p30">v37</p>
                }
            </div>
            }
        </React.Fragment>

    )
}
export default Pagesidebar
