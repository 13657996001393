
export  const SidebarData = [
  {
    title : "Dashboard",
    key : "Dashboard"
  },
  {
    title : "ReKYC/LegalID",
    key : "Rekyc",
    "roles" : ["Admin", "Maker","Checker"],
    submenus:[
     {"title" : "Pending" , "url": "/rekyc?status=pending" },
     {"title" : "Approved" , "url": "/rekyc?status=approved" },
     {"title" : "Forwarded" , "url": "/rekyc?status=forward" },
     {"title" : "Rejected" , "url": "/rekyc?status=rejected" },
     {"title" : "On Hold" , "url": "/rekyc?status=onhold" }
   ]
  },
  {
    title : "Incomplete",
    key : "Incomplete",
    roles : ["Admin"]
  },
  {
    title : "Administration",
    key:"Admin",
    roles : ["Admin"],
    submenus:[
      {"title" : "Roles" , "url": "/list-roles"} ,
      {"title" : "Users" , "url":  "/list-users"},
    ]
  },
  {
    title : "Masters",
    key:"Masters",
    roles : ["Admin"],
    submenus:[
      {"title" : "Country" , "url": "/list-country" } ,
      {"title" : "Status" , "url": "/list-status" } ,
      {"title" : "Marital Status" , "url":  "/list-maritalstatus"},
      {"title" : "Income Range" , "url":  "/list-monthlyincome"},
      {"title" : "Unemployment" , "url":  "/list-unemployedstatuses"},
      {"title" : "Employment Status" , "url":  "/list-employmentstatuses"}
    ]
  },
  {
    title : "System Settings",
    key : "Settings",
    roles : ["Admin"]
  },
  {
    title : "Approval List",
    key:"Approval",
    roles : ["Admin","Checker"],
    submenus:[
      {"title" : "Pending" , "url": "/list-masterslog?status=pending"  } ,
      {"title" : "Approved" , "url": "/list-masterslog?status=approved" } ,
      {"title" : "Rejected" , "url":  "/list-masterslog?status=rejected" },

    ]
  },
  {
    title : "Reports",
    key:"Reports",
    roles : ["Admin"],
    submenus:[
      {"title" : "STP" , "url": "/stp-reports"  } ,
      {"title" : "NON STP" , "url": "/non-stp-reports" },
      {"title" : "LID" , "url": "/lid-reports" }
    ]
  }

];
