import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListUserRoles,
    FetchUserRole,
    EditUserRole,
    AddPendingApprovals,
    ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";

const ListRoles = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [groups, setGroups] = useState([{"label": "REKYC_ADMIN_TEST", "value": "REKYC_ADMIN_TEST"},{"label": "REKYC_SALES_TEST", "value": "REKYC_SALES_TEST"},{"label": "REKYC_MAKER_TEST", "value": "REKYC_MAKER_TEST"},{"label": "REKYC_CHECKER_TEST", "value": "REKYC_CHECKER_TEST"}]);
    const [modalIn, setModalIn] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selroleid, setSelroleid] = useState('');
    const [selectedroles, setSelectedroles] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);

    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [searchval, setSearchVal] = useState('' );
    const [submit, setSubmit] = useState(false );
    const [btnstatus, setButtonStatus] = useState(false );

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '', seldeleted: '',rolename: '', updatedrole: {"status": "pending"} }
    );

    const rolesdata = useSelector(state => state.UserReducer.listrolesdata) ;
    const selroledata = useSelector(state => state.UserReducer.fetchroledata) ;
 /*   const updroledata = useSelector(state => state.UserReducer.updroledata) ;  */

    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ListUserRoles(searchval)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    },[dispatch]);

    useEffect(() => {
        setRoles(rolesdata)
    },[rolesdata]);


    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
            }
        }


    },[newdatarow]);

    useEffect(() => {
        setSelectedroles(selroledata)
         if(selroledata.length >0 ){
             if(selroledata[0].IsActive !== undefined){
                 setInputValues({ "selactive" : selroledata[0].IsActive, "seldeleted" : selroledata[0].IsDeleted, "rolename": selroledata[0].RoleName, "updatedrole" : {"status": "pending"} });

             }
         }
    },[selroledata]);


    const toggleModal = (roleid) => {
        setButtonStatus(false);
        setStatus(false);

        if(roleid == 0){
            dispatch(ListUserRoles(searchval)) ;
        }else{
            setSelroleid(roleid) ;
            dispatch(FetchUserRole(roleid)) ;
        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "selactive" : '', "seldeleted" : '', "rolename": '', "updatedrole" : [] });
    }
    const submitForm = () => {
        /* dispatch(EditUserRole(selroleid,inputValues.rolename,inputValues.selactive,inputValues.seldeleted)) ;  */

        const myObj = {
            "RoleId": selroleid,
            "RoleName": inputValues.rolename,
            "IsActive": inputValues.selactive,
            "IsDeleted":inputValues.seldeleted
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('Roles:'+inputValues.rolename,enc_myObjStr,user,'Update',selroleid)) ;
    }


    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        dispatch(ListUserRoles(searchval)) ;
    }
    console.log(getToken().role);
    console.log(rolesectiondata);
    console.log(roles);
    return (
        <React.Fragment>
                <>
            <Pagesidebar menu="Roles" mainmenu="Administration" showsidebar={showsidebar}/>
                <div className="pageSidebarOffset">
                <navbar className="pageNav">
                <Navbar pagettl="Administration"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar} searchLabel = {'Search by role'} changeNav={changeNav} searchValChange = {searchValChange}/>
                </navbar>



                <div className="roundBox">
                <div className="roundBox__head">
                <h2>Roles</h2>
            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '4'  && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                <Link to="/add-role" className="btn btn--primaryG btn--capsule">(+) Role</Link>
            }
                </div>

                <div className="tableWrapper scrollable">
                <table className="csTable">
                <thead>
                <tr>
                <th>

                Sl No
                </th>
                <th>
                Role
                </th>
                <th>
                Status
                </th>
                <th></th>
                <th></th>

                </tr>
                </thead>
            {roles !== undefined && roles.length > 0 ?

                <tbody>
            {roles.length > 0 && roles.map((item, index) => {
                return (
                <tr>
                <td>

            {index + 1}
                </td>
                <td>
            {item.RoleName}
                </td>
                <td>
            {item.IsActive === "1" ? 'Active' : 'Inactive'}
                </td>

                <td>
                    {(item.RoleName.toLowerCase() !== 'admin' && (getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '4' && (item.actionId === '1' || item.actionId === '2')) > -1))) &&
                    <span className="iconEdit" onClick={() => toggleModal(item.RoleId)}/>
                    }
                </td>
                <td>
            {(item.RoleName.toLowerCase() !== 'admin' && getToken().role === 'Admin' )&&
                <Link to={`/setpermission/?id=${item.RoleId}`}> <span
                className="btn btn--primaryG btn--sm btn--capsule">Set Permission</span>
                </Link>
            }
                </td>


                </tr>
                )
            })}

                </tbody> :
                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
            }
                </table>
                </div>
                </div>
                </div>
            {
                modalIn && (

                <div className={`csModal csModal--sm csModal--active`}>
                <div className="csModal__content">
                <span className="csModal__out" onClick={() => toggleModal('0')}/>

                <h3>Edit Role</h3>


                <div className="csForm__item">
                <label className="csForm__item__label">Rolename</label>
                <input value={inputValues.rolename} name="rolename" className="csForm__item__input" onChange={handleInput} type="text" />

                </div>

                {/*<Selectbox  label="Group Name" name="groupname"  values={groups}   onChange={handleChange}  selvalue={inputValues.groupname} />*/}
                <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />
                <Selectbox  label="Is Deleted"  name="seldeleted" values={yesnovalue}  onChange={handleChange} selvalue={inputValues.seldeleted}  />


            {status === "success"  ? (
                <span className="messageIn">Successfully updated the role</span>
                ) : ''
            }
            {!btnstatus &&

                <div className="flexCC">

                <span className="btn btn--primaryG btn--sm btn--capsule"
                onClick={() => submitForm()}>UPDATE</span>


                </div>
            }
                </div>
                </div>
                )
            }
            </>


        </React.Fragment>
    )
}

export default ListRoles
