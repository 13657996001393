import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import Lock from '../assets/images/lock.png'
import {
    ListCustdata,
    ListMoreCustdata,
    ekycLockUpdates,
    updateRecordLockStatus,
    CreateAccessLog
} from "../actions/User";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken, getUrlSegment} from "../utilities/AuthService";
import Selectbox from "../components/Selectbox";
import Loader from "../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ListReKYC = () => {
    const [hasError, setErrors] = useState(false);
    const [sortbyvalue, setSortbyvalue] = useState([{"label": "Newest", "value": "newest"},{"label": "Oldest", "value": "oldest"}]);
    const [approvedbyvalue, setApprovedbyvalue] = useState([{"label": "All", "value": "all"},{"label": "First Approval", "value": "maker"},{"label": "Second Approval", "value": "checker"}]);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "Name", "value": "name"},{"label": "CIF", "value": "cif"},{"label": "Mobile", "value": "mobile"},{"label": "Reference ID", "value": "refno"}]);
    const [sortbyfilter, setSortbyfilter] = useState([{"label": "All", "value": "0"},{"label": "Today", "value": "1"},{"label": "Last 7 days", "value": "7"},{"label": "Last 30 Days", "value": "30"},{"label": "Last 3 months", "value": "3"},{"label": "Last 6 months", "value": "6"},{"label": "Custom", "value": "custom"}]);
    const [modalIn, setModalIn] = useState(false);
    const [custdata, setCustdata] = useState([]);
    const [selmincomeid, setSelmincomeid] = useState('');
    const [selectedmincome, setSelectedmincome] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);
    const [selmenu, setSelmenu] = useState('');
    const [perpage, setPerpage] = useState('10');
    const [statusloader, setStatusloader] = useState(false);
    const [pageval, setPageVal] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [customDate, setCustomDate] = useState(false);
    //const [page, setPage] = useState('1');

    const [inputValues, _setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {crs: '', pep: '', ustaxresident: '', customTyp1 : '', customTyp2 : '', customTyp3 : '', sortby:'', dateFilter:0 }
    );

    const myStateRef = React.useRef(inputValues);
    const setInputValues = data => {
        let values = inputValues;
        for (var key in data) {
            values[key] = data[key];
        }
        myStateRef.current = values;
        _setInputValues(values);
    };


    const listcustdata = useSelector(state => state.UserReducer.listcustdata) ;
    const listcustcount = useSelector(state => state.UserReducer.listcustcount) ;
  //  let menuStatus = getUrlSegment(2);
    const params = (new URL(document.location)).searchParams  ;
    const menuStatus = params.get("status") ? params.get("status") : 'pending'  ;
    console.log(menuStatus) ;

    let history = useHistory();
    const dispatch = useDispatch();
    let page = 1;
    let searchVal = '';
    let searchBy = '';

    useEffect(() => {
        console.log("userid: "+userid) ;
        const container = document.querySelector('.scrollable');
        container.scrollTop = 0;
        container.removeEventListener("scroll", listenToScroll);
        let selmenu ='';
        if(menuStatus === 'pending'){
            selmenu = 'Pending';
        } else if(menuStatus === 'approved'){
            selmenu = 'Approved';
        }
        else if(menuStatus === 'forward'){
            selmenu = 'Forward';
        }
        else if(menuStatus === 'rejected'){
            selmenu = 'Rejected'
        }
        else if(menuStatus === 'onhold'){
            selmenu = 'On Hold'
        }
        page = 1;
        changeMenu();
        // const container = document.querySelector('.scrollable');
        // container.addEventListener('scroll', listenToScroll());

        let cif ='';
        let sectionmsg= selmenu+' Record Listed';
        dispatch(CreateAccessLog(cif,sectionmsg,userid));
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    },[dispatch,menuStatus]);

    useEffect(() => {
        setStatusloader(false);
        setCustdata(listcustdata)
    },[listcustdata]);

    useEffect(() => {
        let selmenu ='';
        if(menuStatus === 'pending'){
            selmenu = 'Pending';
        } else if(menuStatus === 'approved'){
            selmenu = 'Approved';
        }
        else if(menuStatus === 'forward'){
            selmenu = 'Forward';
        }
        else if(menuStatus === 'rejected'){
            selmenu = 'Rejected'
        }
        else if(menuStatus === 'onhold'){
            selmenu = 'On Hold'
        }
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        setInterval(() => {
            dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues));
        }, 180000);

    },[]);




    const listenToScroll = () => {
        const container = document.querySelector('.scrollable')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);


        if (contentHeight <= scrollTop) {
            page = parseInt(page) + 1;
            const params = (new URL(document.location)).searchParams  ;
            let menuStatus = params.get("status") ? params.get("status") : 'pending'  ;
            console.log(menuStatus) ;

            //setPage(currentPage);
            let selmenu ='';
            if(menuStatus === 'pending'){
                selmenu = 'Pending';
            } else if(menuStatus === 'approved'){
                selmenu = 'Approved';
            }
            else if(menuStatus === 'forward'){
                selmenu = 'Forward';
            }
            else if(menuStatus === 'rejected'){
                selmenu = 'Rejected'
            }
            else if(menuStatus === 'onhold'){
                selmenu = 'On Hold'
            }
            changeMenu();

            inputValues['searchval'] = searchVal;
            inputValues['searchby'] = searchBy;
            const myinputValues = JSON.stringify(myStateRef.current);
            const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(ListMoreCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues),page) ;
        }
    }


    useEffect(() => {
            const container = document.querySelector('.scrollable');
            container.addEventListener("scroll", listenToScroll);
            return () => container.removeEventListener("scroll", listenToScroll);

    }, [selmenu]);



    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const changeMenu = () => {

        const params = (new URL(document.location)).searchParams  ;
        let menuStatus = params.get("status") ? params.get("status") : 'pending'  ;
        console.log(menuStatus) ;

        let selmenu ='';
        if(menuStatus === 'pending'){
            selmenu = 'Pending';
        } else if(menuStatus === 'approved'){
            selmenu = 'Approved';
        }
        else if(menuStatus === 'forward'){
            selmenu = 'Forwarded';
        }
        else if(menuStatus === 'rejected'){
            selmenu = 'Rejected'
        }
        else if(menuStatus === 'onhold'){
            selmenu = 'On Hold'
        }
        setSelmenu(selmenu);
    }
    const handleChange = (value,name) =>{

        if(name === 'searchby'){
            searchBy = value;
        }
        setInputValues({ [name]: value });
    }

    const searchValChange =(searchval)=>{
        setInputValues({ "searchval": searchval }) ;
        inputValues['searchval'] = searchval;
        searchVal = searchval;
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    }
    const handleInput = (e) =>{

        const inputTypes = ['crs','pep','ustaxresident','customTyp1','customTyp2','customTyp3','sortby'];
        if(inputTypes.includes(e.target.name)){
            if(inputValues[e.target.name] === 'on') {
                setInputValues({[e.target.name]: 'off'});
            } else {
                setInputValues({[e.target.name]: 'on'});
            }
        } else {
            setInputValues({ [e.target.name]: e.target.value }) ;
        }
    }
    const handledateFilter = (value) => {
        const val = ['1', '7', '30'];
        if(value !== "0" && value != "custom") {
            setInputValues({ "startDate": '',"endDate": ''}) ;
            setCustomDate(false);
            if (val.indexOf(value) > -1) {
            setInputValues({"filterCat": 'day'});
            } else {
                setInputValues({"filterCat": 'month'});
            }
            setInputValues({"dateFilter": value});
            setStatusloader(true);
            const myinputValues = JSON.stringify(inputValues);
            const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(ListCustdata(selmenu, userrole, userid, perpage, page, enc_myinputValues));
          } else {

            setCustomDate(false);
            if(value === "0"){
                setInputValues({"filterCat": '',"dateFilter":'',"startDate": '',"endDate": ''});
                setInputValues({"dateFilter": value});
                setStatusloader(true);
                const myinputValues = JSON.stringify(inputValues);
                const enc_myinputValues = new Buffer(myinputValues).toString('base64');
                dispatch(ListCustdata(selmenu, userrole, userid, perpage, page, enc_myinputValues));
            }else{
                setCustomDate(true);
                setInputValues({"filterCat": 'custom',"dateFilter":'custom'});
            }
        }
    }

    const handleCustomStartDate = (date) =>{
        let startDate = moment(date).format('YYYY-MM-DD')
        setStartDate(date)
        setInputValues({ "startDate": startDate ,"dateFilter" : 'custom'}) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    }
    const handleCustomEndDate = (date) =>{
        let endDate = moment(date).format('YYYY-MM-DD')
        setEndDate(date)
        setInputValues({ "endDate": endDate,"dateFilter" : 'custom' }) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    }
    const resetForm = () => {
        const container = document.querySelector('.scrollable');
        container.scrollTop = 0;
        setStatusloader(true);
        setInputValues({ "crs" : '' , "pep" : '' , "ustaxresident" : '', "customTyp1" : '', "customTyp2" : '',"customTyp3" : '',"sortby" : '', "dateFilter" : 0});
        const myinputValues = JSON.stringify({ "crs" : '' , "pep" : '' , "ustaxresident" : '', "customTyp1" : '', "customTyp2" : '',"customTyp3" : '',"sortby" : '',"dateFilter" : 0});
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    }

    const submitForm = () =>{
        const container = document.querySelector('.scrollable');
        container.scrollTop = 0;
        setStatusloader(true);
        const myinputValues = JSON.stringify(inputValues);
        console.log(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(ListCustdata(selmenu,userrole,userid,perpage,page,enc_myinputValues)) ;
    }
    const recordLockUpdate = (rid,cid) =>{
        let uid = getToken().id;
        dispatch(ekycLockUpdates(rid,uid,cid));
    }
    // useEffect(() => {
    //     const reloadCount = sessionStorage.getItem('reloadCount');
    //     if(reloadCount < 2) {
    //         sessionStorage.setItem('reloadCount', String(reloadCount + 1));
    //         window.location.reload();
    //     } else {
    //         sessionStorage.removeItem('reloadCount');
    //     }
    // }, []);
    console.log(selmenu);
    return (
        <React.Fragment>
            <Pagesidebar menu={selmenu} mainmenu="ReKYC"  showsidebar={showsidebar} changeMenu ={changeMenu}/>
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="ReKYC"  showsidebar={showsidebar} filter="No" search="Yes"  searchParam = {searchbyvalue} searchChange = {handleChange} searchValChange = {searchValChange} filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head flexCS">
                        <h2>{selmenu === 'On Hold' ? 'Returned to Customer' : selmenu} List ({listcustcount ? listcustcount : 0})</h2>
                        {/*<div className="sortBox__check">*/}
                        {/*    <label className="csCheckbox">*/}
                        {/*        <input type="radio"  className="csCheckbox__input"  value ={1}  name ="dateFilter"  onClick={handledateFilter}  />*/}
                        {/*        <span className="csCheckbox__placeholder" />*/}
                        {/*    </label>*/}
                        {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Today"}}></label>*/}
                        {/*</div>*/}
                        {/*<div className="sortBox__check">*/}
                        {/*    <label className="csCheckbox">*/}
                        {/*        <input type="radio"  className="csCheckbox__input" value ={7}   name ="dateFilter"  onClick={handledateFilter}  />*/}
                        {/*        <span className="csCheckbox__placeholder" />*/}
                        {/*    </label>*/}
                        {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Last 7 days"}}></label>*/}
                        {/*</div>*/}
                        {/*<div className="sortBox__check">*/}
                        {/*    <label className="csCheckbox">*/}
                        {/*        <input type="radio"  className="csCheckbox__input"   value ={30}   name ="dateFilter"  onClick={handledateFilter}  />*/}
                        {/*        <span className="csCheckbox__placeholder" />*/}
                        {/*    </label>*/}
                        {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Last 30 days"}}></label>*/}
                        {/*</div>*/}
                        {/*<div className="sortBox__check">*/}
                        {/*    <label className="csCheckbox">*/}
                        {/*        <input type="radio"  className="csCheckbox__input" value ={3}    name ="dateFilter"  onClick={handledateFilter}  />*/}
                        {/*        <span className="csCheckbox__placeholder" />*/}
                        {/*    </label>*/}
                        {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Last 3 months"}}></label>*/}
                        {/*</div>*/}
                        {/*<div className="sortBox__check">*/}
                        {/*    <label className="csCheckbox">*/}
                        {/*        <input type="radio"  className="csCheckbox__input"  value ={6}    name ="dateFilter"  onClick={handledateFilter}  />*/}
                        {/*        <span className="csCheckbox__placeholder" />*/}
                        {/*    </label>*/}
                        {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Last 6 months"}}></label>*/}
                        {/*</div>*/}
                        <div className="sortBox__check flexCB csLabeled">
                            <label  className="csForm__item__label">Sort By Date :</label>
                            <Selectbox label="Date filter" name="dateFilter"  labelStatus = "No" values={sortbyfilter} onChange={handledateFilter} selvalue={inputValues.dateFilter}/>
                        </div>
                    {customDate &&
                        <div className="sortBox__check">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => handleCustomStartDate(date)}
                                placeholderText="From Date"
                                dateFormat="MM/dd/yyyy"
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => handleCustomEndDate(date)}
                                placeholderText="End Date"
                                dateFormat="MM/dd/yyyy"
                            />
                        </div>

                    }
                        {/*<Link to="/add-monthlyincome" className="btn btn--primaryG btn--capsule">(+) Monthly Income</Link>*/}
                    </div>


                    <div className='sortBox'>
                        <div className='flexCS fullPanel'>
                            {/*<div className="sortBox__check">*/}
                            {/*    <label className="csCheckbox">*/}
                            {/*        <input type="checkbox"  className="csCheckbox__input"   checked={ inputValues.crs  === 'on' ? 'checked' : '' } name ="crs"  onClick={handleInput}  />*/}
                            {/*        <span className="csCheckbox__placeholder" />*/}
                            {/*    </label>*/}
                            {/*    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"CRS"}}></label>*/}

                            {/*</div>*/}
                            <div className="sortBox__check">

                                <label className="csCheckbox">
                                    <input type="checkbox"  className="csCheckbox__input" checked={inputValues.pep  === 'on' ? 'checked' : '' }  name ="pep"  onClick={handleInput} />
                                    <span className="csCheckbox__placeholder" />
                                </label>
                                <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"PEP"}}></label>
                            </div>
                            <div className="sortBox__check">
                                <label className="csCheckbox">
                                    <input type="checkbox"  name ="ustaxresident" className="csCheckbox__input" checked={inputValues.ustaxresident  === "on" ? 'checked' : '' } onClick={handleInput}  />
                                    <span className="csCheckbox__placeholder" />
                                </label>
                                <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"US Taxresident"}}></label>

                            </div>
                            <div className="sortBox__check">
                                <label className="csCheckbox">
                                    <input type="checkbox" name ="customTyp1"  className="csCheckbox__input" checked={inputValues.customTyp1  === "on" ? 'checked' : '' } onClick={handleInput}   />
                                    <span className="csCheckbox__placeholder" />
                                </label>
                                <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Resident"}}></label>

                            </div>
                            <div className="sortBox__check">
                                <label className="csCheckbox">
                                    <input type="checkbox" name ="customTyp2" className="csCheckbox__input" checked={inputValues.customTyp2  === "on" ? 'checked' : '' } onClick={handleInput}   />
                                    <span className="csCheckbox__placeholder" />
                                </label>
                                <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Non Resident"}}></label>

                            </div>
                            <div className="sortBox__check">
                                <label className="csCheckbox">
                                    <input type="checkbox" name ="customTyp3" className="csCheckbox__input"  checked={inputValues.customTyp3  === "on" ? 'checked' : '' } onClick={handleInput}  />
                                    <span className="csCheckbox__placeholder" />
                                </label>
                                <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:"Omani"}}></label>

                            </div>
                            <>
                                <Selectbox  label="Sort by" name="sortby"  labelStatus = "No" values={sortbyvalue} onChange={handleChange} selvalue={inputValues.sortby}/>
                            </>
                            <>
                                {menuStatus === 'approved' &&
                                <Selectbox label="Approved by" name="approvedby" labelStatus="No"
                                           values={approvedbyvalue}
                                           onChange={handleChange} selvalue={''}/>
                                }
                            </>
                        </div>

                        <div>
                            <div className="flexCC buttonWrap">
                                <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Go</span>
                                <span onClick={resetForm} className="btn btn--primaryOuter btn--sm btn--capsule">Reset</span>
                            </div>
                        </div>
                    </div>


                    <div className="tableWrapper scrollable heighted">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>

                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    CIF
                                </th>
                                <th>
                                    Reference ID
                                </th>
                                <th>
                                    Mobile
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Customer Type
                                </th>
                                <th>
                                    CRS
                                </th>
                                <th>
                                    PEP
                                </th>
                                <th>
                                    US Taxresident
                                </th>


                            </tr>
                            </thead>
                            {custdata && custdata.length > 0 ?
                                <tbody>
                                {custdata.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>

                                                {index + 1}
                                            </td>
                                            <td>
                                                {
                                                    (item.IsLocked === "1"  && (menuStatus === 'pending' || menuStatus === 'forward' ))?
                                                        <img src={Lock}/> :
                                                        <Link className="iconIn iconIn--eye-primary "
                                                              to={`/detail-view/?id=${item.id}`}  onClick={() => recordLockUpdate(item.cust_refno,item.cust_cif)}></Link>
                                                }
                                            </td>
                                            <td>
                                                {item.modified_date && item.modified_date.substring(0, item.modified_date.lastIndexOf("."))}
                                            </td>
                                            <td>
                                                    {item.cust_name}

                                            </td>
                                            <td>
                                                {item.cust_cif}
                                            </td>
                                            <td>
                                                {item.cust_refno}
                                            </td>
                                            <td>
                                                {item.cust_mobile}
                                            </td>
                                            <td>
                                                {item.cust_email}
                                            </td>
                                            <td>
                                                {item.cust_id_type ? item.cust_id_type : 'National'}
                                            </td>
                                            <td>
                                                {item.cust_taxresident_notoman ? item.cust_taxresident_notoman : 'No'}
                                            </td>
                                            <td>
                                                {item.pep === "1" ? "Yes" : 'No'}
                                            </td>
                                            <td>
                                                {item.usfatca === "1" ? "Yes" : 'No'}
                                            </td>

                                        </tr>
                                    )
                                })}
                                </tbody> :
                                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ListReKYC