import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddPendingApprovals, AddSetting} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";


const AddCountry = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {country: '',countryar:'',countrycode:'', selactive:'', seldeleted:'', newcountry: []}
    );

    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );

    const [statusloader, setStatusloader] = useState(false);
    const [errorcountrystatus, setErrorCountrystatus] = useState(false);
    const [errorcountryarstatus, setErrorCountryarstatus] = useState(false);
    const [errorcodestatus, setErrorCodestatus] = useState(false);
    const newsettingsdata = useSelector(state => state.UserReducer.newsettingsdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;

    let history = useHistory();
    let validCodeError = false;
    let validCountryError = false;
    let validCountryarError = false;
    const dispatch = useDispatch();


    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatusloader(false);
                setStatus(newdatarow.resp_status) ;
                setInputValues({ "country" : '',"countryar" : '', "countrycode" : '', "selactive": '', "seldeleted" : ''});
            }
        }
    },[newdatarow]);

    useEffect(() => {
        setInputValues({ "newcountry" : newsettingsdata });
    },[newsettingsdata]);


    const submitForm = () => {
        //dispatch(AddSetting(inputValues.key,inputValues.keyvalue)) ;
        if(!inputValues.country){
             validCountryError = true;
            setErrorCountrystatus(true);
        } else{
            validCountryError = false;
            setErrorCountrystatus(false);
        }
        if(!inputValues.countrycode ){
            validCodeError = true;
            setErrorCodestatus(true);
        } else{
            validCodeError = false;
            setErrorCodestatus(false);
        }
        console.log(validCountryError) ;
        console.log(validCodeError) ;
        if(!validCountryError && !validCodeError) {
            const myObj = {
                "CName": inputValues.country,
                "CNameAr": inputValues.countryar,
                "CCode": inputValues.countrycode,
                "IsActive": inputValues.selactive,
                "IsDeleted": inputValues.seldeleted
            };
            const myObjStr = JSON.stringify(myObj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setStatusloader(true);
            dispatch(AddPendingApprovals('Country:'+inputValues.country, enc_myObjStr, user, 'Insert'));
            setSubmit(true);
        }

    }

    const resetForm = () => {
        setStatus(false);
        setInputValues({ "country" : '',"countryar" : '', "countrycode" : '', "selactive": '', "seldeleted" : ''});
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

    return (
        <React.Fragment>

            <Pagesidebar menu="Country" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">

                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>

                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Add Country</h2>
                    </div>



                    <div className="p30">
                        { status === "success"  ? (
                            <span className="messageIn">Successfully created the new Country</span>
                        ) : ''
                        }

                        <div className="fullFlexBox">


                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Country</label>

                                    <input value={inputValues.country} name="country"
                                           className={`csForm__item__input ${errorcountrystatus ? 'error' : ''} `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>
                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Country Arabic</label>

                                    <input value={inputValues.countryar} name="countryar"
                                           className={`csForm__item__input `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>

                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Country Code</label>

                                    <input value={inputValues.countrycode} name="countrycode"
                                           className={`csForm__item__input ${errorcodestatus ? 'error' : ''} `}
                                           onChange={handleInput} type="text"/>

                                </div>
                            </div>

                            <div className="halfBlock">
                                <Selectbox label="Is Active" name="selactive" values={yesnovalue}
                                           onChange={handleChange} selvalue={inputValues.selactive}/>

                            </div>
                            <div className="halfBlock">
                                <Selectbox label="Is Deleted" name="seldeleted" values={yesnovalue}
                                           onChange={handleChange} selvalue={inputValues.seldeleted}/>
                            </div>


                        </div>

                    </div>

                    <div className="p30">
                        <div className="flexCC buttonWrap">
                            <span onClick={resetForm}
                                  className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                            <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

export default AddCountry
