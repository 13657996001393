import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getUploads, ListCustdetails, changeStatusUserRecord} from "../actions/User";
import {ListDashboard} from "../actions/User";

import logo from '../assets/images/logo.png';
import id_card from '../assets/images/id-card-only.jpg';
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";

const Dashboard = () => {
        const [sidebar, setSidebar] = useState(true);
        const [showsidebar, setShowsidebar] = useState(true);
        const [filterlist, setFilterlist] = useState([{"item": "Weekly"},{"item": "Monthly"},{"item": "Yearly"}]);
        const [userrole, setUserrole] = useState(getToken().role);
        const [listitem, setListitem] = useState([]);
        const [listitemcount, setListitemcount] = useState(0);
        const [activefilter, setActivefilter] = useState(0);

        const [listitemkyc, setListitemkyc] = useState([]);
        const [listitemdropoff, setListitemdropoff] = useState([]);
        const [listitemcountkyc, setListitemcountkyc] = useState(0);
        const [listitemcountdropoff, setListitemcountdropoff] = useState(0);

        const [listitemlid, setListitemlid] = useState([]);
        const [listitemcountlid, setListitemcountlid] = useState(0);

        const [listitempep, setListitempep] = useState([]);
        const [listitemcountpep, setListitemcountpep] = useState(0);

        const [listitemnonstp, setListitemnonstp] = useState([]);
        const [listitemcountnonstp, setListitemcountnonstp] = useState(0);

        const [listitemstp, setListitemstp] = useState([]);
        const [listitemcountstp, setListitemcountstp] = useState(0);

        const [listitemusfatca, setListitemusfatca] = useState([]);
        const [listitemcountusfatca, setListitemcountusfatca] = useState(0);

        const dispatch = useDispatch();
        const dashboarddata = useSelector(state => state.UserReducer.dashboarddata) ;

        useEffect(() => {
            console.log("userrole: "+userrole) ;
            dispatch(ListDashboard(userrole)) ;
        },[]);

        useEffect(() => {
               console.log(dashboarddata ) ;
            if(dashboarddata[0] !== undefined ){
                if(dashboarddata[0].total.result !== undefined){
                    console.log(dashboarddata[0].total ) ;
                    setListitem(dashboarddata[0].total.result) ;
                    setListitemcount(dashboarddata[0].total.totcount) ;
                }

                if(dashboarddata[0].KYC.result !== undefined){
                    setListitemkyc(dashboarddata[0].KYC.result) ;
                    setListitemcountkyc(dashboarddata[0].KYC.totcount) ;
                }

                if(dashboarddata[0].DROPPOFF.result !== undefined){
                    setListitemdropoff(dashboarddata[0].DROPPOFF.result) ;
                    setListitemcountdropoff(dashboarddata[0].DROPPOFF.totcount) ;
                }


                if(dashboarddata[0].nonstp.result !== undefined){
                    console.log(dashboarddata[0].nonstp ) ;
                    setListitemnonstp(dashboarddata[0].nonstp.result) ;
                    setListitemcountnonstp(dashboarddata[0].nonstp.totcount) ;
                }

                if(dashboarddata[0].stp.result !== undefined){
                    console.log(dashboarddata[0].stp ) ;
                    setListitemstp(dashboarddata[0].stp.result) ;
                    setListitemcountstp(dashboarddata[0].stp.totcount) ;
                }

            }

            /* if(dashboarddata !== null ){
                Object.keys(dashboarddata).map(function(key, index) {
                        console.log(key) ;
                        setListitem(dashboarddata.index);
                }
                )
            } */
        },[dashboarddata]);


        const changeNav = () =>{
            setShowsidebar(!showsidebar)
        }
        const chooseFilter = (type) =>{
            setActivefilter(type)
            dispatch(ListDashboard(type)) ;
        }

        console.log(listitem) ;
        console.log(listitemdropoff) ;
        console.log(listitemcountdropoff) ;
        return (
        <React.Fragment>

            <Pagesidebar menu="Home" mainmenu="Dashboard" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
            <navbar className="pageNav">
                  <Navbar pagettl="Dashboard"  showsidebar={showsidebar} filter="Yes" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
           </navbar>

                <div className="paddingBlock">

                <div className="fullFlexBox">
                    <div className="partitionFlexBox">
                        <div className="w330Rem">
                            <div className="dashboardMain">
                                {/*<Link to="/setpermission">Set</Link>*/}

                                <div className="flexCE">
                                    <div className="capsuleBlock">
                                        <div className="capsuleBlock__content noPadding">
                                            <span className={`sortText ${activefilter === 0 ? "active" : ""} `}  onClick={() => chooseFilter(0)}>TOTAL</span>
                                        </div>
                                        <div className="capsuleBlock__content noPadding">
                                            <span className={`sortText ${activefilter === 1 ? "active" : ""} `} onClick={() => chooseFilter(1)}>THIS WEEK</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="dashboardMain__item">
                                    <h4 className="subheader">COMPLETED APPLICATIONS</h4>
                                    <div className="flexCB">
                                        <div className="w200">
                                            <div className="colorBgBlock colorBgBlock--red">
                                                <p className="roundBlockSubtext">Total Application</p>
                                                <h4 className="roundBlockValue">{parseInt(listitemcountstp) + parseInt(listitemcountnonstp)}</h4>
                                            </div>
                                        </div>
                                        <div className="w200Rem">
                                            <div className="capsuleBlock">
                                                <div className="capsuleBlock__content">
                                                    <p className="roundBlockSubtext">stp Applications</p>
                                                    <h4 className="roundBlockValue">{listitemcountstp}</h4>
                                                </div>
                                                <div className="capsuleBlock__content">
                                                    <p className="roundBlockSubtext">Non stp Applications</p>
                                                    <h4 className="roundBlockValue">{listitemcountnonstp}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dashboardMain__item">
                                    <h4 className="subheader">APPLICATION STATUS</h4>
                                    <div className="threeGrid capsuleBox">
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Pending</p>
                                            <h4 className="roundBlockValue">{listitemkyc.filter(item => item.status === "Pending")[0] ? listitemkyc.filter(item => item.status === "Pending")[0].count : 0 }</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Approved</p>
                                            <h4 className="roundBlockValue text--green">{listitemkyc.filter(item => item.status === "Approved")[0] ? listitemkyc.filter(item => item.status === "Approved")[0].count : 0 }</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Rejected</p>
                                            <h4 className="roundBlockValue">{listitemkyc.filter(item => item.status === "Rejected")[0] ? listitemkyc.filter(item => item.status === "Rejected")[0].count : 0}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="dashboardMain__item">
                                    <h4 className="subheader">APPLICATIONS DROP OFF</h4>
                                    <div className="sixGrid capsuleBox">
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Total Drop Off</p>
                                            <h4 className="roundBlockValue text--red">{listitemcountdropoff}</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Landing</p>
                                            <h4 className="roundBlockValue text--green">{listitemdropoff.filter(item => item.cust_upd_level === '0')[0] ? listitemdropoff.filter(item => item.cust_upd_level === '0')[0].count : 0}</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Liveness</p><h4 className="roundBlockValue">{listitemdropoff.filter(item => item.cust_upd_level === '1')[0] ? listitemdropoff.filter(item => item.cust_upd_level === '1')[0].count : 0}</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">ID Scan</p>
                                            <h4 className="roundBlockValue text--green">{listitemdropoff.filter(item => item.cust_upd_level === '2')[0] ? listitemdropoff.filter(item => item.cust_upd_level === '2')[0].count : 0}</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Verify</p>
                                            <h4 className="roundBlockValue">{listitemdropoff.filter(item => item.cust_upd_level === '3')[0] ? listitemdropoff.filter(item => item.cust_upd_level === '3')[0].count : 0}</h4>
                                        </div>
                                        <div className="capsuleBlock__content">
                                            <p className="roundBlockSubtext">Form</p>
                                            <h4 className="roundBlockValue">{listitemdropoff.filter(item => item.cust_upd_level === '3')[0] ? listitemdropoff.filter(item => item.cust_upd_level === '3')[0].count : 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<h3 className="subheader">Transaction History</h3>*/}
                            {/*<div className="partitionTileHolder">*/}
                            {/*    /!**/}
                            {/*     { listitem !== []  && listitemcount > 0?*/}
                            {/*    <div className="threeTile applicationTile">*/}
                            {/*        <div className="applicationTile__head applicationTile__head--greenG">*/}
                            {/*            <label>Total Transactions</label>*/}
                            {/*            <p>{listitemcount}</p>*/}
                            {/*        </div>*/}
                            {/*        <div className="applicationTile__content">*/}
                            {/*              {listitem.map((item) => {*/}
                            {/*                   return(*/}
                            {/*                            <div className="applicationTile__content__each">*/}
                            {/*                                <label>{item.status}</label>*/}
                            {/*                                <p>{item.count}</p>*/}
                            {/*                            </div>*/}
                            {/*                   )*/}
                            {/*            })}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*        : '' }*/}

                            {/*         *!/*/}


                            {/*    { listitemkyc !== []  && listitemcountkyc > 0?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--redG">*/}
                            {/*                <label>KYC</label>*/}
                            {/*                <p>{listitemcountkyc}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitemkyc.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}


                            {/*    { listitemlid !== []  && listitemcountlid > 0 ?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--yellowG">*/}
                            {/*                <label>LID</label>*/}
                            {/*                <p>{listitemcountlid}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitemlid.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}


                            {/*    { listitempep !== []  && listitemcountpep > 0 ?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--greenG">*/}
                            {/*                <label>PEP</label>*/}
                            {/*                <p>{listitemcountpep}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitempep.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}

                            {/*    { listitemstp !== []  && listitemcountstp > 0 ?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--redG">*/}
                            {/*                <label>STP</label>*/}
                            {/*                <p>{listitemcountstp}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitemstp.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}

                            {/*    { listitemnonstp !== []  && listitemcountnonstp > 0 ?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--redG">*/}
                            {/*                <label>NON STP</label>*/}
                            {/*                <p>{listitemcountnonstp}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitemnonstp.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}


                            {/*    { listitemusfatca !== []  && listitemcountusfatca > 0 ?*/}
                            {/*        <div className="threeTile applicationTile">*/}
                            {/*            <div className="applicationTile__head applicationTile__head--yellowG">*/}
                            {/*                <label>US FATCA</label>*/}
                            {/*                <p>{listitemcountusfatca}</p>*/}
                            {/*            </div>*/}
                            {/*            <div className="applicationTile__content">*/}
                            {/*                {listitemusfatca.map((item) => {*/}
                            {/*                    return(*/}
                            {/*                        <div className="applicationTile__content__each">*/}
                            {/*                            <label>{item.status}</label>*/}
                            {/*                            <p>{item.count}</p>*/}
                            {/*                        </div>*/}
                            {/*                    )*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        : '' }*/}






                            {/*</div>*/}
                        </div>
                        <div className="w330">
                            <h3 className="subheader">Total Transactions</h3>
                            <div className="radialBox">

                                { listitem !== []  && listitemcount > 0?
                                             <>
                                            {listitem.map((item,index) => {
                                                return(
                                                    <div className="radialBox__each">
                                                   <div className="halfBlock">
                                                       <label className="radialBox__each__label">{item.status}</label>
                                                       <div className="radialBox__indicatorBlock">
                                                           <span  className={`radialBox__indicator radialBox__indicator__${index+1}`} ></span>
                                                           <span className="radialBox__indicator__text">{item.count}</span>
                                                       </div>
                                                   </div>
                                                   <div className="halfBlock">
                                                           <div className="mapLine">
                                                               <span className={`mapLine__in mapLine__in--${index+1}`} ></span>
                                                           </div>
                                                   </div>
                                                    </div>
                                                )
                                            })}
                                         </>
                                    : '' }





                                <div className="radialBox__each">
                                    <div className="fullRoundGraph">
                                        <label>Total Transactions</label>
                                        <p>{listitemcount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>

    </div>


</React.Fragment>
)
}

export default Dashboard
