import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {ListCustdata} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";

const ListCustomersForward = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [modalIn, setModalIn] = useState(false);
    const [custdata, setCustdata] = useState([]);
    const [selmincomeid, setSelmincomeid] = useState('');
    const [selectedmincome, setSelectedmincome] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '',incomerange: '' , updatedmincome: {"status": "pending"}}
    );

    const listcustdata = useSelector(state => state.UserReducer.listcustdata) ;


    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ListCustdata("Forward",userrole,userid)) ;
    },[dispatch]);

    useEffect(() => {
        setCustdata(listcustdata)
    },[listcustdata]);

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

    return (
        <React.Fragment>
            <Pagesidebar menu="Forwarded" mainmenu="ReKYC/LegalID" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="ReKYC/LegalID"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    <div className="roundBox__head">
                        <h2>Forwarded List</h2>
                        {/*<Link to="/add-monthlyincome" className="btn btn--primaryG btn--capsule">(+) Monthly Income</Link>*/}
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    CIF
                                </th>
                                <th>
                                    Mobile
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Customer Type
                                </th>
                                <th>
                                    CRS
                                </th>
                                <th>
                                    PEP
                                </th>
                                <th>
                                    US Taxresident
                                </th>
                                <th>

                                </th>

                            </tr>
                            </thead>
                            <tbody>
                            { custdata && custdata.map((item,index) => {
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>

                                            {item.cust_name}

                                        </td>
                                        <td>
                                            {item.cust_cif}
                                        </td>
                                        <td>
                                            {item.cust_mobile}
                                        </td>
                                        <td>
                                            {item.cust_email}
                                        </td>
                                        <td>
                                            {item.cust_id_type ? item.cust_id_type : 'National' }
                                        </td>
                                        <td>
                                            {item.cust_taxresident_notoman ? item.cust_taxresident_notoman : 'No' }
                                        </td>
                                        <td>
                                            {item.pep === "1" ? "Yes" : 'No' }
                                        </td>
                                        <td>
                                            {item.usfatca === "1" ? "Yes" : 'No' }
                                        </td>
                                        <td>
                                            <Link className="iconIn iconIn--eye-primary " to={`/detail-view/?id=${item.id}`}> </Link>
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
                    </div>

        </React.Fragment>
    )
}

export default ListCustomersForward
