import React, {useState, useEffect, useReducer,useRef} from 'react';
import {Link, useHistory, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    getUploads,
    ListCustdetails,
    changeStatusUserRecord,
    ListLockRecords,
    getSystemConfig,
    updateRecordLockStatus,
    ListCountrys,
    ListRolesectionPermission, CreateAccessLog, ListSetting
} from "../actions/User";
import IdleTimerComponent from './IdleTimerSet'
import {getToken} from "../utilities/AuthService";


import logo from '../assets/images/logo.png';
import Loader from "../components/Loader";

const DetailView = (props) => {
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [userOption, setUserOption] = useState(false)
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);
    const [version, setVersion] = useState(false);

    const [custdetails, setCustdetails] = useState([]);
    const [additioninfo, setAdditioninfo] = useState([]);
    const [previewModal, setPreviewModal] = useState('');
    const [activeView, setactiveView] = useState('livenessRef');
    const [uploaddetails, setUploaddetails] = useState([]);
    const [livevideo, setLivevideo] = useState('');
    const [carddetails, setCarddetails] = useState([]);
    const [mystatus, setMystatus] = useState('Pending');
    const [liveness, setLiveness] = useState([]);
    const [lockstatus, setLockStatus] = useState(false);
    const [idcard, setIdcard] = useState([]);
    const [idcardconfidence, setIdcardconfidence] = useState('');
    const [facematchconfidence, setFacematchconfidence] = useState('');
    const [firstimage, setFirstimage] = useState('');

    const [apprvcomments, setApprvcomments] = useState('');
    const [rejectreason, setRejectreason] = useState('');
    const [rejectcomments, setRejectcomments] = useState('');
    const [onholdreason, setOnholdreason] = useState('');
    const [onholdcomments, setOnholdcomments] = useState('');
    const [forwardcomments, setForwardcomments] = useState('');
    const [message, setMessage] = useState('');
    const [cid, setCid] = useState(0);
    const [jid, setJid] = useState(0);
    const [listimage, setListimage] = useState([]);
    const [documentarray, setDocumentarray] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [pageloader, setPageloader] = useState(false);
    const [errorstatus, setErrorstatus] = useState(false);
    const [btnstatus, setBtnstatus] = useState(false);
    const [istimedOut, setisTimedOut] = useState(false);
    const [backtopage, setBackTopage] = useState(false);

    const livenessRef = useRef(null)
    const idRef = useRef(null)
    const formRef = useRef(null)
    const backtotop = useRef(null)



    const params = (new URL(document.location)).searchParams;
    const id = params.get("id") ? params.get("id") :'';
console.log(id);
    const listcustdetails = useSelector(state => state.UserReducer.listcustdetails) ;
    const getuploaddata = useSelector(state => state.UserReducer.uploadRespdata) ;
    const updatestatusesdata = useSelector(state => state.UserReducer.updatestatusesdata) ;
    const listlockdata = useSelector(state => state.UserReducer.listlockdata) ;
    const systemconfigdata = useSelector(state => state.UserReducer.systemconfigdata) ;
    const recLockupdatesStatus = useSelector(state => state.UserReducer.recLockupdatesStatus) ;
    const countrydata = useSelector(state => state.UserReducer.listcountrydata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;

    console.log(countrydata);
    console.log(recLockupdatesStatus);
console.log(backtopage);


    let history = useHistory();
    const dispatch = useDispatch();
    const Timeout = 1000 * 10;
    const idleTimerRef = useRef(null);

    useEffect(() => {
        setPageloader(true);
        dispatch(ListCustdetails(id)) ;
        dispatch(ListCountrys()) ;
        dispatch(getSystemConfig('AgentRecordLockTime')) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    },[dispatch]);

  useEffect(() => {
    if(recLockupdatesStatus && recLockupdatesStatus === '0' && backtopage === true){
        history.goBack();
        setBackTopage(false);
    }
    },[recLockupdatesStatus]);

    // useEffect(() => {
    //     console.log(lockstatus);
    //
    //     if(listlockdata[0].user_id){
    //         console.log(listlockdata);
    //     }
    // },[listlockdata]);


    useEffect(() => {
        if (updatestatusesdata.status != undefined) {
            if (updatestatusesdata.status === "success") {
                setBtnstatus(true);
                setStatusloader(false);

                setMessage("Successfully updated the status");
                let cif = custdetails.length > 0 ? custdetails[0].cust_cif : 0;
                let sectionmsg= 'Successfully updated the status';
                dispatch(CreateAccessLog(cif,sectionmsg,userid));
            } else {
                setBtnstatus(true);
                setStatusloader(false);
                let cif = custdetails.length > 0 ? custdetails[0].cust_cif : 0;
                let sectionmsg= 'Status update failed';
                dispatch(CreateAccessLog(cif,sectionmsg,userid));
                setMessage("We are unable to communicate with our server. Please try again after some time");
            }
         } else {
            setBtnstatus(true);
            setStatusloader(false);
            let cif = custdetails.length > 0 ? custdetails[0].cust_cif : 0;
            let sectionmsg= 'Status update failed';
            dispatch(CreateAccessLog(cif,sectionmsg,userid));
            setMessage("We are unable to communicate with our server. Please try again after some time");
        }
    },[updatestatusesdata]);

    const showFilePreview=(key)=> {
        setPreviewModal(key)
    }

    const showActiveView=(key)=> {
        setactiveView(key)
    }
    const previewModalOut=()=> {
        setPreviewModal('')
    }
    const lockModalOut=()=> {
        setLockStatus(false);
    }
    useEffect(() => {
        setPageloader(true);
        setCustdetails(listcustdetails)


        if(listcustdetails != undefined){
            dispatch(ListLockRecords(listcustdetails[0].cust_cif)) ;

            if(listcustdetails[0].IsLocked === '1'){
                setLockStatus(true);
            }
            setAdditioninfo(JSON.parse(listcustdetails[0].addtln_info)) ;
            setFacematchconfidence(JSON.parse(listcustdetails[0].facematch_response)) ;

            setCid(id) ;
            if(listcustdetails[0].status){
                setMystatus(listcustdetails[0].status);


            }

            if(listcustdetails[0].livenesstest_response !== undefined){
                const lresp =  JSON.parse(listcustdetails[0].livenesstest_response) ;
                if(lresp !== null){
                    setLiveness(lresp.result) ;
                }

            }


            if(listcustdetails[0].idscan_response !== undefined){
                const idresp =  JSON.parse(listcustdetails[0].idscan_response) ;
                setIdcard(idresp) ;
                if(idresp !== null){
                    Object.keys(idresp).map(function(key, index) {
                        if(key === "INDIAPAN - Recognition Confidence" || key === "PASSPORT - Recognition Confidence"){
                            setIdcardconfidence(idresp[key]) ;
                        }
                    });
                }

            }




            if(listcustdetails[0].journey_id !== undefined){
                setJid(listcustdetails[0].journey_id);
                fetchAllGioemDocs(listcustdetails[0].journey_id) ;
            }

        }
    },[listcustdetails]);

    const modalSet = (typeIn) => {
        setMessage('')
        setModal(true);
        setModalContent(typeIn);
        setErrorstatus(false);
        setBtnstatus(false);

    }
    const modalOut = () => {
        setMessage('')
        setModal(false)
    }

    const  fetchAllGioemDocs = (jid) =>{
        console.log(jid) ;
        dispatch(getUploads(jid)) ;

    }


    const statusUpdateKYC = (status) =>{
        let comments = '';
        let reason = '';
        let errorStatus = false;
        if(status == "Approved"){
            comments = apprvcomments ;
        }else if(status == "Rejected"){
            comments = rejectcomments ;
            reason = rejectreason ;

            if(!comments && !reason){
                errorStatus = true;
                setErrorstatus(true)
            }else{
                errorStatus = false;
                setErrorstatus(false)
            }
        }else if(status == "Forward"){
            comments = forwardcomments ;
        }else if(status == "On Hold"){
            comments = onholdcomments ;
            reason = onholdreason ;
            if(!comments && !reason){
                errorStatus = true;
                setErrorstatus(true)
            }else{
                errorStatus = false;
                setErrorstatus(false)

            }
        }


        if(errorStatus === false) {
            setStatusloader(true);
            const frmObj = {
                "cid": cid,
                "uid": userid,
                "status": status,
                "comment": comments,
                "reason": reason,
                "jid":jid
            };
            const myObjStr = JSON.stringify(frmObj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setMystatus("status");
            let cif = custdetails.length > 0 ? custdetails[0].cust_cif : 0;
            let sectionmsg= 'Clicked on ReKYC Record '+ status +' button';
            dispatch(CreateAccessLog(cif,sectionmsg,userid));
            dispatch(changeStatusUserRecord(cid, enc_myObjStr)) ;
        }
    }


    useEffect(() => {
        if(getuploaddata !== undefined){
            setUploaddetails(getuploaddata);
            // console.log(getuploaddata.result) ;
            let Imagearray= []  ;
            let documentarray= []  ;
            if(getuploaddata.result !== undefined){
                setPageloader(false);
                Object.keys(getuploaddata.result).map(function(key, index) {
                    var ftype = 'img';
                    if(key === "livevideo"){
                        var lvdo = getuploaddata.result.livevideo;
                        lvdo = lvdo.replace("b'","");
                        lvdo = lvdo.substr(0,lvdo.length -1) ;
                        /* console.log(lvdo) ; */
                        setLivevideo(lvdo);
                    }else if(key === "IndiaPanFront"){
                        var fimage = getuploaddata.result.IndiaPanFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage(fimage);
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);
                    }else if(key === "OmanResidentIdFront"  ){
                        var fimage = getuploaddata.result.OmanResidentIdFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage(fimage);
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);

                    }else if( key === "OmanNationalIdFront" ){
                        var fimage = getuploaddata.result.OmanNationalIdFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage(fimage);
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);
                    }else if( key === "PassportFront"){
                        var fimage = getuploaddata.result.PassportFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage(fimage);
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);
                    }else if( key === "OmanNationalIdBack" ){
                        var fimage = getuploaddata.result.OmanNationalIdBack;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);
                    }else if( key === "OmanResidentIdBack" ){
                        var fimage = getuploaddata.result.OmanResidentIdBack;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        Imagearray.push(fimage) ;
                        setListimage(Imagearray);
                    }else {
                        fimage = getuploaddata.result[key].replace("b'","");
                        fimage = fimage.substr(0,fimage.length -1) ;

                        if (key.indexOf('_pdf') >= 0) {
                            fimage =  'data:application/pdf;base64,' + fimage;
                            ftype = 'pdf'
                        } else {
                            fimage =  'data:image/jpeg;base64,' + fimage;
                            ftype = 'img'
                        }
                        let fKey = key.replace('_pdf', '');
                        documentarray[fKey] = fimage;
                        documentarray[fKey+'_ftype'] = ftype;
                    }
                });
                setDocumentarray(documentarray);
            }

        }

    },[getuploaddata]);


    const onFocusClick = (key) => {
        showActiveView(key);
        if (key === 'livenessRef') {
            if (livenessRef.current !== null) {
                console.log(livenessRef.current)
                livenessRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        }
        if (key === 'idRef') {
            if (idRef.current !== null) {
                console.log(idRef.current)
                idRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        }
        if (key === 'formRef') {
            if (formRef.current !== null) {
                console.log(formRef.current);
                formRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        }
        if (key === 'backtotop') {
            if (backtotop.current !== null) {
                console.log(backtotop.current)
                backtotop.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
        }
    }


   const resetLockRecords = (e) =>{
        e.preventDefault();
       let custDet = custdetails ? custdetails : [];

       let uid = getToken().id;
       let rid = custDet[0].cust_refno;
       let cid = custDet[0].cust_cif;
       updateRecordLockStatus(rid,uid,cid,'0');
       setBackTopage(true);
     //  history.goBack();

       //     window.location.href = ('/rekyc?status=pending');

   }
    const showVersion = () =>{
        setVersion(!version) ;
    }

    let timeOut = systemconfigdata ? systemconfigdata[0].Value : '';
console.log(listlockdata);
console.log(rolesectiondata);
    return (
        <React.Fragment>

                    <div className="pageSidebar show">
                        <IdleTimerComponent
                            custdetails={custdetails} systemconfigdata = {timeOut} backtopage={backtopage}/>

                        <a onClick={() => showVersion()} className="logoIn">
                            <img src={logo} className="logo__image"/>
                        </a>
                        <ul className="sidebarList">
                            <li>
                                <a onClick={() => onFocusClick('livenessRef')}
                                      className={`sidebarList__item ${activeView === 'livenessRef' ? 'sidebarList__item--active' : ''}`}>Liveness
                                    Video</a>
                            </li>
                            <li>
                                <a onClick={() => onFocusClick('idRef')}
                                      className={`sidebarList__item ${activeView === 'idRef' ? 'sidebarList__item--active' : ''}`}>ID
                                    Scan</a>
                            </li>
                            {custdetails && custdetails[0] && custdetails[0].updation_type.toUpperCase() !== 'LID' &&
                                <li>
                                <a onClick={() => onFocusClick('formRef')}
                                className={`sidebarList__item ${activeView === 'formRef' ? 'sidebarList__item--active' : ''}`}>e-KYC
                                Form</a>
                                </li>
                            }

                        </ul>
                        {version &&
                        <p className="flexCE p30">v37</p>
                        }
                    </div>
                    {custdetails && custdetails.map((item, index) => {
                        return (

                            <div className="pageSidebarOffset">
                                {pageloader &&
                                <Loader/>
                                }
                                <navbar className="pageNav pageNav--flexCol">
                                    <div className="pageNav__top" ref={backtotop}>
                                        <Link  onClick={(e) => resetLockRecords(e)} className="iconAnchor"><span
                                            className="iconIn iconIn--backArrow"></span>Back</Link>
                                        <div className="pageNav__buttonWrapper">
                                            {/*  <a  onClick={() => history.goBack()} href="#" className="btn btn--primaryOuter btn--capsule btn--sm btn--icon">
                <span className="iconIn iconIn--export" />
                Export
            </a>  */}
                                            <div className="relBlock">
                                                {(mystatus === null || mystatus === "Pending" || mystatus === "Forward") ? (
                                                    item.cust_id_type.toLowerCase() != 'non-resident' &&
                                                    <>
                                                        <a className="btn btn--primaryG btn--capsule btn--sm"
                                                           onClick={() => setUserOption(!userOption)}>Approve/Reject</a>

                                                        {
                                                            userOption && (
                                                                <ul className="userOptionList">

                                                                    <li onClick={() => modalSet('accept')}>Accept</li>
                                                                    <li onClick={() => modalSet('reject')}>Reject</li>

                                                                   {(userrole === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '2' && item.actionId !== '2') > -1))  &&
                                                                  <>
                                                                   <li onClick={() => modalSet('hold')}>Return to Customer</li>
                                                                   <li onClick={() => modalSet('forward')}>Forward</li>
                                                                   </>
                                                                    }
                                                                </ul>
                                                            )
                                                        }

                                                    </>

                                                ) : ''}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="pageNav__bottom">
                                        <div className="halfBlock">
                                            <div className="fullFlexBox">
                                                {/* <div className="userImageBlock">
            <div className="userImageIn"></div>
            </div>  */}
                                                <div className="userImageBlock--offset">
                                                    <div className="gridEachContent">
                                                        <div className="gridEachContentEach">
                                                            <label className="gridEachContentEach__label">Full
                                                                Name</label>
                                                            <p className="gridEachContentEach__text">{item.cust_name}</p>
                                                        </div>
                                                        <div className="gridEachContentEach">
                                                            <label className="gridEachContentEach__label">Reference
                                                                ID</label>
                                                            <p className="gridEachContentEach__text">{item.cust_refno}</p>
                                                        </div>

                                                        {(item.created_date || []).split(" ").map(function (item1, i) {
                                                            return <div key={i} className="gridEachContentEach">
                                                                <label
                                                                    className="gridEachContentEach__label">{i == 0 ? 'Date' : 'Time'}</label>
                                                                <p className="gridEachContentEach__text">{i == 0 ? item1 : item1.substring(0, item1.lastIndexOf("."))}</p>
                                                            </div>
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="halfBlock">
                                            <div className="gridEachContent">
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Mobile Number</label>
                                                    <p className="gridEachContentEach__text">{item.cust_mobile}</p>
                                                </div>
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Email Address</label>
                                                    <p className="gridEachContentEach__text">{item.cust_email}</p>
                                                </div>
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">CIF</label>
                                                    <p className="gridEachContentEach__text">{item.cust_cif}</p>
                                                </div>
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Bar Code</label>
                                                    <p className="gridEachContentEach__text">{item.cust_barcode}</p>
                                                </div>
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Status</label>
                                                    <p className="gridEachContentEach__text">{item.status}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </navbar>

                                <div className="paddingBlock" ref={livenessRef}>
                                    {livevideo !== "" ?
                                        <div className="fullFlexBox">
                                            <div className="w55p">
                                                <h5 className="title">Liveness Video</h5>

                                                <div className="videoFrame">
                                                    <div className="videoFrame__holder">
                                                        <video src={`data:video/mp4;base64,${livevideo}`}
                                                               controls="controls"
                                                               width="100%" height="300" loop="loop"/>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="w45p">
                                                <div className="gridEachContent flexCE">
                                                    {/* <div className="gridEachContentEach">
                                                <label className="gridEachContentEach__label">Created On</label>
                                                <p className="gridEachContentEach__text">{item.created_date}</p>
                                            </div>  */}
                                                    <span
                                                        className="highlightBox cursorP">Face Matching : {(facematchconfidence && facematchconfidence.fvconfidence) ? facematchconfidence.fvconfidence : '0'}%</span>
                                                    {mystatus === "Rejected" ? (
                                                        <div className="gridEachContentEach">
                                                            <label className="gridEachContentEach__label">Reason for
                                                                Rejection</label>
                                                            <p className="gridEachContentEach__text">{item.final_status_reason}</p>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                                {(mystatus === "Rejected" && item.final_status_reason === 'Others') ? (
                                                    <div className="borderTBox">
                                                        <span className="highlightBox cursorP">Remarks</span>
                                                        <p className="lgText">{item.final_status_comment}</p>
                                                    </div>
                                                ) : ''}

                                            </div>
                                        </div>
                                        : (<div className="wPanel--loader"><img width={50} src={Loader}
                                                                                className="loader" alt=""/></div>)}


                                    <div className="fullBlock" ref={idRef}>
                                        <div className="flexCB">
                                            <h5 className="title">Scanned ID Card</h5>
                                            <div className="flexSS flexCol">
                                                <span
                                                    className="highlightBox cursorP mV5">Name Matching : {item.namematch_score}%</span>
                                                <span
                                                    className="highlightBox cursorP mV5">Total Score : {item.namematch_score}%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fullFlexBox">

                                                {listimage.map((image) => (
                                                    <div className="halfBlock">
                                                        <img src={`data:image/jpeg;base64,${image}`}
                                                             className="responsive" alt="idCard"/>
                                                    </div>
                                                ))}
                                    </div>
                                    {item.updation_type.toUpperCase() !== 'LID' &&
                                    <>
                                    <div ref={formRef} className="fullBlock">
                                        <div className="flexCB">
                                            <h5 className="title">e-KYC Form</h5>
                                        </div>
                                    </div>
                                    <div className="fullFlexBox">
                                        <div className="halfBlock">
                                            <div className="card">
                                                <h4 className="cardHead">Basic Details</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Country of
                                                            Birth</label>
                                                        <p className="gridEachContentEach__text">{item.cust_birth_country}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Marital
                                                            Status</label>
                                                        <p className="gridEachContentEach__text">{item.cust_marital_status}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Name</label>
                                                        <p className="gridEachContentEach__text">{item.cust_name}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Residence
                                                            Address</label>
                                                        <p className="gridEachContentEach__text">{item.cust_house_flat}, {item.cust_building_no}, {item.cust_way_no}, {item.cust_city}, {item.cust_wilayat}, {item.cust_pincode}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="halfBlock">
                                            <div className="card">
                                                <h4 className="cardHead">Employment Details</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Are you
                                                            Employed</label>
                                                        <p className="gridEachContentEach__text">{item.cust_employed}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Monthly Income
                                                            (OMR)</label>
                                                        <p className="gridEachContentEach__text">{item.monthly_income}</p>
                                                    </div>
                                                    {item.cust_employed === "NO" ? (
                                                        <div className="gridEachContentEach">
                                                            <label className="gridEachContentEach__label">Select if
                                                                Unemployed</label>
                                                            <p className="gridEachContentEach__text">{item.cust_select_unemployed}</p>
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="halfBlock">
                                            <div className="card">
                                                <h4 className="cardHead">US Indicia </h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">US Tax
                                                            Resident</label>
                                                        <p className="gridEachContentEach__text">{item.cust_US_tax_resident ? item.cust_US_tax_resident : 'No'}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Address/Telephone
                                                            Number in US</label>
                                                        <p className="gridEachContentEach__text">{item.cust_US_address_tel ? item.cust_US_address_tel : 'No'}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">US
                                                            Nationality/Green Card</label>
                                                        <p className="gridEachContentEach__text">{item.cust_US_nationality ? item.cust_US_nationality : 'No'}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Make ongoing
                                                            payments to the US</label>
                                                        <p className="gridEachContentEach__text">{item.cust_US_payments ? item.cust_US_payments : 'No'}</p>
                                                    </div>

                                                    {additioninfo && additioninfo.showW8 ? (
                                                        <div className="gridEachContentEach"
                                                             onClick={(e) => showFilePreview('W8Form')}>
                                                            <label className="gridEachContentEach__label">W8BEN</label>
                                                            <p className="gridEachContentEach__text">
            <span className="highlightBox cursorP">
            <span className="iconIn iconIn--doc"/>
            W8Scanned Copy
        </span>


                                                            </p>
                                                        </div>
                                                    ) : ''}
                                                    {additioninfo && additioninfo.showW9 ? (
                                                        <div className="gridEachContentEach"
                                                             onClick={(e) => showFilePreview('W9Form')}>
                                                            <label className="gridEachContentEach__label">W9</label>
                                                            <p className="gridEachContentEach__text">
            <span className="highlightBox cursorP">
            <span className="iconIn iconIn--doc"/>
            W9Scanned Copy
        </span>
                                                            </p>
                                                        </div>
                                                    ) : ''}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="halfBlock">
                                            <div className="card">
                                                <h4 className="cardHead">CRS {item.cust_taxresident_notoman}</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Are you a tax
                                                            resident in any country other than Sultanate Oman</label>
                                                        <p className="gridEachContentEach__text">{item.cust_taxresident_notoman ? item.cust_taxresident_notoman : 'No'}</p>
                                                    </div>

                                                    {item.cust_country_taxresidence && item.cust_country_taxresidence.length > 0 &&

                                                    JSON.parse(item.cust_country_taxresidence).map((item, index) => {
                                                        let count =  parseInt(index )+ 1;
                                                        return (
                                                    <>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Country of Tax
                                                            Residence{count}</label>
                                                        <p className="gridEachContentEach__text">{(item.country_taxresidence && countrydata.length > 0 )? countrydata.filter(item1 => item1.CCode === item.country_taxresidence)[0].CName : ''}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">TIN Number {count}</label>
                                                        <p className="gridEachContentEach__text">{item.tinnumber}</p>
                                                    </div>
                                                    </>
                                                            )})}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fullFlexBox">
                                        <div className="fullInBlock">
                                            <div className="card">
                                                <h4 className="cardHead">Politically Exposed Person/Prominent Position
                                                    (PEP)</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Do you or any of
                                                            your direct relative(s) currently hold/previously held any
                                                            of the following mentioned positions(s)?</label>
                                                        <p className="gridEachContentEach__text">{item.cust_pep_relative ? item.cust_pep_relative : "No"}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Are you a close
                                                            business associate of any of the following mentioned
                                                            positions(5)?</label>
                                                        <p className="gridEachContentEach__text">{item.cust_pep_busns_associate ? item.cust_pep_relative : "No"}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Name</label>
                                                        <p className="gridEachContentEach__text">{item.cust_pep_orgname_directv}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Positions</label>
                                                        <p className="gridEachContentEach__text">{item.cust_pep_position_directv}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="fullFlexBox">
                                        <div className="fullInBlock">
                                            <div className="card">
                                                <h4 className="cardHead">Additional Documents</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Address
                                                            Proof</label>
                                                        {additioninfo && additioninfo.showaddress_proof ? (
                                                            <p className="gridEachContentEach__text"
                                                               onClick={(e) => showFilePreview('AddressProof')}>
                                    <span className="highlightBox cursorP">
                                    <span className="iconIn iconIn--doc"/>
                                        {additioninfo && additioninfo.selectedfilenameaddress_proof}
                                    </span>
                                                            </p>
                                                        ) : ''}

                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Please confirm if
                                                            the proof is in your name or relative/other name Y/N</label>
                                                        <p className="gridEachContentEach__text">{additioninfo && additioninfo.address_proof_own_name}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Select
                                                            Relationship Type</label>
                                                        <p className="gridEachContentEach__text">{additioninfo && additioninfo.relatioshipaddrs ? additioninfo.relatioshipaddrs : '-'}</p>
                                                    </div>
                                                    {additioninfo && additioninfo.showauthorisation_letter ? (
                                                        <div className="gridEachContentEach">
                                                            <label className="gridEachContentEach__label">Authorisation
                                                                Letter</label>
                                                            <p className="gridEachContentEach__text"
                                                               onClick={(e) => showFilePreview('AddressAuthLetter')}>
                                    <span className="highlightBox cursorP">
                                    <span className="iconIn iconIn--doc"/>
                                        {additioninfo && additioninfo.selectedfilenameauthorisation_letter}
                                    </span>
                                                            </p>

                                                        </div>
                                                    ) : ''}
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Type of
                                                            Employment</label>
                                                        <p className="gridEachContentEach__text">{item.employmentStatus} </p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Are you receiving
                                                            salary to Bank Muscat? Y/N</label>
                                                        <p className="gridEachContentEach__text">{additioninfo && additioninfo.salary_bom ? additioninfo.salary_bom : 'No'}</p>
                                                    </div>
                                                    {additioninfo && additioninfo.filenameadditionaldoc &&
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Additional
                                                            Documents</label>
                                                        {additioninfo && additioninfo.filenameadditionaldoc ? (
                                                            <p className="gridEachContentEach__text"
                                                               onClick={(e) => showFilePreview('AdditionalDoc')}>
                                    <span className="highlightBox cursorP">
                                    <span className="iconIn iconIn--doc"/>
                                        {additioninfo && additioninfo.filenameadditionaldoc}
                                    </span>
                                                            </p>
                                                        ) : ''}

                                                    </div>
                                                    }
                                                    {item.employmentStatus !== 'Unemployed' &&
                                                    <div className="gridEachContentEach">
                                                        <label
                                                            className="gridEachContentEach__label">{item.employmentStatus} Proof</label>
                                                        <p className="gridEachContentEach__text">
                                                            {additioninfo && additioninfo.showemployment_proof ? (
                                                                <span className="highlightBox cursorP"
                                                                      onClick={(e) => showFilePreview('EmpProof')}>
                           <span className="iconIn iconIn--doc"/>
                                                                    {additioninfo && additioninfo.selectedfilenameemployment_proof}
                         </span>

                                                            ) : (
                                                                <span className="highlightBox cursorP"
                                                                      onClick={(e) => showFilePreview('SelfEmpProof')}>
                               <span className="iconIn iconIn--doc"/>
                                                                    {additioninfo && additioninfo.selectedfilenameselfemployment_proof}
                                </span>

                                                            )}
                                                        </p>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.cust_id_type.toLowerCase() === 'non-resident' &&
                                    <div className="fullFlexBox">
                                        <div className="fullInBlock">
                                            <div className="card">
                                                <h4 className="cardHead">Resident to Non-Resident For</h4>
                                                <div className="gridEachContent">
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Own property in self or blood relative name in Oman ? </label>
                                                        <p className="gridEachContentEach__text">{item.cust_ownproperty_oman ? item.cust_ownproperty_oman : "No"}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Have first degree blood relatives/ family members in Oman?</label>
                                                        <p className="gridEachContentEach__text">{item.cust_relative_family_oman ? item.cust_relative_family_oman : "No"}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Own business or a shareholder in an Oman registered company</label>
                                                        <p className="gridEachContentEach__text">{item.cust_own_business_oman ? item.cust_own_business_oman :'No'}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Expecting end of employment/ service benefits/ compensation from previous employer</label>
                                                        <p className="gridEachContentEach__text">{item.cust_benefit_from_emplyr ? item.cust_benefit_from_emplyr : 'No' }</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Other reasons to continue the account and I authorize Bank to block credit of funds in my account (no document required)</label>
                                                        <p className="gridEachContentEach__text">{item.cust_authorize_account_transfer ? item.cust_authorize_account_transfer: 'No'}</p>
                                                    </div>
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Other reason if any </label>
                                                        <p className="gridEachContentEach__text">{item.other_reason ? item.other_reason : 'No'}</p>
                                                    </div>
                                                {item.cust_accholding_reason1 &&
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Reason1</label>
                                                    <p className="gridEachContentEach__text">{item.cust_accholding_reason1}</p>
                                                </div>}
                                                {item.cust_accholding_reason2 &&
                                                <div className="gridEachContentEach">
                                                    <label className="gridEachContentEach__label">Reason2</label>
                                                    <p className="gridEachContentEach__text">{item.cust_accholding_reason2}</p>
                                                </div>}
                                                    <div className="gridEachContentEach">
                                                        <label className="gridEachContentEach__label">Supporting documents for changing customer status</label>
                                                        {additioninfo && additioninfo.filenamedocresidenttononresident ? (
                                                            <p className="gridEachContentEach__text"
                                                               onClick={(e) => showFilePreview('ResidentToNonResident')}>
                                    <span className="highlightBox cursorP">
                                    <span className="iconIn iconIn--doc"/>
                                        {additioninfo && additioninfo.filenamedocresidenttononresident}
                                    </span>
                                                            </p>
                                                        ) : ''}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    }

                                </div>
                                <div className="flexCE mb30" onClick={() => onFocusClick('backtotop')}>
            <span className="btn btn--primaryG btn--capsule btn--sm">
            <span className="iconIn icon--toTop"/>
            Back to Top
        </span>
                                </div>
                            </div>
                        )
                    })}
                    {
                        modal && (
                            <div className={`csModal csModal--md csModal--active statusModal`}>
                                <div className="csModal__content">
                                    {statusloader &&
                                    <Loader/>
                                    }
                                    <span className="csModal__out" onClick={() => modalOut()}/>
                                    {
                                        modalContent === 'accept' && (
                                            <>
                                                <h3>Accept Re-KYC</h3>
                                                {btnstatus === true && apprvcomments &&
                                                <p>Comments : {apprvcomments}</p>
                                                }
                                                <p className="messageIn">{message}</p>
                                                {btnstatus === false &&
                                                <>
                                <textarea className="modalTextarea" value={apprvcomments}
                                          onChange={(e) => setApprvcomments(e.target.value)}
                                          placeholder="Notes and Comments (Optional)"></textarea>
                                                    <div className="btnHolderFull">
                                        <span onClick={() => statusUpdateKYC("Approved")}
                                              className="btn btn--capsule btn--inFull btn--green">Accept Re-KYC </span>
                                                    </div>
                                                </>
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        modalContent === 'reject' && (
                                            <>
                                                <h3>Reject Re-KYC</h3>
                                                {btnstatus === true && rejectreason &&
                                                <p>Reason for rejection : {rejectreason}</p>
                                                }
                                                <p className="messageIn">{message}</p>

                                                {btnstatus === false &&
                                                <>
                                                    <select className={`modalSelect ${errorstatus ? 'error' : ''} `}
                                                            onChange={(e) => setRejectreason(e.target.value)}>
                                                        <option selected disabled>Please select the reason for
                                                            rejection
                                                        </option>
                                                        <option>Scanned Image Not clear/ Relevant</option>
                                                        <option>Resident Address proof missing/not clear</option>
                                                        <option>Employment proof missing/ not clear</option>
                                                        <option>FATCA form missing/ Incomplete</option>
                                                        <option>Civil ID / Resident ID / PP is missing</option>
                                                        <option>NRID is must for GCC, Diplomates and Investors
                                                            customers
                                                        </option>
                                                        <option>Others</option>
                                                    </select>
                                                    {rejectreason === 'Others' &&
                                                    <textarea className="modalTextarea" value={rejectcomments}
                                                              onChange={(e) => setRejectcomments(e.target.value)}
                                                              placeholder="Please mention the reason you are REJECTING this customer’s e-KYC"></textarea>
                                                    }
                                                    <div className="btnHolderFull">
                                                        <span onClick={() => statusUpdateKYC("Rejected")}
                                                              className="btn btn--capsule btn--inFull btn--primaryG">Reject Re-KYC </span>
                                                    </div>
                                                </>
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        modalContent === 'forward' && (
                                            <>
                                                <h3>Forward Re-KYC</h3>
                                                {btnstatus === true && forwardcomments &&
                                                <p>Comments : {forwardcomments}</p>
                                                }
                                                <p className="messageIn">{message}</p>
                                                {btnstatus === false &&
                                                <>
                                   <textarea className="modalTextarea" value={forwardcomments}
                                          onChange={(e) => setForwardcomments(e.target.value)}
                                          placeholder="Notes and Comments (Optional)"></textarea>
                                                    <div className="btnHolderFull">
                                                        <span onClick={() => statusUpdateKYC("Forward")}
                                                              className="btn btn--capsule btn--inFull btn--primaryOuter">Forward Re-KYC</span>
                                                    </div>
                                                </>
                                                }

                                            </>
                                        )
                                    }

                                    {
                                        modalContent === 'hold' && (
                                            <>
                                                <h3>Return to customer Re-KYC</h3>
                                                {btnstatus === true && onholdreason &&
                                                <p>Reason for return to customer : {onholdreason}</p>
                                                }
                                                <p className="messageIn">{message}</p>
                                                {btnstatus === false &&
                                                <>
                                                    <select className={`modalSelect ${errorstatus ? 'error' : ''} `}
                                                            onChange={(e) => setOnholdreason(e.target.value)}>
                                                        <option selected disabled>Please select the reason for Return to customer
                                                        </option>
                                                        <option>Scanned Image Not clear/ Relevant</option>
                                                        <option>Resident Address proof missing/not clear</option>
                                                        <option>Employment proof missing/ not clear</option>
                                                        <option>FATCA form missing/ Incomplete</option>
                                                        <option>Civil ID / Resident ID / PP is missing</option>
                                                        <option>NRID is must for GCC, Diplomates and Investors
                                                            customers
                                                        </option>
                                                        <option>Others</option>
                                                    </select>
                                                    {onholdreason === 'Others' &&
                                                    <textarea className="modalTextarea" value={onholdcomments}
                                                              onChange={(e) => setOnholdcomments(e.target.value)}
                                                              placeholder="Please mention the reason for returning to customer’s e-KYC"></textarea>
                                                    }
                                                    <div className="btnHolderFull">
                                                        <span onClick={() => statusUpdateKYC("On Hold")}
                                                              className="btn btn--capsule btn--inFull btn--yellowG">Return to customer</span>
                                                    </div>
                                                </>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {previewModal !== '' &&
                    <div className={`csModal csModal--active statusModal iframeModal`}>
                        <div className="csModal__content">
                            <span className="csModal__out" onClick={() => previewModalOut()}/>
                            <div className="fullBlock">
                                {documentarray[previewModal + '_ftype'] === 'img' ?
                                    <img src={documentarray[previewModal]} style={{width: '100%', height: '100%'}}/>
                                    :
                                    <iframe src={documentarray[previewModal]}
                                            width="100%" height="500px"/>
                                }

                            </div>
                        </div>
                    </div>
                    }
                    {lockstatus !== '' && (listlockdata !== undefined && listlockdata.length >0 && listlockdata[0].user_id != getToken().id) && (custdetails[0].status === 'Pending' || custdetails[0].status === 'Forward') &&
                    <div className={`csModal csModal--md csModal--active statusModal`}>
                        <div className="csModal__content">
                            <div className="fullBlock">
                                <p>This section is Locked temporary</p>
                                <div className="flexCC buttonWrap">
                                    {userrole === 'Checker' ?
                                    <Link to="/rekyc?status=approved"><span
                                        className="btn btn--primaryG btn--sm btn--capsule">Back</span></Link> :
                                        <Link to="/rekyc?status=pending"><span
                                        className="btn btn--primaryG btn--sm btn--capsule">Back</span></Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    }


        </React.Fragment>
    )
}

export default DetailView