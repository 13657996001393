import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { stpReports} from "../actions/User";
import Selectbox from "../components/Selectbox";
import { CSVLink } from "react-csv";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken,forceDownload} from "../utilities/AuthService";
import Loader from "../components/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";

const Reportsstp = () => {
    const [hasError, setErrors] = useState(false);
    const [sortbyfilter, setSortbyfilter] = useState([{"label": "All", "value": "0"},{"label": "Today", "value": "1"},{"label": "Last 7 days", "value": "7"},{"label": "Last 30 Days", "value": "30"},{"label": "Last 3 months", "value": "3"},{"label": "Last 6 months", "value": "6"},{"label": "Custom", "value": "custom"}]);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "Name", "value": "name"},{"label": "CIF", "value": "cif"},{"label": "Mobile", "value": "mobile"}]);
    const [custdata, setCustdata] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);
    const [searchby, setInputSearchby] = useState('');
    const [searchval, setSearchVal] = useState('' );
    const [valuesloader, setValuesloader] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [customDate, setCustomDate] = useState(false);
    const [statusloader, setStatusloader] = useState(false);

    const [inputValues, _setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '',incomerange: '' , updatedmincome: {"status": "pending"}, dateFilter:0 }
    );

    const listcustdata = useSelector(state => state.UserReducer.stpReportdata) ;

    let history = useHistory();
    const dispatch = useDispatch();


    const myStateRef = React.useRef(inputValues);
    const setInputValues = data => {
        let values = inputValues;
        for (var key in data) {
            values[key] = data[key];
        }
        myStateRef.current = values;
        _setInputValues(values);
    };
    useEffect(() => {
        console.log("userid: "+userid) ;
        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;

    },[dispatch]);
    // useEffect(() => {
    //     const reloadCount = sessionStorage.getItem('reloadCount');
    //     if(reloadCount < 2) {
    //         console.log(3434);
    //         sessionStorage.setItem('reloadCount', String(reloadCount + 1));
    //         window.location.reload();
    //     } else {
    //         sessionStorage.removeItem('reloadCount');
    //     }
    // }, []);

    useEffect(() => {
        setValuesloader(false);
        setCustdata(listcustdata)
    },[listcustdata]);


    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const handleFilterChange = (value,name) =>{
        setInputSearchby(value);
    }
    const searchValChange =(searchval)=>{
        setValuesloader(true);
        setSearchVal(searchval);
        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;
    }
    const handledateFilter = (value) => {
        const val = ['1', '7', '30'];
        if(value !== "0" && value != "custom") {
            setInputValues({ "startDate": '',"endDate": ''}) ;
            setCustomDate(false);
            if (val.indexOf(value) > -1) {

                let filterCat = 'day';
                setInputValues({"filterCat": 'day'});
            } else {
                let filterCat = 'month'
                setInputValues({"filterCat": 'month'});
            }
            setInputValues({"dateFilter": value});
            setStatusloader(true);
            setValuesloader(true);

            const strJSON = {
                sub_date: ''
            }
            const myObjStr = JSON.stringify(strJSON);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');

            const myinputValues = JSON.stringify(inputValues);
            const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;

        } else {

            setCustomDate(false);
            if(value === "0"){
                setInputValues({"filterCat": '',"dateFilter":'',"startDate": '',"endDate": ''});
                setInputValues({"dateFilter": value});
                setStatusloader(true);
                setValuesloader(true);

                const strJSON = {
                    sub_date: ''
                }
                const myObjStr = JSON.stringify(strJSON);
                const enc_myObjStr = new Buffer(myObjStr).toString('base64');
                setCustomDate(false);

                const myinputValues = JSON.stringify(inputValues);
                const enc_myinputValues = new Buffer(myinputValues).toString('base64');
                dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;

            }else{
                setCustomDate(true);
                setInputValues({"filterCat": 'custom',"dateFilter":'custom'});
            }
        }
    }

    const handleCustomStartDate = (date) =>{
        let startDate = moment(date).format('YYYY-MM-DD')
        setStartDate(date)
        setInputValues({ "startDate": startDate ,"dateFilter" : 'custom'}) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        setValuesloader(true);

        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;
    }
    const handleCustomEndDate = (date) =>{
        let endDate = moment(date).format('YYYY-MM-DD')
        setEndDate(date)
        setInputValues({ "endDate": endDate,"dateFilter" : 'custom' }) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        setValuesloader(true);

        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(stpReports(enc_myObjStr,searchval,searchby,enc_myinputValues)) ;
    }

    return (
        <React.Fragment>
            <Pagesidebar menu="STP" mainmenu="Reports" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Reports"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav} searchParam = {searchbyvalue} searchChange = {handleFilterChange} searchValChange = {searchValChange}/>
                </navbar>


                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head ">
                        <div className="flexCB">
                        <h2 style={{marginRight: '30px'}}>STP</h2>
                        <div className="sortBox__check flexCB csLabeled">
                            <label  className="csForm__item__label">Sort By Date :</label>
                            <Selectbox label="Date filter" name="dateFilter"  labelStatus = "No" values={sortbyfilter} onChange={handledateFilter} selvalue={inputValues.dateFilter}/>
                        </div>
                        {customDate &&
                        <div className="sortBox__check">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => handleCustomStartDate(date)}
                                placeholderText="From Date"
                                dateFormat="MM/dd/yyyy"
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => handleCustomEndDate(date)}
                                placeholderText="End Date"
                                dateFormat="MM/dd/yyyy"
                            />
                        </div>
                        }

                        {/*<Link to="/add-monthlyincome" className="btn btn--primaryG btn--capsule">(+) Monthly Income</Link>*/}
                        </div>
                        {custdata && custdata.length > 0 &&
                            <CSVLink
                                data={custdata}
                                filename={"StpReport.csv"}
                                className={"btn btn--primaryG btn--sm btn--capsule"}
                                target="_blank"
                            >
                                Export as CSV
                            </CSVLink>
                        }
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    CIF
                                </th>
                                <th>
                                    Mobile
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Customer Type
                                </th>
                                <th>
                                    STP
                                </th>
                                <th>
                                    PEP
                                </th>
                                <th>
                                    US Taxresident
                                </th>
                                <th>

                                </th>

                            </tr>
                            </thead>
                            {custdata && custdata.length > 0 ?
                                <tbody>
                                {custdata && custdata.map((item, index) => {
                                    console.log(item);
                                    return (
                                        <tr>
                                            <td>

                                                {index + 1}
                                            </td>
                                            <td>
                                                {item.created_date && item.created_date.substring(0, item.created_date.lastIndexOf("."))}
                                            </td>
                                            <td>

                                                {item.cust_name}

                                            </td>
                                            <td>
                                                {item.cust_cif}
                                            </td>
                                            <td>
                                                {item.cust_mobile}
                                            </td>
                                            <td>
                                                {item.cust_email}
                                            </td>
                                            <td>
                                                {item.cust_id_type ? item.cust_id_type : 'National'}
                                            </td>
                                            <td>
                                                Yes
                                            </td>
                                            <td>
                                                {item.pep === "1" ? "Yes" : 'No'}
                                            </td>
                                            <td>
                                                {item.usfatca === "1" ? "Yes" : 'No'}
                                            </td>
                                            <td>
                                                <Link className="iconIn iconIn--eye-primary "
                                                      to={`/detail-view/?id=${item.id}`}> </Link>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody> :
                                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>

                            }
                        </table>
                    </div>
                </div>
                    </div>

        </React.Fragment>
    )
}

export default Reportsstp
