import React, {useEffect, useReducer, useState} from 'react';
import {Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import logo from '../assets/images/logo.png';
import useravatar from "../assets/images/user.png";
import {FetchMenu } from "../actions/Menu";
import {getToken} from "../utilities/AuthService";
import {getConfig, UserLogout} from "../actions/Authorization";
import Selectbox from "./Selectbox";
import {getSystemConfig} from "../actions/User";
import IdleTimerComponent from "../pages/IdleTimerSet";


const Navbar = (props) => {
    const [sidebar, setSidebar] = useState(true);
    const [userOption, setUserOption] = useState(false)
    const [menu, setMenu] = useState('Roles');
    const [mastermenu, setMastermenu] = useState('Masters');
    const [user, setUser] = useState(getToken().name);
    const [userrole, setUserrole] = useState(getToken().role);
    const [search, setSearch] = useState('');
    const [searcherror, setSearchError] = useState(false);
    const [menuItems, setMenuItems] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {menu: '', master: '' }
    );

    const dispatch = useDispatch();
    const menudata = useSelector(state => state.MenuReducer.fetchmenudata) ;
    const systemconfigdata = useSelector(state => state.UserReducer.systemconfigdata) ;

    useEffect(() => {
        dispatch(getConfig());
        dispatch(FetchMenu(menu,mastermenu)) ;
        dispatch(getSystemConfig('AgentSessionInterval')) ;
        console.log(localStorage.getItem("AL_userid"));
        var userid = localStorage.getItem("AL_userid") ? localStorage.getItem("AL_userid") : '';
        console.log(userid);
        if(!userid){
            window.location.href = ('/');
            dispatch(UserLogout()) ;
        }
        console.log(menu+" "+mastermenu) ;
    }, dispatch);




    useEffect(() => {
        if(menudata !== undefined){
            setMenuItems({ "menu" : menudata.cmenu, "master" : menudata.pmenu});
        }

    }, [menudata])



    const activeSet = (idIn) => {
        var items_in = document.querySelectorAll('.iconMasters');
        if (document.getElementById(`listItem-`+idIn).classList.contains('active')) {
            return false
        }
        else {
            for (var i=0; i< items_in.length; i++) {
                items_in[i].classList.remove('active');
            }
            document.getElementById(`listItem-`+idIn).classList.add('active');
        }
    }


    const setmenuItem = (item) =>{
        setMenu(item);
        setMastermenu('Masters');
        console.log(menu+" "+mastermenu) ;
    }

    const toggleSidebar = () =>{
        setSidebar(!sidebar) ;
        props.changeNav();
    }


    const logout = () =>{
        dispatch(UserLogout()) ;
    }

    const searchToggle = () =>{
        if(search) {
            setSearchError(false);
            props.searchValChange(search);
        } else{
            setSearchError(true);
        }
    }
    const searchInput = (e) =>{
        setSearchError(false);
        setSearch(e.target.value) ;
        //    props.searchValChange(e.target.value);
    }
console.log(searcherror);
console.log(systemconfigdata);
    let timeOut = systemconfigdata ? systemconfigdata[0].Value : '';

    return (
        <React.Fragment>
            <IdleTimerComponent
               systemconfigdata = {timeOut}  type = {'logout'}/>
            <div className="pageNav__left">
                    <span className={`hamburger ${sidebar && 'active'}`} onClick={toggleSidebar}>
                        <label></label>
                    </span>
                <h2>{props.pagettl}</h2>
            </div>
            <div className="pageNav__middle">
                { props.search  === "Yes" &&
            <div className="multipleOptionBlock">

                { props.filter === "Yes" ?
                    ( <select className="fieldSelect">
                            <>
                                <option selected disabled>Filter By</option>
                                { props.filterlist && props.filterlist.map(itemrow => {
                                    return (
                                        <option>{itemrow.item}</option>
                                    )
                                })
                                }

                            </>
                        </select>
                    )
                    : '' }
                { props.search  === "Yes"  &&  props.searchParam &&
                <Selectbox label="Search by" name="searchby" labelStatus = "No" values={props.searchParam}
                           onChange={props.searchChange} selvalue={''}/>
                }
                <div className="searchBlock">
                    { props.search  === "Yes" ?
                        (
                            <>
                                <input type="text" className="searchBlock__input" name= "searchVal" onChange={searchInput} placeholder={props.searchLabel ? props.searchLabel : "Search Something..."}/>
                                <span className="iconIn iconIn--search searchBlock__icon" name="searchvalue" onClick={searchToggle}/>
                            </>
                        ) : ''
                    }
                </div>
                { searcherror &&
                    <p className="errorMsg" >Please enter value for search</p>
                }
            </div>
                }
            </div>
            <div className="pageNav__right">
                <div className="userBlock"   onClick={() => setUserOption(!userOption)}  >
                    <div className="userBlock__details">
                        <h6 className="userName">Agent Login</h6>
                        <p className="userRole">{user}</p>
                    </div>
                    <div className="userBlock__image">
                        <img src={useravatar} className="userBlock__image__in" alt="user" />
                    </div>
                    {
                        userOption && (
                            <div className="userBlock__logout"  id="userOptionList">
                                <ul className="userOptionList"  onClick={logout}>
                                    <li>
                                        <span className="">Log out</span>
                                    </li>
                                </ul>
                            </div>
                        )}

                </div>
            </div>


        </React.Fragment>

    )
}
export default Navbar
