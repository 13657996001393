import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListUnemployedStatus,
    FetchUnemployedStatus,
    EditUnemployedStatus,
    AddPendingApprovals,
    FetchMastersLog, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListUnemployedStatuses = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [modalIn, setModalIn] = useState(false);
    const [unemployedstatus, setUnemployedstatus] = useState([]);
    const [selunemployedstatusid, setSelunemployedstatusidd] = useState('');
    const [selectedunemployedstatus, setSelectedunemployedstatus] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchval, setSearchVal] = useState('' );
    const [valuesloader, setValuesloader] = useState(false);
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '',title: '' , titlear : '',updatedunemployedstatus: {"status": "pending"}}
    );

    const unemployedstatusdata = useSelector(state => state.UserReducer.unemployedstatusdata) ;
    const selunemployedstatusdata = useSelector(state => state.UserReducer.fetchunemployedstatusdata) ;
    const updunemployedstatusdata = useSelector(state => state.UserReducer.updunemployedstatusdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ListUnemployedStatus(searchval)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    },[dispatch]);

    useEffect(() => {
        setValuesloader(false);
        setUnemployedstatus(unemployedstatusdata)
    },[unemployedstatusdata]);

    useEffect(() => {
        setInputValues({ "updatedunemployedstatus" : updunemployedstatusdata });
    },[updunemployedstatusdata]);

    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);
    useEffect(() => {

        //console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);

            }
        }
    },[newdatarow]);


    useEffect(() => {
        console.log(selunemployedstatusdata) ;

        setSelectedunemployedstatus(selunemployedstatusdata)
        if(selunemployedstatusdata[0] !== undefined){
            let Title_Ar = selunemployedstatusdata[0].Title_Ar ? selunemployedstatusdata[0].Title_Ar : '';
            setInputValues({ "title": selunemployedstatusdata[0].Title,"titlear": Title_Ar, "selactive" : selunemployedstatusdata[0].IsActive, "updatedunemployedstatus" : {"status": "pending"} });
        }

    },[selunemployedstatusdata]);


    const toggleModal = (id) => {
        console.log(id) ;
        setStatus(false);
        setButtonStatus(false);
        if(id == 0){
            dispatch(ListUnemployedStatus(searchval)) ;
        }else{
            setSelunemployedstatusidd(id) ;
            setStatusloader(true);
            dispatch(FetchUnemployedStatus(id)) ;
            dispatch(FetchMastersLog('Unemployment',id)) ;

        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "selactive" : '', "title" : '',"titlear" : '' ,"updatedunemployedstatus" : [] });
    }
    const submitForm = () => {
        //dispatch(EditUnemployedStatus(selunemployedstatusid,inputValues.title,inputValues.selactive)) ;
        setStatusloader(true);
        const myObj = {
            "id": selunemployedstatusid,
            "Title": inputValues.title,
            "TitleAr": inputValues.titlear,
            "IsActive":inputValues.selactive
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('Unemployment:'+inputValues.title,enc_myObjStr,user,'Update',selunemployedstatusid)) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        setValuesloader(true);
        dispatch( ListUnemployedStatus(searchval));
    }
    console.log(updunemployedstatusdata) ;
    return (
        <React.Fragment>
            <Pagesidebar menu="Unemployment" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar} searchValChange = {searchValChange}  changeNav={changeNav}/>
                </navbar>

                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Unemployment List</h2>
                        {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                        <Link to="/add-unemployedstatus" className="btn btn--primaryG btn--capsule">(+) Unemployed
                            Statuses</Link>
                        }
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Title
                                </th>
                                <th>
                                    Title in Arabic
                                </th>
                                <th>
                                    Status
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { unemployedstatus && unemployedstatus.map((item,index) => {
                                let TitleAr = item.Title_Ar ? item.Title_Ar : '';
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.Title}
                                        </td>
                                        <td>
                                            {TitleAr}
                                        </td>
                                        <td>
                                            {item.IsActive == "1" ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>
                                            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                            <span className="iconEdit" onClick={() => toggleModal(item.id)}/>
                                            }
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Income Range</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Title</label>
                                <input value={inputValues.title} name="title" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                            <div className="csForm__item">
                                <label className="csForm__item__label">Title in Arabic</label>
                                <input value={inputValues.titlear} name="titlear" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />

                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&
                            <div className="flexCC">
                                {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                    <span className="messageIn">Waiting for Approval</span>
                                }
                            </div>
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListUnemployedStatuses
