import decode from 'jwt-decode';

export  function handshake(webtoken) {
    /* const user = jwt(webtoken);
    console.log(user) ;
    setToken(webtoken,user)
    console.log(webtoken);
    setToken(webtoken) ;  */
    const user = decode(webtoken);
    console.log(user) ;
    console.log(user.cif) ;
    setToken(webtoken,user)
    return Promise.resolve(webtoken);
}


export  function authservicelogin(id,logindata) {
    /* setToken(token,user_id,name,permission,screens) */
    console.log(logindata) ;
    setToken(logindata) ;
    return Promise.resolve(id);
}



function setToken(logindata) {
    localStorage.setItem('AL_user', logindata.UserName)
    localStorage.setItem('AL_userid', logindata.UserUniqueId)
    localStorage.setItem('AL_userrole', logindata.RoleName)
    localStorage.setItem('AL_roleId', logindata.RoleId)


}

export function getToken() {
    var user = [];
    user['name'] = localStorage.getItem("AL_user")
    user['id'] = localStorage.getItem("AL_userid")
    user['role'] = localStorage.getItem("AL_userrole")
    user['roleid'] = localStorage.getItem("AL_roleId")
    return user
}

export function authservicelogout() {
    localStorage.removeItem('AL_user');
    localStorage.removeItem("AL_userid");
    localStorage.removeItem("AL_userrole");
    localStorage.removeItem("AL_roleId");
}

export function getUrlSegment(segment) {
    var url = window.location.pathname.split('/');
    return url[segment];
}