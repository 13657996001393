 import axios from 'axios';

// import {CC_API, GM_API} from '../constants/index'
//
//  let CC_API = '';
//  let GM_API = '';
//
//
//  if(String(document.location).indexOf('sitagent1') > -1){
//      CC_API   =  'https://sitagent1.bmoman.bankmuscat.com:1443/cc/' ;
//      GM_API=  'https://sitagent1.bmoman.bankmuscat.com:1443/ocr/' ;
//  }else if(String(document.location).indexOf('uatrekycagent1') > -1){
//      CC_API   =  'http://uatrekycagent1.bankmuscat.com:1443/cc/' ;
//      GM_API=  'http://uatrekycagent1.bankmuscat.com:1443/ocr/' ;
//  }
//  else{
//      CC_API   =  'https://bom.labsls.com/' ;
//      GM_API=  'https://digivaldemo.gieom.com/' ;
//  }
//  console.log(document.location)
//  console.log(CC_API, GM_API)


export const userService = {
    ListUserRoles,
    AddUserRoles,
    FetchUserRole,
    EditUserRole,
    ListUser,
    AddUsers,
    ListUserRolesDropDown,
    FetchUserDetails,
    EditUserData,
    ListMstatus,
    AddMstatus,
    FetchMstatusDetails,
    FetchEmpstatusDetails,
    FetchPostalDetails,
    EditMstatus,
    EditEmpstatus,
    ListEmploymentstatus,
    ListPostalCodes,
    ListMincome,
    AddMincome,
    FetchMincome,
    EditMincome,
    ListUnemployedStatus,
    FetchUnemployedStatus,
    EditUnemployedStatus,
    AddUnemployedstatuses,
    ListCustdata,
    LevelCustList,
    ListCustdetails,
    ListSetting,
    FetchSettings,
    EditSettingsData,
    AddSetting,
    ListStatuses,
    FetchStatus,
    EditStatusesData,
    AddStatuses,
    getUploads,
    updateCustomerStatus,
    AddPendingApprovals,
    ListMastersEntry,
    fetchMastersEntry,
    updateStatusMastersLog,
    ListCountrys,
    ListLockRecords,
    getConfigValue,
    FetchCountrys,
    FetchMastersLog,
    ListNewCount,
    ListDashboard,
    stpReports,
    lidReports,
    nonstpReports,
    RecordLockUpdates,
    UpdateRecordLockStatus,
    ListMainSections,
    ListActionTypes,
    ListRolesectionPermission,
    setRolesectionPermission,
    createAccessLog

};
 function getAgentapi(){
     return localStorage.getItem("CC_API")
 }
 function getGMapi(){
     return localStorage.getItem("GM_API")
 }

 function ListUserRoles(search=''){
     return axios.get(getAgentapi() + 'masters/listroles?search='+search) ;
 }

 function ListCountrys(searchval='',searchby=''){
     return axios.get(getAgentapi() + 'masters/listcountry?searchval='+searchval+'&searchby='+searchby) ;
 }
 function ListLockRecords(cif){
     return axios.get(getAgentapi() + 'masters/listlockedrecords?cif='+cif) ;
 }
 function getSystemConfig(id){
     return axios.post(getAgentapi() + 'masters/selectconfig', {
         "sid": id
     })
 }
 function getConfigValue(value){
     return axios.get(getAgentapi() + 'masters/getconfigvalue?value='+value) ;
 }

 function ListMincome(searchval=''){
     return axios.get(getAgentapi() + 'masters/listmonthlyincome?search='+searchval) ;
 }

 function ListUserRolesDropDown(){
     return axios.get(getAgentapi() + 'masters/listrolesdropdown') ;
 }


 function ListUser(searchval='',searchby=''){
     return axios.get(getAgentapi() + 'masters/listusers?searchval='+searchval+'&searchby='+searchby) ;
 }



 function stpReports(search,searchval='',searchby='',inputvalues=''){
     return axios.post(getAgentapi() + 'masters/stpReports', {
         "search": search,
         "searchval": searchval,
         "searchby": searchby,
         "inputvalues": inputvalues

     })
 }

 function lidReports(search,searchval='',searchby='',inputvalues=''){
     return axios.post(getAgentapi() + 'masters/lidReports', {
         "search": search,
         "searchval": searchval,
         "searchby": searchby,
         "inputvalues": inputvalues

     })
 }

 function nonstpReports(search,searchval='',searchby='',inputvalues=''){
     return axios.post(getAgentapi() + 'masters/nonstpReports', {
         "search": search,
         "searchval": searchval,
         "searchby": searchby,
         "inputvalues":inputvalues
     })
 }

 function ListCustdata(status,urole,uid,perpage,page,inputValues){
     return axios.get(getAgentapi() + 'masters/listnew_customer?status='+status+'&urole='+urole+'&uid='+uid+'&perpage='+perpage+'&page='+page+'&filterType='+inputValues) ;
 }
 function LevelCustList(level,perpage,page,searchval,searchby,inputValues){
     return axios.get(getAgentapi() + 'masters/customerlevel?level='+level+'&perpage='+perpage+'&page='+page+'&searchval='+searchval+'&searchby='+searchby+'&inputvalues='+inputValues) ;
 }

 function ListDashboard(filterType){
     return axios.get(getAgentapi() + 'masters/dashboardnew?filterType='+filterType) ;
 }
function ListNewCount(uid){
     return axios.get(getAgentapi() + 'masters/listnew_count?uid='+uid) ;
 }


 function ListCustdetails(custid){
     return axios.get(getAgentapi() + 'masters/fetchcustomers?cid='+custid)
 }

 function ListMstatus(searchval=''){
     return axios.get(getAgentapi() + 'masters/listmaritalstatus?search='+searchval) ;
 }

function ListEmploymentstatus(searchval=''){
     return axios.get(getAgentapi() + 'masters/listemploymentstatus?search='+searchval) ;
 }
 function ListPostalCodes(searchval=''){
     return axios.get(getAgentapi() + 'masters/postalcodeslist?search='+searchval) ;
 }

 function ListSetting(search=''){
     return axios.get(getAgentapi() + 'masters/listconfig?search='+search) ;
 }

 function ListUnemployedStatus(searchval=''){
     return axios.get(getAgentapi() + 'masters/listunemployedstatuses?search='+searchval) ;
 }

 function ListStatuses(searchval=''){
     return axios.get(getAgentapi() + 'masters/liststatuses?search='+searchval) ;
 }

 function AddUserRoles(role,isActive,isDeleted){
     return axios.post(getAgentapi() + 'masters/addrole', {
         "rolename": role,
         "active": isActive,
         "deleted": isDeleted
     })
 }

 function AddMincome(incomerange,isActive){
     return axios.post(getAgentapi() + 'masters/addmonthlyincome', {
         "range": incomerange,
         "active": isActive
     })
 }

 function AddMstatus(mstatus,isActive){
     return axios.post(getAgentapi() + 'masters/addmaritalstatus', {
         "status": mstatus,
         "active": isActive
     })
 }

 function AddSetting(key,keyvalue){
     return axios.post(getAgentapi() + 'masters/addconfig', {
         "key": key,
         "value": keyvalue
     })
 }

 function AddStatuses(status){
     return axios.post(getAgentapi() + 'masters/addstatuses', {
         "status": status
     })
 }

 function AddUsers(username,useremail,userphone,userempid,userrole,selactive,seldeleted,userpassword){
     return axios.post(getAgentapi() + 'masters/adduser', {
         "userEmail": useremail,
         "userName": username,
         "password": userpassword,
         "empId": userempid,
         "active": selactive,
         "phone": userphone,
         "role": userrole,
         "deleted": seldeleted
     })
 }

 function AddUnemployedstatuses(title,isActive){
     return axios.post(getAgentapi() + 'masters/addunemployedstatuses', {
         "title": title,
         "active": isActive
     })
 }


 function FetchUserRole(role){
     return axios.post(getAgentapi() + 'masters/getrole', {
         "roleid": role
     })
 }

 function FetchSettings(id){
     return axios.post(getAgentapi() + 'masters/selectconfig', {
         "sid": id
     })
 }

 function FetchCountrys(id){
     return axios.post(getAgentapi() + 'masters/selectcountry', {
         "cid": id
     })
 }
 function FetchMastersLog(section,masterid){
     return axios.get(getAgentapi() + 'masters/fetchMatersLog?section='+section+'&masterid='+masterid);
 }


 function FetchStatus(id){
     return axios.post(getAgentapi() + 'masters/fetchstatus', {
         "sid": id
     })
 }

 function FetchMincome(id){
     return axios.post(getAgentapi() + 'masters/fetchmonthlyincome', {
         "mid": id
     })
 }

 function FetchUserDetails(uniqueId){
     return axios.post(getAgentapi() + 'masters/fetchuser', {
         "userid": uniqueId
     })
 }

 function FetchMstatusDetails(id){
     return axios.post(getAgentapi() + 'masters/fetchmaritalstatus', {
         "mid": id
     })
 }
 function FetchEmpstatusDetails(id){
     return axios.post(getAgentapi() + 'masters/fetchemployementstatus', {
         "mid": id
     })
 }
 function FetchPostalDetails(id){
     return axios.get(getAgentapi() + 'masters/fetchpostalcodesdetails?pid='+id);
 }

 function FetchUnemployedStatus(id){
     return axios.post(getAgentapi() + 'masters/fetchunemployedstatuses', {
         "mid": id
     })
 }


 function EditUserRole(roleid,role,isActive,isDeleted){
     return axios.post(getAgentapi() + 'masters/updaterole', {
         "roleid": roleid,
         "rolename": role,
         "active": isActive,
         "deleted": isDeleted
     })
 }

 function EditMincome(mincomeid,incomerange,isActive){
     return axios.post(getAgentapi() + 'masters/updatemonthlyincome', {
         "range": incomerange,
         "active": isActive,
         "mid": mincomeid

     })
 }

 function EditMstatus(mstatusid,mstatus,isActive){
     return axios.post(getAgentapi() + 'masters/updatemaritalstatus', {
         "mid": mstatusid,
         "status": mstatus,
         "active": isActive
     })
 }
 function EditEmpstatus(empstatusid,empstatus,isActive){
     return axios.post(getAgentapi() + 'masters/updateemploymentstatus', {
         "mid": empstatusid,
         "status": empstatus,
         "active": isActive
     })
 }

 function EditUserData(seluserid,username,useremail,userphone,userempid,userrole,selactive){
     return axios.post(getAgentapi() + 'masters/updateuser', {
         "userId": seluserid,
         "userEmail": useremail,
         "userName": username,
         "empId": userempid,
         "active": selactive,
         "phone": userphone,
         "role": userrole
     })
 }

 function EditUnemployedStatus(empstatusid,title,isActive){
     return axios.post(getAgentapi() + 'masters/updateunemployedstatuses', {
         "mid": empstatusid,
         "title": title,
         "active": isActive
     })
 }

 function EditSettingsData(sid,key,value){
     return axios.post(getAgentapi() + 'masters/updateconfig', {
         "sid": sid,
         "key": key,
         "value": value
     })
 }

 function EditStatusesData(sid,status){
     return axios.post(getAgentapi() + 'masters/updatestatus', {
         "sid": sid,
         "status": status
     })
 }




 function getUploads(jid){
     return axios.get(getGMapi() + 'getuploads/?referenceid='+jid);

 }

 function updateCustomerStatus(cid,formObj){
     return axios.post(getAgentapi() + 'masters/updateCustomerStatus', {
         "cid": cid,
         "formobj": formObj
     })
 }
 function RecordLockUpdates(recordid,uid,cid){
     return axios.post(getAgentapi() + 'masters/AddLockDetails', {
         "recordid": recordid,
         "uid": uid,
         "cid": cid
     })
 }
 function UpdateRecordLockStatus(recordid,uid,cid,stat){
     return axios.post(getAgentapi() + 'masters/UpdateLockDetails',{
         "recordid": recordid,
         "uid": uid,
         "cid": cid,
         "status": stat,
     })
 }


 function AddPendingApprovals(service,actiontext,user,action,master_id=''){
     return axios.post(getAgentapi() + 'masters/addMasterEntry', {
         "section": service,
         "request": actiontext,
         "enteredby": user,
         "action": action,
         "masterid": master_id
     })

 }




 function updateStatusMastersLog(id,status,section,action,user){
     return axios.post(getAgentapi() + 'masters/updateStatusMastersLog', {
         "refid": id,
         "status": status,
         "section":section,
         "action": action,
         "updatedby": user
     })

 }


 function ListMastersEntry(status,filterby='',perpage,page,type){
     return axios.get(getAgentapi() + 'masters/fetchMastersList?status='+status+'&perpage='+perpage+'&page='+page+'&filterby='+filterby+'&type='+type)

 }



 function fetchMastersEntry(id){
     return axios.get(getAgentapi() + 'masters/fetchMastersEntryById?refid='+id)

 }
 function ListMainSections(){
     return axios.get(getAgentapi() + 'masters/SectionList') ;
 }
 function ListActionTypes(){
     return axios.get(getAgentapi() + 'masters/ActionTypesDetails') ;
 }
 function ListRolesectionPermission(roleId){
     return axios.get(getAgentapi() + 'masters/ListRolesectionPermission?roleid='+roleId) ;
 }
 function setRolesectionPermission(formObj,id){
     return axios.post(getAgentapi() + 'masters/setRolesectionPermission', {
         "data": formObj,
         "roleid":id
     })
 }
 function createAccessLog(cif,sectionmsg,sessionid){
     return axios.post(getAgentapi() + '/access/createaccesslog', {
         "cif": cif ? cif : '0',
         "service": "API",
         "section": "Agent Login",
         "position": "Request",
         "msg": sectionmsg,
         "journeyid": sessionid
     })
 }
