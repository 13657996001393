import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListPostalCodes,
    FetchPostalDetails,
    AddPendingApprovals,
    FetchMastersLog
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const PostalCodesList = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [modalIn, setModalIn] = useState(false);
    const [postalcodes, setPostalCodelist] = useState([]);
    const [selpostalid, setSelpostalid] = useState('');
    const [selectedpostalcodes, setSelectedpostalcodes] = useState([]);

    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);

    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchval, setSearchVal] = useState('' );
    const [valuesloader, setValuesloader] = useState(false);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {description: '', selactive:'' , updatedpostalstatus: {"status": "pending"}}
    );

    const listpostalcodedata = useSelector(state => state.UserReducer.listpostalcodedata) ;
    const fetchpostalcodedata = useSelector(state => state.UserReducer.fetchpostalcodedata) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;

    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
console.log(listpostalcodedata);



    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(ListPostalCodes(searchval)) ;
    },[dispatch]);

    useEffect(() => {
        setValuesloader(false);
        setPostalCodelist(listpostalcodedata)
    },[listpostalcodedata]);

    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);

    useEffect(() => {

        //console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);

            }
        }
    },[newdatarow]);


    useEffect(() => {
        setSelectedpostalcodes(fetchpostalcodedata)
        if(fetchpostalcodedata !== undefined){
            let description_Ar = fetchpostalcodedata[0].description_Ar ? fetchpostalcodedata[0].description_Ar : '';
            setInputValues({ "code" : fetchpostalcodedata[0].code,"description" : fetchpostalcodedata[0].description, "descriptionar" : description_Ar, "selactive" : fetchpostalcodedata[0].IsActive, "updatedpostalstatus" : {"status": "pending"} });
        }

    },[fetchpostalcodedata]);


    const toggleModal = (id) => {
        setStatus(false);
        setButtonStatus(false);
        if(id == 0){
            dispatch(ListPostalCodes(searchval)) ;
        }else{
            setSelpostalid(id) ;
            setStatusloader(true);
            dispatch(FetchPostalDetails(id)) ;
            dispatch(FetchMastersLog('PostalCodes',id)) ;

        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }


    const submitForm = () => {
        //dispatch(EditMstatusData(selmstatusid,inputValues.mstatus,inputValues.selactive)) ;
        setStatusloader(true);
        const myObj = {
            "id": selpostalid,
            "code": inputValues.code,
            "description": inputValues.description,
            "descriptionAr": inputValues.descriptionar,
            "IsActive":inputValues.selactive
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('PostalCodes',enc_myObjStr,user,'Update',selpostalid)) ;

    }


    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        setValuesloader(true);
        dispatch( ListPostalCodes(searchval));
    }
console.log(postalcodes);
    return (
        <React.Fragment>
            <Pagesidebar menu="Postal Codes" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="Yes" searchValChange = {searchValChange}   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Postal Codes</h2>
                        <Link to="/add-postalcodes" className="btn btn--primaryG btn--capsule">(+) Postal Codes</Link>
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Code
                                </th>
                                <th>
                                    Description
                                </th>
                                <th>
                                    Description in Arabic
                                </th>
                                <th>
                                    Active/Inactive
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { postalcodes && postalcodes.map((item,index) => {
                                let DescriptionAr = item.description_Ar ? item.description_Ar : '';
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.code}
                                        </td>
                                        <td>
                                            {item.description}
                                        </td>
                                        <td>
                                            {DescriptionAr}
                                        </td>
                                        <td>
                                            {item.IsActive === "1" ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>
                                            <span className="iconEdit" onClick={() => toggleModal(item.id)} />
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Postal Codes</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Code</label>
                                <input value={inputValues.code} name="code" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                            <div className="csForm__item">
                                <label className="csForm__item__label">Description</label>
                                <input value={inputValues.description} name="description" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                            <div className="csForm__item">
                                <label className="csForm__item__label">Description in Arabic</label>
                                <input value={inputValues.descriptionar} name="descriptionar" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />


                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&

                            <div className="flexCC">
                                {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                    <span className="messageIn">Waiting for Approval</span>
                                }
                            </div>
                            }

                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default PostalCodesList
