import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddUserRoles, AddPendingApprovals} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";


const AddRole = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    {/* const newroledata = useSelector(state => state.UserReducer.newrolesdata) ;  */}
    const [groups, setGroups] = useState([{"label": "REKYC_ADMIN_TEST", "value": "REKYC_ADMIN_TEST"},{"label": "REKYC_SALES_TEST", "value": "REKYC_SALES_TEST"},{"label": "REKYC_MAKER_TEST", "value": "REKYC_MAKER_TEST"},{"label": "REKYC_CHECKER_TEST", "value": "REKYC_CHECKER_TEST"}]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );


    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;


    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '', seldeleted: '',rolename: '', newrole: []}
    );

    let history = useHistory();
    const dispatch = useDispatch();

     useEffect(() => {
         if(submit === true){
             if(newdatarow.resp_status !== undefined){
                 setStatus(newdatarow.resp_status) ;
             }
         }
    },[newdatarow]);


    const submitForm = () => {
        const myObj = {
            "RoleName": inputValues.rolename,
            "IsActive": inputValues.selactive,
            "IsDeleted":inputValues.seldeleted
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        dispatch(AddPendingApprovals('Roles:'+inputValues.rolename,enc_myObjStr,user,'Insert')) ;
        setSubmit(true);

    }

    const resetForm = () => {
        setInputValues({ "selactive" : '', "seldeleted" : '', "rolename": '', "newrole" : [] });
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }


    return (
        <React.Fragment>

            <Pagesidebar menu="Roles" mainmenu="Administration" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Administration"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>

            <div className="roundBox">
                <div className="roundBox__head">
                    <h2>Add Role</h2>
                </div>
                <div className="p30">
                    { status === "success"  ? (
                            <span className="messageIn">Successfully created the new role</span>
                        ) : ''
                    }

                    <div className="fullFlexBox">


                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Rolename</label>

                                <input value={inputValues.rolename} name="rolename" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>
                        {/*<div className="halfBlock">*/}
                        {/*    <Selectbox  label="Group Name" name="groupname"  values={groups}   onChange={handleChange}  selvalue={inputValues.groupname} />*/}

                        {/*</div>*/}
                        <div className="halfBlock">
                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />

                        </div>
                        <div className="halfBlock">
                            <Selectbox  label="Is Deleted"  name="seldeleted" values={yesnovalue}  onChange={handleChange} selvalue={inputValues.seldeleted}  />
                        </div>

                    </div>
                </div>
                <div className="p30">
                    <div className="flexCC buttonWrap">
                            <span onClick={resetForm} className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                            <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                    </div>
                </div>

            </div>
        </div>
        </React.Fragment>
    )
}

export default AddRole
