import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListMainSections, ListActionTypes, setRolesectionPermission, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import Loader from "../components/Loader";
import Pagesidebar from "../components/Pagsidebar";

const PermissionSettings = () => {


    const [showsidebar, setShowsidebar] = useState(true);
    const [newpermissionarray, setNewPermissionArray] = useState([]);
    const [statusloader, setStatusloader] = useState(false);

    let history = useHistory();
    const dispatch = useDispatch();
    const params = (new URL(document.location)).searchParams;
    const id = params.get("id") ? params.get("id") :'';

    const listsectiondata = useSelector(state => state.UserReducer.listsectiondata) ;
    const listactiontype = useSelector(state => state.UserReducer.listactiontype) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;
    const setrolepermission = useSelector(state => state.UserReducer.setrolepermission) ;

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const setRoleModule = (secid,actnid) =>{
        console.log(id);
        console.log(secid);
        console.log(actnid);
        let permissionArray = newpermissionarray ? [...newpermissionarray]:[];
        let index = permissionArray.findIndex( x => x.sectionid === secid);
        console.log(index);
        if (index > -1) {
            permissionArray.splice(index, 1);
            permissionArray.push({"roleid": id, "sectionid": secid, "actionid": actnid});

        }else {
            // eslint-disable-next-line no-unused-expressions
            permissionArray.push({"roleid": id, "sectionid": secid, "actionid": actnid});
        }
        setNewPermissionArray(permissionArray)

        console.log(permissionArray);
    }

    const clearPermission = (secid) => {
        let permissionArray = newpermissionarray ? [...newpermissionarray]:[];
        let index = permissionArray.findIndex( x => x.sectionid === secid);

        if (index > -1) {
            permissionArray.splice(index, 1);
        }
        setNewPermissionArray(permissionArray)
    }
    const submitForm = () =>{
        console.log(newpermissionarray);
        setStatusloader(true);
        const myObjStr = JSON.stringify(newpermissionarray);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        dispatch(setRolesectionPermission(enc_myObjStr,id));
    }

    const listRoles =()=>{
        window.location.href = ('/list-roles');
    }

    useEffect(() => {
        setStatusloader(true);
        dispatch(ListMainSections()) ;
        dispatch(ListActionTypes()) ;
        dispatch(ListRolesectionPermission(id)) ;
    },[dispatch]);

    useEffect(() => {
        setStatusloader(false)
    },[setrolepermission]);

    useEffect(() => {
        const myArray = rolesectiondata ? rolesectiondata : [] ;
        const arr = [];
        myArray.forEach((element, index) => {
            arr.push({"roleid": element.roleId, "sectionid": element.sectionId, "actionid": element.actionId});
        });
        setNewPermissionArray(arr);
        setStatusloader(false);
    },[rolesectiondata]);
    console.log(listsectiondata);
    console.log(listactiontype);
    console.log(newpermissionarray);
    console.log(rolesectiondata);
    console.log(statusloader);
    return (
        <React.Fragment>
            <Pagesidebar menu="Roles" mainmenu="Administration" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                {/*<navbar className="pageNav">*/}
                {/*    /!*<Navbar pagettl="Administration"  showsidebar={showsidebar} filter="No" search="No"  changeNav={changeNav} />*!/*/}
                {/*</navbar>*/}


                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">

                        <Link  onClick={() => listRoles()} className="iconAnchor"><span
                            className="iconIn iconIn--backArrow"></span>Back</Link>                        <span className="btn btn--primaryG btn--sm btn--capsule"
                              onClick={() => submitForm()}>UPDATE</span>
                    </div>
                    <div className='tableWrapper scrollable heighted'>
                            <table className='csTable permissionTabel'>
                                <thead>
                                    <tr>
                                        <th>
                                            SECTIONS
                                        </th>
                                        {listactiontype && listactiontype.map((item1, index1) => {
                                            return(
                                            <th>{item1.actionType}</th>
                                        )})}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {listsectiondata && listsectiondata.map((item, index) => {
                                        return (
                                    <tr>
                                        <td>
                                            {item.sectionName}
                                        </td>

                                        {listactiontype && listactiontype.map((item2, index2) => {
                                            return (
                                        <td>
                                            <label className="csCheckbox" key={index2}>
                                                {newpermissionarray.findIndex( x => x.sectionid == item.id && x.actionid == item2.id)}
                                                <input type="radio" name = {item.id} value={item.id} className="csCheckbox__input" onClick={() => setRoleModule(item.id,item2.id)}
                                                       checked={newpermissionarray.findIndex( x => x.sectionid == item.id && x.actionid == item2.id) > -1 ? true : false} disabled={(item.id == 3 && (item2.id == 1 || item2.id == 2) || item.id == 7 && (item2.id == 1) || item.id == 9 && (item2.id == 1))? true : false}/>
                                                <span className="csCheckbox__placeholder" />
                                            </label>
                                        </td>
                                                )})}
                                        <td><span className="btn btn--primaryOuter btn--sm btn--capsule" onClick={() => clearPermission(item.id)}>Clear</span></td>

                                    </tr>
                                            )})}
                                </tbody>
                            </table>
                        </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default PermissionSettings
