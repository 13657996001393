let initialState = {
    listrolesdata: [],
    newrolesdata: [],
    fetchroledata: [],
    updroledata: [],
    listusersdata: [],
    fetchuserdata :[],
    newusersdata: [],
    listrolesdropdowndata:[],
    upduserdata :[],
    liststatusdata:[],
    newmincomedata : [],
    fetchmincomedata:[],
    updmincomedata:[],
    unemployedstatusdata:[],
    fetchunemployedstatusdata:[],
    addunemployedstatus:[],
    listsettingsdata:[],
    fetchsettingsdata:[],
    updsettingsdata:[],
    newsettingsdata:[],
    liststatusesdata:[],
    fetchstatusdata:[],
    updstatusesdata:[],
    newstatusesdata:[],
    uploadRespdata:[],
    updatestatusesdata: [],
    newdatarow: [],
    listmasterdata: [],
    fetchmasterdata: [],
    updatedmasterdata: [],
    listcountrydata: [],
    dashboarddata: [],
    stpReportdata: [],
    nonstpReportdata: [],
    newempstatusdata:[],
    listempstatusdata:[]

}


const UserReducer =  (state = initialState, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_ROLES":
            return {
                ...state,
                listrolesdata: action.listrolesdata,
            };
            break;

        case "LISTDROPDOWN_ROLES":
            return {
                ...state,
                listrolesdropdowndata: action.listrolesdropdowndata,
            };
            break;

        case "Masters_Approval":
            return {
                ...state,
                newdatarow: action.newdatarow,
            };
            break;
        case "LIST_MASTERSLOG":
            return {
                ...state,
                listmasterdata: action.listmasterdata,
            };
            break;

        case "LIST_MORE_MASTERSLOG":
            _newState = {...state};
            _newState['listmasterdata'] = _newState.listmasterdata.concat(action.listmoremasterdata);

            return _newState;

            break;

        case "FECTCH_MASTERSLOG":
            return {
                ...state,
                fetchmasterdata: action.selectedmasterdata,
            };
            break;
        case "UPDATE_MASTERSLOG":
            return {
                ...state,
                updatedmasterdata: action.updatedmasterdata,
            };
            break;



        case "ADD_ROLE":
            return {
                ...state,
                newrolesdata: action.newrolesdata,
            };
            break;

        case "ADD_USER":
            return {
                ...state,
                newusersdata: action.newusersdata,
            };
            break;

        case "FETCH_ROLE":
            return {
                ...state,
                fetchroledata: action.fetchroledata,
            };
            break;

        case "UPDATE_ROLE":
            return {
                ...state,
                updroledata: action.updrolesdata,
            };
            break;

        case "UPDATE_USER":
            return {
                ...state,
                upduserdata: action.upduserdata,
            };
            break;

        case "LIST_USER":
            return {
                ...state,
                listusersdata: action.listusersdata,
            };
            break;

        case "FETCH_USER":
            return {
                ...state,
                fetchuserdata: action.fetchuserdata,
            };

            break;
        case "LIST_MSTATUS":
            return {
                ...state,
                listmstatusdata: action.listmstatusdata,
            };
            break;

        case "ADD_MSTATUS":
            return {
                ...state,
                newmstatusdata: action.newmstatusdata,
            };
            break;

        case "COUNTOFLIST":
            return {
                ...state,
                listcounts: action.listcounts,
            };
            break;

        case "FETCH_MSTATUS":
            return {
                ...state,
                fetchmstatusdata: action.fetchmstatusdata,
            };
            break;

        case "UPDATE_MSTATUS":
            return {
                ...state,
                updmstatusdata: action.updmstatusdata,
            };
            break;


        case "LIST_POSTALCODES":
            return {
                ...state,
                listpostalcodedata: action.listpostalcodedata,
            };
            break;

        case "FETCH_POSTALCODES":
            return {
                ...state,
                fetchpostalcodedata: action.fetchpostalcodedata,
            };
            break;
        case "LIST_EMPLOYMENTSTATUS":
            return {
                ...state,
                listempstatusdata: action.listempstatusdata,
            };
            break;

        case "ADD_EMPLOYMENTSTATUS":
            return {
                ...state,
                newempstatusdata: action.newempstatusdata,
            };
            break;

        case "FETCH_EMPSTATUS":
            return {
                ...state,
                fetchempstatusdata: action.fetchempstatusdata,
            };
            break;

        case "UPDATE_EMPSTATUS":
            return {
                ...state,
                updempstatusdata: action.updempstatusdata,
            };
            break;

        case "LIST_MINCOME":
            return {
                ...state,
                listmincomedata: action.listmincomedata,
            };
            break;

        case "ADD_MINCOME":
            return {
                ...state,
                newmincomedata: action.newmincomedata,
            };
            break;

        case "FETCH_MINCOME":
            return {
                ...state,
                fetchmincomedata: action.fetchmincomedata,
            };
            break;

        case "UPDATE_MINCOME":
            return {
                ...state,
                updmincomedata: action.updmincomedata,
            };
            break;

        case "LIST_UNEMPLOYEDSTATUS":
            return {
                ...state,
                unemployedstatusdata: action.unemployedstatusdata,
            };
            break;

        case "FETCH_UNEMPLOYEDSTATUS":
            return {
                ...state,
                fetchunemployedstatusdata: action.fetchunemployedstatusdata,
            };
            break;

        case "UPDATE_UNEMPLOYEDSTATUS":
            return {
                ...state,
                updunemployedstatusdata: action.updunemployedstatusdata,
            };
            break;

        case "ADD_UNEMPLOYEDSTATUS":
            return {
                ...state,
                addunemployedstatus: action.addunemployedstatus,
            };
            break;

        case "DASHBOARD":
            return {
                ...state,
                dashboarddata: action.dashboarddata,
            };
            break;

        case "COUNTOFLIST":
            return {
                ...state,
                listcounts: action.listcounts,
            };
            break;

        case "LIST_CUSTOMERS":
            return {
                ...state,
                listcustdata: action.listcustdata,
                listcustcount: action.listcustcount
            };
            break;

        case "LIST_LEVEL_CUSTOMERS":
            return {
                ...state,
                listlevelcust: action.listlevelcust,
                listlevelcustcount: action.listlevelcustcount
            };
            break;

        case "LIST_MORE_CUSTOMERS":
            _newState = {...state};
            _newState['listcustdata'] = _newState.listcustdata.concat(action.listmorecustdata);

            return _newState;

            break;

        case "LIST_LEVEL_CUSTOMERS_MORE":
            _newState = {...state};
            _newState['listlevelcust'] = _newState.listlevelcust.concat(action.listlevelcustmore);

            return _newState;

            break;


        case "STP_REPORTS":
            return {
                ...state,
                stpReportdata: action.stpReportdata,
            };
            break;

        case "LID_REPORTS":
            return {
                ...state,
                lidReportdata: action.lidReportdata,
            };
            break;
        case "NONSTP_REPORTS":
            return {
                ...state,
                nonstpReportdata: action.nonstpReportdata,
            };
            break;

        case "LIST_CUSTOMERDETAILS":
            return {
                ...state,
                listcustdetails: action.listcustdetails,
            };
            break;

        case "LIST_SETTINGS":
            return {
                ...state,
                listsettingsdata: action.listsettingsdata,
            };
            break;

        case "FETCH_SETTINGS":
            return {
                ...state,
                fetchsettingsdata: action.fetchsettingsdata,
            };
            break;

        case "UPDATE_SETTINGS":
            return {
                ...state,
                updsettingsdata: action.updsettingsdata,
            };
            break;

        case "ADD_SETTINGS":
            return {
                ...state,
                newsettingsdata: action.newsettingsdata,
            };
            break;

        case "LIST_STATUS":
            return {
                ...state,
                liststatusesdata: action.liststatusesdata,
            };
            break;

        case "FETCH_STATUS":
            return {
                ...state,
                fetchstatusdata: action.fetchstatusdata,
            };
            break;

        case "UPDATE_STATUS":
            return {
                ...state,
                updstatusesdata: action.updstatusesdata,
            };
            break;

        case "ADD_STATUS":
            return {
                ...state,
                newstatusesdata: action.newstatusesdata,
            };
            break;
        case "CUSTOMER_STATUS_UPDATE":
            return {
                ...state,
                updatestatusesdata: action.updatestatusesdata,
            };
            break;

        case "GET_UPLOAD":
            return {
                ...state,
                uploadRespdata: action.uploadRespdata,
            };
            break;

        case "LIST_COUNTRY":
            return {
                ...state,
                listcountrydata: action.listcountrydata,
            };
            break;

        case "LIST_LOCKRECORD":
            return {
                ...state,
                listlockdata: action.listlockdata,
            };
            break;

    case "RECORD_LOCKSTATUS_UPDATES":
            return {
                ...state,
                recLockupdates: action.recLockupdates,
                recLockupdatesStatus: action.recLockupdatesStatus,
            };
            break;
         case "CONFIG_SETTINGS":
            return {
                ...state,
                systemconfigdata: action.systemconfigdata,
            };
            break;

        case "FETCH_COUNTRY":
            return {
                ...state,
                fetchcountrydata: action.fetchcountrydata,
            };
            break;

        case "FETCH_MASTERSLOG":
            return {
                ...state,
                fetchmasterslogdata: action.fetchmasterslogdata,
            };
            break;

   case "SECTION_LIST":
            return {
                ...state,
                listsectiondata: action.listsectiondata,
            };
            break;
   case "LIST_ACTIONTYPE":
            return {
                ...state,
                listactiontype: action.listactiontype,
            };
            break;

   case "ROLE_SECTION_PERMISSION":
            return {
                ...state,
                rolesectiondata: action.rolesectiondata,
            };
            break;
 case "SAVE_ROLE_PERMISSION":
            return {
                ...state,
                setrolepermission: action.setrolepermission,
            };
            break;


        default:
            return state;
    }
};


export default UserReducer;
