import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {LevelCustListMore, LevelCustList} from "../actions/User";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken, getUrlSegment} from "../utilities/AuthService";
import Loader from "../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Selectbox from "../components/Selectbox";
import moment from "moment";
import DatePicker from "react-datepicker";

const IncompleteStage = () => {
    const [hasError, setErrors] = useState(false);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "Name", "value": "name"},{"label": "CIF", "value": "cif"},{"label": "Mobile", "value": "mobile"}]);
    const [sortbyfilter, setSortbyfilter] = useState([{"label": "All", "value": "0"},{"label": "Today", "value": "1"},{"label": "Last 7 days", "value": "7"},{"label": "Last 30 Days", "value": "30"},{"label": "Last 3 months", "value": "3"},{"label": "Last 6 months", "value": "6"},{"label": "Custom", "value": "custom"}]);
    const [modalIn, setModalIn] = useState(false);
    const [custlevel, setLevelcust] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [userrole, setUserrole] = useState(getToken().role);
    const [userid, setUserid] = useState(getToken().id);
    const [perpage, setPerpage] = useState('10');
    const [statusloader, setStatusloader] = useState(false);
    const [toggleupdate, setToggleUpdate] = useState(false);
    const [level, setInputLevel] = useState(0);
    const [searchval, setInputSearch] = useState('');
    const [searchby, setInputSearchby] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [customDate, setCustomDate] = useState(false);
    const [valuesloader, setValuesloader] = useState(false);

    const listlevelcust = useSelector(state => state.UserReducer.listlevelcust) ;
    const listlevelcustcount = useSelector(state => state.UserReducer.listlevelcustcount) ;

    const [inputValues, _setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {dateFilter:0}
    );
    console.log(listlevelcust);
    console.log(listlevelcustcount);
    let history = useHistory();
    const dispatch = useDispatch();
    let page = 1;
    const myStateRef = React.useRef(inputValues);
    const setInputValues = data => {
        let values = inputValues;
        for (var key in data) {
            values[key] = data[key];
        }
        myStateRef.current = values;
        _setInputValues(values);
    };

    useEffect(() => {
        const container = document.querySelector('.scrollable');
        container.scrollTop = 0;
        container.removeEventListener("scroll", listenToScroll);
        page = 1;
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;
    },[level]);

    useEffect(() => {
        setStatusloader(false);
        setToggleUpdate(false);
        setLevelcust(listlevelcust)
    },[listlevelcust]);

    const listenToScroll = () => {
        const container = document.querySelector('.scrollable')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        console.log(container);
        console.log(contentHeight);
        console.log(scrollTop);

        if (contentHeight <= scrollTop) {

            page = parseInt(page) + 1;
            //setPage(currentPage);
            setStatusloader(true);
            const myinputValues = JSON.stringify(inputValues);
            const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(LevelCustListMore(level,perpage,page,searchval,searchby,enc_myinputValues),page) ;
        }
    }


    useEffect(() => {
            const container = document.querySelector('.scrollable');
            container.addEventListener("scroll", listenToScroll);
            return () => container.removeEventListener("scroll", listenToScroll);

    }, [level]);



    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

     const toggleMenu =(level)=>{
         setStatusloader(true);
         setToggleUpdate(true);
            setInputLevel(level) ;
            page=1;
         const myinputValues = JSON.stringify(inputValues);
         const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;
        }
    const handleChange = (value,name) =>{
        setInputSearchby(value);
    }
    const searchValChange =(searchval)=>{
        setInputSearch(searchval);
        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;
    }
console.log(searchby);
console.log(searchval);

    const handledateFilter = (value) => {
        const val = ['1', '7', '30'];
        if(value !== "0" && value != "custom") {
            setInputValues({ "startDate": '',"endDate": ''}) ;
            setCustomDate(false);
            if (val.indexOf(value) > -1) {

                let filterCat = 'day';
                setInputValues({"filterCat": 'day'});
            } else {
                let filterCat = 'month'
                setInputValues({"filterCat": 'month'});
            }
            setInputValues({"dateFilter": value});
            setStatusloader(true);
            setValuesloader(true);

            const strJSON = {
                sub_date: ''
            }
            const myObjStr = JSON.stringify(strJSON);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');

            const myinputValues = JSON.stringify(inputValues);
            const enc_myinputValues = new Buffer(myinputValues).toString('base64');
            dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;

        } else {

            setCustomDate(false);
            if(value === "0"){
                setInputValues({"filterCat": '',"dateFilter":'',"startDate": '',"endDate": ''});
                setInputValues({"dateFilter": value});
                setStatusloader(true);
                setValuesloader(true);

                const strJSON = {
                    sub_date: ''
                }
                const myObjStr = JSON.stringify(strJSON);
                const enc_myObjStr = new Buffer(myObjStr).toString('base64');
                setCustomDate(false);

                const myinputValues = JSON.stringify(inputValues);
                const enc_myinputValues = new Buffer(myinputValues).toString('base64');
                dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;

            }else{
                setCustomDate(true);
                setInputValues({"filterCat": 'custom',"dateFilter":'custom'});
            }
        }
    }

    const handleCustomStartDate = (date) =>{
        let startDate = moment(date).format('YYYY-MM-DD')
        setStartDate(date)
        setInputValues({ "startDate": startDate ,"dateFilter" : 'custom'}) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        setValuesloader(true);

        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;
    }
    const handleCustomEndDate = (date) =>{
        let endDate = moment(date).format('YYYY-MM-DD')
        setEndDate(date)
        setInputValues({ "endDate": endDate,"dateFilter" : 'custom' }) ;
        setInputValues({filterCat: 'custom'});
        setStatusloader(true);
        setValuesloader(true);

        const strJSON = {
            sub_date: ''
        }
        const myObjStr = JSON.stringify(strJSON);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        const myinputValues = JSON.stringify(inputValues);
        const enc_myinputValues = new Buffer(myinputValues).toString('base64');
        dispatch(LevelCustList(level,perpage,page,searchval,searchby,enc_myinputValues)) ;
    }
    return (
        <React.Fragment>
            <Pagesidebar menu="Incomplete" mainmenu="Incomplete" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Incomplete"  showsidebar={showsidebar} filter="No" search="Yes"  searchParam = {searchbyvalue}  searchChange = {handleChange}  filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}  searchValChange = {searchValChange} />
                </navbar>


                <div className="roundBox">
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head flexCS">
                        <h2>Journey Drop Off ({listlevelcustcount ? listlevelcustcount.count : 0})</h2>
                        <div className="sortBox__check flexCB csLabeled">
                            <label  className="csForm__item__label">Sort By Date :</label>
                            <Selectbox label="Date filter" name="dateFilter"  labelStatus = "No" values={sortbyfilter} onChange={handledateFilter} selvalue={inputValues.dateFilter}/>
                        </div>
                        {customDate &&
                        <div className="sortBox__check">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => handleCustomStartDate(date)}
                                placeholderText="From Date"
                                dateFormat="MM/dd/yyyy"
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => handleCustomEndDate(date)}
                                placeholderText="End Date"
                                dateFormat="MM/dd/yyyy"
                            />
                        </div>

                        }
                    </div>

                    <div className="tabWrapper">
                        <div className="tabHead">
                            <span className={`tabHead__items ${level === 0 ? "active" : ""} `} onClick={() => toggleMenu(0)}>Landing Page ({listlevelcustcount ? listlevelcustcount.onboarding_count : 0})</span>
                            <span className={`tabHead__items ${level === 1 ? "active" : ""} `} onClick={() => toggleMenu(1)}>Liveness ({listlevelcustcount ? listlevelcustcount.liveness_count : 0})</span>
                            <span className={`tabHead__items ${level === 2 ? "active" : ""} `} onClick={() => toggleMenu(2)}>ID Scan ({listlevelcustcount ? listlevelcustcount.idscan_count : 0})</span>
                            <span className={`tabHead__items ${level === 3 ? "active" : ""} `} onClick={() => toggleMenu(3)}>ID Verification ({listlevelcustcount ? listlevelcustcount.idvrftcn_count : 0})</span>
                            <span className= {`tabHead__items ${level === 4 ? "active" : ""} `} onClick={() => toggleMenu(4)}>Form ({listlevelcustcount ? listlevelcustcount.form_count : 0}) </span>
                        </div>
                        <div className="tabPanelWrapper">
                            <div className="tabPanel active">
                                <div className="tableWrapper scrollable heighted">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    CIF
                                </th>
                                <th>
                                    Mobile
                                </th>
                                <th>
                                    Customer Type
                                </th>
                                <th>
                                    Status
                                </th>

                            </tr>
                            </thead>
                            {custlevel && custlevel.length > 0 ?
                                <tbody>
                                {custlevel.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>

                                                {index + 1}
                                            </td>
                                            <td>
                                                {item.modified_on && item.modified_on.substring(0, item.modified_on.lastIndexOf("."))}
                                            </td>
                                            <td>
                                                    {item.cust_name}
                                            </td>
                                            <td>
                                                {item.cif}
                                            </td>
                                            <td>
                                                {item.cust_mobile}
                                            </td>
                                            <td>
                                                {item.cust_id_type ? item.cust_id_type : 'National'}
                                            </td>
                                            <td>
                                                {item.cust_upd_status}
                                            </td>

                                        </tr>
                                    )
                                })}
                                </tbody> :
                                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
                            }
                        </table>
                    </div>
                            </div>

                        </div>
                    </div>



                </div>
            </div>

        </React.Fragment>
    )
}

export default IncompleteStage