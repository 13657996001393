import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom';
import {Provider} from 'react-redux';
import configureStore from './configureStore';

import Login from './pages/Login';
import ListView from './pages/ListView';
import GridView from './pages/GridView';
import DetailView from './pages/DetailView';
import ListRoles from './pages/ListRoles';
import AddRole from './pages/AddRole';
import ListUsers from "./pages/ListUsers";
import AddUser from "./pages/AddUser";
import ListMaritalstatus from "./pages/ListMaritalstatus";
import AddMaritalstatus from "./pages/AddMaritalstatus";
import ListMonthlyincome from "./pages/ListMonthlyincome";
import AddMonthlyincome from "./pages/AddMonthlyincome";
import ListUnemployedStatuses from "./pages/ListUnemployedStatuses";
import ListEmploymentstatuses from "./pages/Listemploymentstatuses";
import ListPostalCodes from "./pages/ListPostalCodes";
import AddEmploymentstatus from "./pages/AddEmploymentstatus";
import AddPostalCodes from "./pages/AddPostalCodes";
import AddUnemployedstatus from "./pages/AddUnemployedStatus";
import ListCustomers from "./pages/ListCustomers";
import ListReKYC from "./pages/ListReKYC";
import ListCustomersApproved from "./pages/ListCustomersApproved";
import ListCustomersForward from "./pages/ListCustomersForward";
import ListCustomersRejected from "./pages/ListCustomersRejected";
import ListSettings from "./pages/ListSettings";
import AddSettings from "./pages/AddSettings";
import ListStatus from "./pages/ListStatus";
import AddStatus from "./pages/AddStatus";
import Dashboard from "./pages/Dashboard" ;
import ListMasterLog  from "./pages/ListMasterLog" ;
import ListSystemsLog  from "./pages/ListSystemsLog" ;
import PermissionSettings  from "./pages/PermissionSettings" ;
import ListCountry from "./pages/ListCountry";
import AddCountry from "./pages/AddCountry";
import Reportsstp from "./pages/Reportsstp";
import Reportsnonstp from "./pages/Reportsnonstp";
import Reportslid from "./pages/Reportslid" ;



import './assets/css/fonts.css';
import './assets/css/template.css';
import IncompleteStage from "./pages/IncompleteStage";
import Layout from "./components/Layout";

const store = configureStore();

class App extends Component {
    render() {
        return (
            <Provider store={store}>

            <Router>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Layout>
                        <Route path="/list-roles" exact component={ListRoles} />
                        <Route path="/add-role" exact component={AddRole} />
                        {/*<Route path="/home" exact component={ListView} />*/}
                        <Route path="/home-grid" exact component={GridView} />
                        <Route path="/detail-view" exact component={DetailView} />
                        <Route path="/list-users" exact component={ListUsers} />
                        <Route path="/add-user" exact component={AddUser} />
                        <Route path="/list-maritalstatus" exact component={ListMaritalstatus} />
                        <Route path="/add-maritalstatus" exact component={AddMaritalstatus} />
                        <Route path="/list-monthlyincome" exact component={ListMonthlyincome} />
                        <Route path="/add-monthlyincome" exact component={AddMonthlyincome} />
                        <Route path="/list-unemployedstatuses" exact component={ListUnemployedStatuses} />
                        <Route path="/list-employmentstatuses" exact component={ListEmploymentstatuses} />
                        <Route path="/list-postalcodes" exact component={ListPostalCodes} />
                        <Route path="/add-postalcodes" exact component={AddPostalCodes} />
                        <Route path="/add-employmentstatuses" exact component={AddEmploymentstatus} />
                        <Route path="/add-unemployedstatus" exact component={AddUnemployedstatus} />
                        <Route path="/rekyc" exact component={ListReKYC} />
                        <Route path="/pending" exact component={ListCustomers} />
                        <Route path="/approved" exact component={ListCustomersApproved} />
                        <Route path="/forward" exact component={ListCustomersForward} />
                        <Route path="/rejected" exact component={ListCustomersRejected} />
                        <Route path="/incompletestage" exact component={IncompleteStage} />

                        <Route path="/list-settings" exact component={ListSettings} />
                        <Route path="/list-country" exact component={ListCountry} />
                        <Route path="/add-country" exact component={AddCountry} />
                        <Route path="/add-settings" exact component={AddSettings}/>
                        <Route path="/list-status" exact component={ListStatus}/>
                        <Route path="/add-status" exact component={AddStatus}/>
                        <Route path="/dashboard" exact component={Dashboard}/>
                        <Route path="/list-masterslog" exact component={ListMasterLog}/>
                        <Route path="/list-systemslog" exact component={ListSystemsLog}/>
                        <Route path="/setpermission" exact component={PermissionSettings} />

                        <Route path="/stp-reports" exact component={Reportsstp} />
                        <Route path="/non-stp-reports" exact component={Reportsnonstp} />
                        <Route path="/lid-reports" exact component={Reportslid} />
                    </Layout>

                </Switch>
            </Router>

            </Provider>
        )
    }
}

export default App;
