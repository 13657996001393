import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListCountrys,
    FetchCountrys,
    EditSettingsData,
    AddPendingApprovals,
    FetchMastersLog,
    ListUser, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListCountry = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "Country", "value": "country"},{"label": "Country Code", "value": "code"}]);
    const [modalIn, setModalIn] = useState(false);
    const [country, setCountry] = useState([]);
    const [selcountryid, setSelcountryid] = useState('');
    const [selectedcountry, setSelectedcountry] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchby, setInputSearchby] = useState('');
    const [searchval, setSearchVal] = useState('' );

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {country: '',countryar : '',countrycode:'', selactive:'', seldeleted:'', updatedcountry: {"status": "pending"}}
    );

    const countrydata = useSelector(state => state.UserReducer.listcountrydata) ;
    const selcountrydata = useSelector(state => state.UserReducer.fetchcountrydata) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;
    const updsettingsdata = useSelector(state => state.UserReducer.updsettingsdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(ListCountrys(searchval,searchby)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    },[dispatch]);

    useEffect(() => {
        setCountry(countrydata)
    },[countrydata]);
    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);

    useEffect(() => {
        setInputValues({ "updatedsettings" : updsettingsdata });
    },[updsettingsdata]);


    useEffect(() => {

        //console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);

            }
        }
    },[newdatarow]);


    useEffect(() => {
        setSelectedcountry(selcountrydata)
        if(selcountrydata && selcountrydata.length > 0 ){
            let countryAr = selcountrydata[0].CName_Ar ? selcountrydata[0].CName_Ar : '';
            setInputValues({ "country" : selcountrydata[0].CName, "countryar" : countryAr, "countrycode" : selcountrydata[0].CCode, "selactive" : selcountrydata[0].IsActive, "seldeleted" : selcountrydata[0].IsDeleted, "updatedcountry" : {"status": "pending"} });
        }

    },[selcountrydata]);


    const toggleModal = (id) => {
        console.log(id) ;
        setStatus(false);
        setButtonStatus(false);
        if(id == 0){
            dispatch(ListCountrys(searchval,searchby)) ;
        }else{
            setStatusloader(true);
            setSelcountryid(id) ;
            dispatch(FetchCountrys(id)) ;
            dispatch(FetchMastersLog('Country',id)) ;
        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "country" : '',"countryar": '', "countrycode" : '', "selactive" : '', "seldeleted" : '', "updatedcountry":[]});
    }
    const submitForm = () => {
        //dispatch(EditSettingsData(selsettingsid,inputValues.settingskey,inputValues.keyvalue)) ;
        setStatusloader(true);
        const myObj = {
            "id": selcountryid,
            "CName": inputValues.country,
            "CNameAr": inputValues.countryar,
            "CCode":inputValues.countrycode,
            "IsActive": inputValues.selactive,
            "IsDeleted":inputValues.seldeleted
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('Country',enc_myObjStr,user,'Update',selcountryid)) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const handleFilterChange = (value,name) =>{
        setInputSearchby(value);
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        dispatch(ListCountrys(searchval,searchby)) ;
    }
    console.log(selcountrydata) ;
    console.log(selmastersdata) ;
    return (
        <React.Fragment>
            <Pagesidebar menu="Country" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="Yes"   searchParam = {searchbyvalue} filterlist={filterlist} sidebar={sidebar}  changeNav={changeNav} searchChange = {handleFilterChange} searchValChange = {searchValChange}/>
                </navbar>


                <div className="roundBox">

                    <div className="roundBox__head">
                        <h2>Country</h2>
                        {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&

                        <Link to="/add-country" className="btn btn--primaryG btn--capsule">(+) Country</Link>
                        }
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Country
                                </th>
                                <th>
                                    Country Arabic
                                </th>
                                <th>
                                    Country Code
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    To Be Deleted
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { country && country.map((item,index) => {
                                let countryAr = item.CName_Ar ? item.CName_Ar : '';
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.CName}
                                        </td>
                                        <td>
                                            {countryAr}
                                        </td>
                                        <td>
                                            {item.CCode}
                                        </td>
                                        <td>
                                            { item.IsActive  === "1" ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>
                                            { item.IsDeleted  === "1" ? 'Yes' : 'No'}
                                        </td>
                                        <td>
                                            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                            <span className="iconEdit" onClick={() => toggleModal(item.id)}/>
                                            }
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Country</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Country</label>
                                <input value={inputValues.country} name="country" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                            <div className="csForm__item">
                                <label className="csForm__item__label">Country Arabic</label>
                                <input value={inputValues.countryar} name="countryar" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <div className="csForm__item">
                                <label className="csForm__item__label">Value</label>
                                <input value={inputValues.countrycode} name="countrycode" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />
                            <Selectbox  label="Is Deleted"  name="seldeleted" values={yesnovalue}  onChange={handleChange} selvalue={inputValues.seldeleted}  />


                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&
                                <div className="flexCC">
                                    {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                        <span className="messageIn">Waiting for Approval</span>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListCountry
