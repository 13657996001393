import {authService} from "../services/auth.services";
import {userService} from "../services/user.Services";
import { authservicelogin,authservicelogout  } from '../utilities/AuthService'


export const UserLogin = (username, password) => dispatch => {
    let logindata = authService.login(username, password);
    console.log(logindata);
    logindata.then(logindata => {
        console.log(logindata)

            if(logindata.data.status == "success"){
                let cif ='';
                let sectionmsg='Login Success';
                userService.createAccessLog(cif,sectionmsg,logindata.data.UserId);
                authservicelogin(logindata.data.UserId,logindata.data.UserInfo);
               window.location = "/dashboard";
            }else{
                console.log(logindata.data)
                dispatch({
                    type: 'LOGIN_FAILED',
                    validdataerrors: logindata.data.status

                })
            }





    })

};
export const getConfig = () => dispatch => {
    let siteConfig = authService.getConfig();
    siteConfig.then(config => {
        localStorage.setItem('CC_API', config.data.CC_API)
        localStorage.setItem('GM_API', config.data.GM_API)
        localStorage.setItem('LOGIN_API', config.data.LOGIN_API)

    }).catch(config => {
        console.log( config.error)
    })

};


export const UserLogout = () => dispatch => {
    authservicelogout() ;
    window.location = "/#";

};
