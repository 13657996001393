import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddEmpstatus, AddPendingApprovals} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";


const AddEmploymentstatus = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );
    const [statusloader, setStatusloader] = useState(false);
    const [errorstatus, setErrorstatus] = useState(false);

    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const newempstatusdata = useSelector(state => state.UserReducer.newempstatusdata) ;
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {empstatus: '',selactive:'', newempstatus: []}
    );

    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        setInputValues({ "empstatus" : newempstatusdata });
    },[newempstatusdata]);

    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatusloader(false);
                setStatus(newdatarow.resp_status) ;
                setInputValues({ "empstatus" : '',"empstatusar" : '', "selactive" : '', "newempstatus": ''});
            }
        }
    },[newdatarow]);


    const submitForm = () => {
        //dispatch(AddMstatus(inputValues.mstatus,inputValues.selactive)) ;
        let errorStatus = false;

        if(inputValues.empstatus.length === 0){
            setErrorstatus(true);
            errorStatus = true;

        }else{
            setErrorstatus(false);
            errorStatus = false;

        }
        if(errorStatus === false) {
            const myObj = {
                "EStatus": inputValues.empstatus,
                "EStatusAr": inputValues.empstatusar,
                "IsActive": inputValues.selactive
            };
            const myObjStr = JSON.stringify(myObj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setStatusloader(true);

            dispatch(AddPendingApprovals('EmploymentStatus:'+inputValues.empstatus, enc_myObjStr, user, 'Insert'));
            setSubmit(true);
        }

    }

    const resetForm = () => {
        setStatus(false);
        setInputValues({ "empstatus" : '',"empstatusar" : '', "selactive" : '', "newempstatus": ''});
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }
    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }


    return (
        <React.Fragment>

            <Pagesidebar menu="Employment Status" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    <div className="roundBox__head">
                        <h2>Add Employment Status</h2>
                    </div>
                    {statusloader &&
                    <Loader/>
                    }
                    <div className="p30">
                        { status === "success"  ? (
                            <span className="messageIn">Successfully created the new Employment Status</span>
                        ) : ''
                        }

                        <div className="fullFlexBox">


                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Status</label>

                                    <input value={inputValues.empstatus} name="empstatus" className={`csForm__item__input ${errorstatus ? 'error' : ''} `}  onChange={handleInput} type="text" />

                                </div>
                            </div>
                            <div className="halfBlock">
                                <div className="csForm__item">
                                    <label className="csForm__item__label">Status in Arabic</label>

                                    <input value={inputValues.empstatusar} name="empstatusar" className={`csForm__item__input`}  onChange={handleInput} type="text" />

                                </div>
                            </div>

                            <div className="halfBlock">
                                <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />

                            </div>


                        </div>
                    </div>


                    <div className="p30">
                        <div className="flexCC buttonWrap">
                            <span onClick={resetForm}
                                  className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                            <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default AddEmploymentstatus
