import React from 'react'

const Checkbox = () => {
    return (
        <label className="csCheckbox">
            <input type="checkbox" className="csCheckbox__input" />
            <span className="csCheckbox__placeholder" />
        </label>
    )
}

export default Checkbox
