 import axios from 'axios';
 import {getToken} from "../utilities/AuthService";

// import {CC_API, LOGIN_API} from '../constants/index'


 //
 // if(String(document.location).indexOf('sitagent1') > -1){
 //     CC_API   =  'https://sitagent1.bmoman.bankmuscat.com:1443/cc/' ;
 //     LOGIN_API=  'https://sitagent1.bmoman.bankmuscat.com:1443/cc/agentlogin/login' ;
 // }else if(String(document.location).indexOf('uatrekycagent1') > -1){
 //     CC_API   =  'https://kycadmin.bmoman.bankmuscat.com:11443/cc/' ;
 //     LOGIN_API=  'https://kycadmin.bmoman.bankmuscat.com:11443/cc/agentlogin/login' ;
 // } else{
 //     CC_API   =  'https://bom.labsls.com/' ;
 //     LOGIN_API=  'https://bom.labsls.com/agentlogin/verifylogin' ;
 // }
 // console.log(CC_API, LOGIN_API)


export const authService = {
    login,
    getConfig
};

/*
 function login(username,passs){
     return  axios.post(CC_API + 'agentlogin/verifylogin', {
         "username": username,
         "password": passs
     }, {headers: {
             "Content-Type": "application/json",
             "accept": "application/json",
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
             "Cache-Control":"no-cache, must-revalidate"
        }}).then(function (response) {
         console.log(response);
        })
         .catch(function (error) {
             console.log(error);
         })


 return axios.get(CC_API + 'masters/listroles')

  }  */

/*
 function login(username,passs){
  return axios.post(OR_API_PATH + 'adminauth/verifyLoginv2', {
       "username": username,
       "password": passs
   })

}   */

 function getAgentloginapi(){
     return localStorage.getItem("LOGIN_API")
 }


 function getConfig(){
     return axios.get('config.json');

 }

 function login(username,passs){

     return axios.post(getAgentloginapi(), {
         "username": username,
         "password": passs
     })

 }





/* function login(username,passs){
    return axios.get(CC_API + 'agentlogin/verify?username='+username+'&password='+passs) ;

 } */
