import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListMastersEntry,
    fetchMastersEntry,
    updateStatusMastersLog,
    ListMoreCustdata,
    ListMoreMastersEntry, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken, getUrlSegment} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListMasterLog = () => {
    const [hasError, setErrors] = useState(false);
    const [sortfiltervalue, setFiltervalue] = useState([{"label": "All", "value": ""},{"label": "Users", "value": "Users"},{"label": "Roles", "value": "Roles"},{"label": "Country", "value": "Country"},{"label": "Status", "value": "Status"},{"label": "Marital Status", "value": "Marital"},{"label": "Income Range", "value": "Income Range"},{"label": "Unemployment", "value": "Unemployment"},{"label": "Employment Status", "value": "Employment"}]);
    const [modalIn, setModalIn] = useState(false);
    const [masterdata, setMasterdata] = useState([]);
    const [updmasterdata, setUpdmasterdata] = useState([]);

    const [selstatusid, setSelstatusid] = useState('');
    const [singlerecord, setSinglerecord] = useState([]);
    const [selectedrecord, setSelectedrecord] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);

    const [updstatus, setUpdstatus] = useState('');
    const [updstatusmsg, setUpdstatusMsg] = useState('');
    const [valuesloader, setValuesloader] = useState(false);
    const [perpage, setPerpage] = useState('10');

    const params = (new URL(document.location)).searchParams  ;
    const liststatus = params.get("status") ? params.get("status") : 'pending'  ;
    console.log(liststatus) ;

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {status: '', updatedstatuses: {"status": "pending"}}
    );

    const listmasterdata = useSelector(state => state.UserReducer.listmasterdata) ;
    const fetchmasterdata = useSelector(state => state.UserReducer.fetchmasterdata) ;
    const updatedmasterdata = useSelector(state => state.UserReducer.updatedmasterdata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;


    let history = useHistory();
    const dispatch = useDispatch();

    let page = 1;

    useEffect(() => {
        dispatch(ListMastersEntry(liststatus,inputValues.categoryFilter,perpage,page,'false')) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;
    },[liststatus]);

    useEffect(() => {
        setValuesloader(false);
        setMasterdata(listmasterdata)

    },[listmasterdata]);

    useEffect(() => {
        console.log(updatedmasterdata) ;
        if(updatedmasterdata !== undefined){
            setUpdmasterdata(updatedmasterdata)
            console.log(updatedmasterdata);
            setUpdstatus(updatedmasterdata.resp_status)
            setUpdstatusMsg(updatedmasterdata.ap_status)
        }

    },[updatedmasterdata]);


 /*    useEffect(() => {
        setInputValues({ "updatedstatuses" : updstatusesdata });
    },[updstatusesdata]);  */


    useEffect(() => {
        console.log(fetchmasterdata) ;
        if(fetchmasterdata.length  >0 ){
            setSelectedrecord(fetchmasterdata[0]);
            console.log(fetchmasterdata[0].ap_text) ;
           const enc_myObjStr = fetchmasterdata[0].ap_text ;
            console.log(enc_myObjStr) ;

           const dec_myObjStr = new Buffer(enc_myObjStr, "base64").toString();
            console.log(dec_myObjStr);

            const myObj = JSON.parse(dec_myObjStr);
            console.log(myObj);
            setSinglerecord(myObj) ;
            console.log(singlerecord) ;
        }

    },[fetchmasterdata]);


    const toggleModal = (id) => {
        console.log(id) ;
        if(id == 0){
            dispatch(ListMastersEntry(liststatus,inputValues.categoryFilter,perpage,page,'false')) ;
        }else{
            setSelstatusid(id) ;
          /*   dispatch(FetchStatus(id)) ; */
            setUpdstatus('') ;
            dispatch(fetchMastersEntry(id)) ;

        }
        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "status" : '', "updatedstatuses":[]});
    }
    const submitForm = (status,id) => {
      /*   dispatch(EditStatusesData(selstatusid,inputValues.status)) ; */
        console.log(status+" : "+id) ;
        dispatch(updateStatusMastersLog(id,status,selectedrecord.ap_section.split(":")[0],selectedrecord.ap_action,user)) ;
    }
    const listenToScroll = () => {
        const container = document.querySelector('.scrollable')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);


        if (contentHeight <= scrollTop) {
            page = parseInt(page) + 1;
            dispatch(ListMoreMastersEntry(liststatus,inputValues.categoryFilter,perpage,page,'false')) ;
        }
    }
    useEffect(() => {
        const container = document.querySelector('.scrollable');
        container.addEventListener("scroll", listenToScroll);
        return () => container.removeEventListener("scroll", listenToScroll);

    }, [liststatus,inputValues.categoryFilter]);


    const changeNav = () =>{
        setShowsidebar(!showsidebar);
        dispatch(ListMastersEntry(liststatus,inputValues.categoryFilter,perpage,1,'false')) ;

    }

    const handleFilter = (value,name) =>{
        setValuesloader(true);
        setInputValues({ [name]: value });
        dispatch(ListMastersEntry(liststatus,value,perpage,1,'false')) ;

    }

console.log(inputValues.categoryFilter);
console.log(liststatus);

    return (
        <React.Fragment>
            <Pagesidebar menu={liststatus.charAt(0).toUpperCase()+liststatus.substring(1).toLowerCase()} mainmenu="Approval List" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Approval List"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>

                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head flexCS">
                        <h2>Masters {liststatus.charAt(0).toUpperCase()+liststatus.substring(1).toLowerCase()} List</h2>
                        <div className="sortBox__check">
                            {/*<label  className="csForm__item__label">Sort By Date :</label>*/}
                            <Selectbox label="Category filter" name="categoryFilter"  labelStatus = "No" values={sortfiltervalue} onChange={handleFilter} selvalue={inputValues.categoryFilter}/>
                        </div>
                    </div>

                    <div className="tableWrapper scrollable heighted">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Service
                                </th>
                                <th>
                                    Action
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Entered By
                                </th>
                                <th>
                                    Entered On
                                </th>

                                <th></th>

                            </tr>
                            </thead>
                            {masterdata && masterdata.length > 0 ?
                                <tbody>
                                {masterdata.length > 0 && masterdata.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>

                                                {index + 1}
                                            </td>
                                            <td>
                                                {item.ap_section}
                                            </td>
                                            <td>
                                                {item.ap_action}
                                            </td>
                                            <td>
                                                {item.ap_status}
                                            </td>
                                            <td>
                                                {item.ap_entered_by}
                                            </td>
                                            <td>
                                                {item.ap_entered_on && item.ap_entered_on.substring(0, item.ap_entered_on.lastIndexOf("."))}
                                            </td>

                                            <td>
                                                <span className="iconIn iconIn--eye-primary"
                                                      onClick={() => toggleModal(item.ap_id)}/>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody> :
                                <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Approve/Reject {selectedrecord.ap_action === "Insert" ? "New" : "Update"} {selectedrecord.ap_section && selectedrecord.ap_section.split(":")[0]}</h3>


                            { Object.keys(singlerecord).map((item, i) => (
                                <>
                                { item === "RoleId" ? '' : (
                                <div key={i} className="report">

                                    { item === "IsActive" || item === "IsDeleted" ? (

                                        <p> <label class="label-ttl"> {item}: </label>  <label class="label-item">{ singlerecord[item] == 1 ? "Yes" : "No" }</label> </p>

                                    ) : (
                                        <p> <label class="label-ttl"> {item} : </label> <label className="label-item"> { singlerecord[item] } </label> </p>
                                    ) }

                                </div>
                                )}
                                </>
                            ))}




                            <div className="flexCC">
                                { updstatus  !== "success" ? (
                                 <>
                                     {  liststatus === 'pending' &&(
                                         <>
                                             {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '7' && (item.actionId === '2')) > -1)) &&
                                                <>
                                                 <span className="btn btn--primaryG btn--sm btn--capsule"
                                                       onClick={() => submitForm('approved', selstatusid)}>Approve</span>
                                                     <span className="btn btn--primaryG btn--sm btn--capsule" onClick={() => submitForm('rejected',selstatusid)}>Reject</span>
                                                </>
                                             }
                                             </>
                                         )}
                                 </>
                                    ):
                                    <div> <span className="messageIn">{updstatusmsg && updstatusmsg.charAt(0).toUpperCase() + updstatusmsg.slice(1)}</span></div>

                                    }
                            </div>

                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListMasterLog
