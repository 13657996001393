import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddPendingApprovals, AddSetting} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";


const AddSettings = () => {
    const [hasError, setErrors] = useState(false);
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {key: '',keyvalue:'', newsettings: []}
    );

    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );
    const [statusloader, setStatusloader] = useState(false);
    const [errorstatus, setErrorstatus] = useState(false);
    const [errokeystatus, setErrorKeystatus] = useState(false);
    const [errorvalstatus, setErrorValuestatus] = useState(false);

    const newsettingsdata = useSelector(state => state.UserReducer.newsettingsdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;

    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatusloader(false);
                setStatus(newdatarow.resp_status) ;
                setInputValues({ "key" : '', "keyvalue" : '', "newsettings": ''});
            }
        }
    },[newdatarow]);

    useEffect(() => {
        setInputValues({ "newsettings" : newsettingsdata });
    },[newsettingsdata]);


    const submitForm = () => {
        //dispatch(AddSetting(inputValues.key,inputValues.keyvalue)) ;
        let keyErr = false;
        let keyVal = false;
         if(!inputValues.key){
             keyErr = false;
            setErrorKeystatus(true)
        } else {
             keyErr = true;
             setErrorKeystatus(false)
        }
        if(!inputValues.keyvalue){
            keyVal = false;
            setErrorValuestatus(true)
        } else {
            keyVal = true;
            setErrorValuestatus(false)
        }

        if(keyVal && keyErr ) {
            const myObj = {
                "Key": inputValues.key,
                "Value": inputValues.keyvalue
            };
            const myObjStr = JSON.stringify(myObj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setStatusloader(true);
            const service = 'Settings:'+inputValues.key;
            dispatch(AddPendingApprovals(service, enc_myObjStr, user, 'Insert'));
            setSubmit(true);
        }
    }

    const resetForm = () => {
        setStatus(false);
        setInputValues({ "key" : '', "keyvalue" : '', "newsettings": ''});
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }


    return (
        <React.Fragment>

            <Pagesidebar menu="Settings" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>


            <div className="roundBox">
                <div className="roundBox__head">
                    <h2>Add Settings</h2>
                </div>
                {statusloader &&
                <Loader/>
                }
                <div className="p30">
                    { status === "success"  ? (
                        <span className="messageIn">Successfully created the new Settings</span>
                    ) : ''
                    }

                    <div className="fullFlexBox">


                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Key</label>

                                <input value={inputValues.key} name="key"  className={`csForm__item__input ${errokeystatus ? 'error' : ''} `}  onChange={handleInput} type="text" />

                            </div>
                        </div>

                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Value</label>

                                <input value={inputValues.keyvalue} name="keyvalue"  className={`csForm__item__input ${errorvalstatus ? 'error' : ''} `}  onChange={handleInput} type="text" />

                            </div>
                        </div>


                    </div>
                </div>
                <div className="p30">
                    <div className="flexCC buttonWrap">
                        <span onClick={resetForm} className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                        <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                    </div>
                </div>


            </div>
        </div>
        </React.Fragment>
    )
}

export default AddSettings
