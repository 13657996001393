
export const FetchMenu = (menu,mastermenu) => dispatch => {

       const data = {
           "cmenu" :   menu,
           "pmenu" :  mastermenu
       }

               dispatch({
                type: 'FETCH_MENU',
                fetchmenudata:  data
            })


};





