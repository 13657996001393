import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddMincome, AddPendingApprovals} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";


const AddMonthlyincome = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [status, setStatus] = useState('' );
    const [submit, setSubmit] = useState(false );
    const [statusloader, setStatusloader] = useState(false);
    const [errorstatus, setErrorstatus] = useState(false);
    const newmincomedata = useSelector(state => state.UserReducer.newmincomedata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {selactive: '', incomerange: '',incomerangear: '', newincome: []}
    );

    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatusloader(false);
                setInputValues({ "incomerange" :'',"incomerangear" :'', "selactive" : '', "newincome" : [] });
                setStatus(newdatarow.resp_status) ;

            }
        }
    },[newdatarow]);


    useEffect(() => {
        setInputValues({ "newincome" : newmincomedata });
    },[newmincomedata]);

    const submitForm = () => {
        //dispatch(AddMincome(inputValues.incomerange,inputValues.selactive)) ;
        let errorStatus = false;

        if(!inputValues.incomerange){
            setErrorstatus(true);
            errorStatus = true;

        }else{
            setErrorstatus(false);
            errorStatus = false;

        }
        if(errorStatus === false) {
            const myObj = {
                "IncomeRange": inputValues.incomerange,
                "IncomeRangeAr": inputValues.incomerangear,
                "IsActive": inputValues.selactive
            };
            const myObjStr = JSON.stringify(myObj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setStatusloader(true);
            dispatch(AddPendingApprovals('MonthlyIncome:'+inputValues.incomerange, enc_myObjStr, user, 'Insert'));
            setSubmit(true);
        }
    }

    const resetForm = () => {
        setStatus(false);
        setInputValues({ "incomerange" :'', "incomerangear" :'', "selactive" : '', "newincome" : [] });
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }



    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }



    return (
        <React.Fragment>
        <Pagesidebar menu="Income Range" mainmenu="Masters" showsidebar={showsidebar} />
        <div className="pageSidebarOffset">
        <navbar className="pageNav">
        <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
       </navbar>

            <div className="roundBox">
                <div className="roundBox__head">
                    <h2>Add Monthly Income</h2>
                </div>
                {statusloader &&
                <Loader/>
                }
                <div className="p30">
                    { status === "success"  ? (
                        <span className="messageIn">Successfully created the new Income Range</span>
                    ) : ''
                    }

                    <div className="fullFlexBox">


                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Income Range</label>

                                <input value={inputValues.incomerange} name="incomerange" className={`csForm__item__input ${errorstatus ? 'error' : ''} `}   onChange={handleInput} type="text" />

                            </div>
                        </div>
                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Income Range in Arabic</label>

                                <input value={inputValues.incomerangear} name="incomerangear" className={`csForm__item__input`}   onChange={handleInput} type="text" />

                            </div>
                        </div>
                        <div className="halfBlock">
                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />

                        </div>

                    </div>
                </div>
                <div className="p30">
                    <div className="flexCC buttonWrap">
                        <span onClick={resetForm} className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                        <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>

                    </div>
                </div>

            </div>
        </div>
        </React.Fragment>
    )
}

export default AddMonthlyincome
