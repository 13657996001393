import React, {useState, useEffect, useReducer } from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AddPendingApprovals, AddUsers, ListUserRolesDropDown} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar  from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";


const AddUser = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [submit, setSubmit] = useState(false );
    const [user, setUser] = useState(getToken().name);
    const [rolesvalues, setRolesvalues] = useState([]);
    const [status, setStatus] = useState('' );

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {username: '', useremail:'' , userphone:'' , userempid:'' , userrole:'' , selactive:'' , seldeleted:'', userpassword:'', newuser: []}
    );

    const rolesdata = useSelector(state => state.UserReducer.listrolesdropdowndata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;


    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ListUserRolesDropDown()) ;
    },[dispatch]);

    useEffect(() => {
        setRolesvalues(rolesdata)
    },[rolesdata]);

    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
            }
        }
    },[newdatarow]);


    const submitForm = () => {
       const myObj = {
            "UserName": inputValues.username,
            "UserEmail": inputValues.useremail,
            "UserPhoneNo":inputValues.userphone,
            "UserEmpId": inputValues.userempid,
            "UserRole": inputValues.userrole,
            "IsActive": inputValues.selactive,
            "IsDeleted": inputValues.seldeleted,
            "UserPassword": inputValues.userpassword
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        dispatch(AddPendingApprovals('Users:'+inputValues.username,enc_myObjStr,user,'Insert')) ;
        setSubmit(true);

    }

    const resetForm = () => {
        setInputValues({ "username" : '', "useremail" : '', "userphone": '', "userempid":'', "userrole":'', "selactive":'', "seldeleted":'', "userpassword":'', "newuser" : '' });
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }
    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }


    return (
        <React.Fragment>

            <Pagesidebar menu="Users" mainmenu="Administration" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Administration"  showsidebar={showsidebar} filter="No" search="No"   filterlist={filterlist} sidebar={sidebar} changeNav={changeNav}/>
                </navbar>

            <div className="roundBox">
                <div className="roundBox__head">
                    <h2>Add User</h2>
                </div>
                <div className="p30">


                    { status === "success"  ? (
                        <span className="messageIn">Successfully created the new user</span>
                    ) : ''
                    }


                    <div className="fullFlexBox">


                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Name</label>

                                <input value={inputValues.username} name="username" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>
                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Email</label>

                                <input value={inputValues.useremail} name="useremail" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>
                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Phone Number</label>

                                <input value={inputValues.userphone} name="userphone" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>
                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Employee ID</label>

                                <input value={inputValues.userempid} name="userempid" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>


                       <div className="halfBlock">
                            <Selectbox  label="Role" name="userrole"  values={rolesvalues}   onChange={handleChange}  selvalue={inputValues.userrole} />

                        </div>


                        <div className="halfBlock">
                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />

                        </div>

                        <div className="halfBlock">
                            <Selectbox  label="Is Deleted" name="seldeleted"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.seldeleted} />

                        </div>


                        <div className="halfBlock">
                            <div className="csForm__item">
                                <label className="csForm__item__label">Password</label>

                                <input value={inputValues.userpassword} name="userpassword" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                        </div>

                    </div>
                </div>
                <div className="p30">
                    <div className="flexCC buttonWrap">
                        <span onClick={resetForm} className="btn btn--primaryG btn--sm btn--capsule">Reset Form</span>
                        <span onClick={submitForm} className="btn btn--primaryG btn--sm btn--capsule">Submit</span>
                    </div>
                </div>

            </div>
        </div>
        </React.Fragment>
    )
}

export default AddUser
