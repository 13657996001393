import React, {useState, useEffect } from 'react'
import {Link, useHistory} from 'react-router-dom';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {UserLogin,getConfig} from "../actions/Authorization"
import {useDispatch, useSelector} from "react-redux";
import logo from "../assets/images/logo.png";
import {CreateAccessLog} from "../actions/User";



const Login = (props) => {

    const [username, setUsername] = useState('');
    const [version, setVersion] = useState(false);
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [msgclass, setMsgclass] = useState('');
    const [agentdata, setAgentdata] = useState([]);
    const [message, setMessage] = useState('');

    let history = useHistory();
    const dispatch = useDispatch();
    const agentlogindata = useSelector(state => state.LoginReducer.Validdata  ) ;

console.log(agentlogindata);

    useEffect(() => {
        console.log(4343);
        if(localStorage.getItem("LOGIN_API") === ''){
            console.log(232323);
            setTimeout(dispatch(getConfig()),100);
        }
        if(agentlogindata==='error'){
            let cif =0;
            let sectionmsg='Login failed';
            dispatch(CreateAccessLog(cif,sectionmsg,username));
            setMessage('please provide valid login credentials')
        }
        dispatch(getConfig());
        setAgentdata(agentlogindata)
    },[agentlogindata]);

    useEffect(() => {
        console.log(4343);
        if(localStorage.getItem("LOGIN_API") === ''){
            console.log(232323);
            setTimeout(dispatch(getConfig()),100);
        }
    },);

   /*  componentDidUpdate(prevProps, prevState, snapshot) {
        let msg = this.state.msg ;
        if(prevProps.loginData.Validdata !==  this.props.loginData.Validdata) {
            if (this.props.loginData !== undefined) {

                if (this.props.loginData.Validdata !== undefined) {
                    console.log(this.props.loginData.Validdata)
                    if (this.props.loginData.Validdata === false) {
                        msg = "Invalid Username/Password";
                    }
                    this.setState({
                        msg: msg,
                        msgclass: 'error'
                    })
                }
            }
        }
    } */


  /*   formHandler(e){
        this.setState({[e.target.name]:e.target.value},()=>{
            console.log(this.state.username);
            console.log(this.state.password);
        })
    } */


    const formHandler = (e) => {
        if(e.target.name == "username") {
            setUsername(e.target.value)
        }
        if(e.target.name == "password") {
            setPassword(e.target.value)

        }
    }
    /* Login(e) {
        e.preventDefault();
        if( username !== '' && password !== '') {
            dispatch(UserLogin(username, password));
        }
    } */

    const Login = (e) => {
        e.preventDefault();

        if( username !== '' && password !== '') {
            let cif ='';
            let sectionmsg='Clicked on Login button';
            dispatch(CreateAccessLog(cif,sectionmsg,username));
            dispatch(UserLogin(username, password));
        }
    };

    const showVersion = () =>{
        setVersion(!version) ;
    }

    console.log(agentdata) ;
        return (
            <div className="flexCC fullViewPort" onSubmit={Login} >
                <div className="w355">
                    <Link  onClick={() => showVersion()}  className="logo">
                        <img src={logo} className="logoImage" alt="logo"/>
                    </Link>
                    <h1>LOGIN</h1>
                    <div className={`login-resp-msg  ${msgclass}`} >{msg}</div>
                    <form className="csForm">
                        <div className="csForm__item">
                            <input type="text" name="username" className="csForm__item__input" placeholder="Username" onChange={formHandler}/>
                        </div>

                        <div className="csForm__item">
                            <input name="password" type="password" className="csForm__item__input" placeholder="Password"  onChange={formHandler} />
                        </div>

                        <div className="csForm__item">
                            <button  name="password" className="btn btn--primaryG btn--capsule btn--inFull">Login</button>
                            <p className="messageIn">{message}</p>
                        </div>
                        {version &&
                        <p>v37</p>
                        }
                        {/*<div className="csForm__item">*/}
                        {/*    <Link to="/" className="primaryTextLink">FORGOT PASSWORD</Link>*/}
                        {/*</div>*/}
                    </form>
                </div>
            </div>
        );
}

export default Login ;
