import {userService} from "../services/user.Services";

export const ListUserRoles = ($search) => dispatch => {
    let rolesdata = userService.ListUserRoles($search);
    console.log(rolesdata);
    rolesdata.then(rolesdata => {
        console.log(rolesdata)

            console.log(rolesdata.data)
            if(rolesdata.data  !== undefined){
                dispatch({
                    type: 'LIST_ROLES',
                    listrolesdata: rolesdata.data

                })

            }
    })

};

export const AddPendingApprovals = (service,actiontext,user,action,id) => dispatch => {
    console.log(service+" "+actiontext+" "+user+" "+action);
    let newdata = userService.AddPendingApprovals(service,actiontext,user,action,id);
    console.log(newdata);
    newdata.then(newdatarow => {
        console.log(newdatarow)

        console.log(newdatarow.data)
        if(newdatarow.data  !== undefined){
            dispatch({
                type: 'Masters_Approval',
                newdatarow: newdatarow.data

            })

        }
    })
};

export const AddUserRoles = (role,isActive,isDeleted) => dispatch => {
    console.log(role+" "+isActive+" "+isDeleted);
    let newrolesdata = userService.AddUserRoles(role,isActive,isDeleted);
    console.log(newrolesdata);
    newrolesdata.then(newrolesdata => {
        console.log(newrolesdata)

        console.log(newrolesdata.data)
        if(newrolesdata.data  !== undefined){
            dispatch({
                type: 'ADD_ROLE',
                newrolesdata: newrolesdata.data

            })

        }
    })

};

export const FetchUserRole = (roleid) => dispatch => {
    console.log(roleid);
    let fetchroledata = userService.FetchUserRole(roleid);
    console.log(fetchroledata);
    fetchroledata.then(fetchroledata => {
        console.log(fetchroledata)

        console.log(fetchroledata.data)
        if(fetchroledata.data  !== undefined){
            dispatch({
                type: 'FETCH_ROLE',
                fetchroledata: fetchroledata.data

            })

        }
    })

};


export const EditUserRole = (roleid,role,isActive,isDeleted) => dispatch => {
    console.log(roleid+" "+role+" "+isActive+" "+isDeleted);
    let updrolesdata = userService.EditUserRole(roleid,role,isActive,isDeleted);
    console.log(updrolesdata);
    updrolesdata.then(updrolesdata => {
        console.log(updrolesdata)

        console.log(updrolesdata.data)
        if(updrolesdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_ROLE',
                updrolesdata: updrolesdata.data

            })

        }
    })

};

export const ListUser = (searchval,searchby) => dispatch => {
    let usersdata = userService.ListUser(searchval,searchby);
    console.log(usersdata);
    usersdata.then(usersdata => {
        console.log(usersdata)

        console.log(usersdata.data)
        if(usersdata.data  !== undefined){
            dispatch({
                type: 'LIST_USER',
                listusersdata: usersdata.data

            })

        }
    })

};


export const AddUsers = (username,useremail,userphone,userempid,userrole,selactive,seldeleted,userpassword) => dispatch => {
    console.log(username+" "+useremail+" "+userphone+" "+userempid+" "+userrole+" "+selactive+" "+seldeleted+" "+userpassword);
    let newusersdata = userService.AddUsers(username,useremail,userphone,userempid,userrole,selactive,seldeleted,userpassword);
    console.log(newusersdata);
    newusersdata.then(newusersdata => {
        console.log(newusersdata)

        console.log(newusersdata.data)
        if(newusersdata.data  !== undefined){
            dispatch({
                type: 'ADD_USER',
                newusersdata: newusersdata.data

            })

        }
    })

};


export const FetchUserDetails = (uniqueId) => dispatch => {
    console.log(uniqueId);
    let fetchuserdata = userService.FetchUserDetails(uniqueId);
    console.log(fetchuserdata);
    fetchuserdata.then(fetchuserdata => {
        console.log(fetchuserdata)

        console.log(fetchuserdata.data)
        if(fetchuserdata.data  !== undefined){
            dispatch({
                type: 'FETCH_USER',
                fetchuserdata: fetchuserdata.data

            })

        }
    })

};


export const EditUserData = (seluserid,username,useremail,userphone,userempid,userrole,selactive) => dispatch => {
    console.log(seluserid+" "+username+" "+useremail+" "+userphone+" "+userempid+" "+userrole+" "+selactive);
    let upduserdata = userService.EditUserData(seluserid,username,useremail,userphone,userempid,userrole,selactive);
    console.log(upduserdata);
    upduserdata.then(upduserdata => {
        console.log(upduserdata)

        console.log(upduserdata.data)
        if(upduserdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_USER',
                upduserdata: upduserdata.data

            })

        }
    })

};



export const ListMincome = (searchval) => dispatch => {
    let listmincomedata = userService.ListMincome(searchval);
    console.log(listmincomedata);
    listmincomedata.then(listmincomedata => {
        console.log(listmincomedata)

        console.log(listmincomedata.data)
        if(listmincomedata.data  !== undefined){
            dispatch({
                type: 'LIST_MINCOME',
                listmincomedata: listmincomedata.data

            })

        }
    })

};


export const AddMincome = (incomerange,isActive) => dispatch => {
    console.log(incomerange+" "+isActive);
    let newmincomedata = userService.AddMincome(incomerange,isActive);
    console.log(newmincomedata);
    newmincomedata.then(newmincomedata => {
        console.log(newmincomedata)

        console.log(newmincomedata.data)
        if(newmincomedata.data  !== undefined){
            dispatch({
                type: 'ADD_MINCOME',
                newmincomedata: newmincomedata.data

            })

        }
    })

};


export const FetchMincome = (id) => dispatch => {
    console.log(id);
    let fetchmincomedata = userService.FetchMincome(id);
    console.log(fetchmincomedata);
    fetchmincomedata.then(fetchmincomedata => {
        console.log(fetchmincomedata)

        console.log(fetchmincomedata.data) ;
        if(fetchmincomedata.data  !== undefined){
            dispatch({
                type: 'FETCH_MINCOME',
                fetchmincomedata: fetchmincomedata.data

            })

        }
    })

};



export const EditMincome = (mincomeid,incomerange,isActive) => dispatch => {
    console.log(mincomeid+" "+incomerange+" "+isActive);
    let updmincomedata = userService.EditMincome(mincomeid,incomerange,isActive);
    console.log(updmincomedata);
    updmincomedata.then(updmincomedata => {
        console.log(updmincomedata)

        console.log(updmincomedata.data)
        if(updmincomedata.data  !== undefined){
            dispatch({
                type: 'UPDATE_MINCOME',
                updmincomedata: updmincomedata.data

            })

        }
    })

};

export const ListMstatus = (searchval) => dispatch => {
    let listmstatusdata = userService.ListMstatus(searchval);
    console.log(listmstatusdata);
    listmstatusdata.then(listmstatusdata => {
        console.log(listmstatusdata)

        console.log(listmstatusdata.data)
        if(listmstatusdata.data  !== undefined){
            dispatch({
                type: 'LIST_MSTATUS',
                listmstatusdata: listmstatusdata.data

            })

        }
    })

};



export const AddMstatus = (mstatus,isActive) => dispatch => {
    console.log(mstatus+" "+isActive);
    let newmstatusdata = userService.AddMstatus(mstatus,isActive);
    console.log(newmstatusdata);
    newmstatusdata.then(newmstatusdata => {
        console.log(newmstatusdata)

        console.log(newmstatusdata.data)
        if(newmstatusdata.data  !== undefined){
            dispatch({
                type: 'ADD_MSTATUS',
                newmstatusdata: newmstatusdata.data

            })

        }
    })

};


export const FetchMstatusDetails = (id) => dispatch => {
    console.log(id);
    let fetchmstatusdata = userService.FetchMstatusDetails(id);
    console.log(fetchmstatusdata);
    fetchmstatusdata.then(fetchmstatusdata => {
        console.log(fetchmstatusdata)

        console.log(fetchmstatusdata.data)
        if(fetchmstatusdata.data  !== undefined){
            dispatch({
                type: 'FETCH_MSTATUS',
                fetchmstatusdata: fetchmstatusdata.data

            })

        }
    })

};


export const EditMstatusData = (mstatusid,mstatus,isActive) => dispatch => {
    console.log(mstatusid+" "+mstatus+" "+isActive);
    let updmstatusdata = userService.EditMstatus(mstatusid,mstatus,isActive);
    console.log(updmstatusdata);
    updmstatusdata.then(updmstatusdata => {
        console.log(updmstatusdata)

        console.log(updmstatusdata.data)
        if(updmstatusdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_MSTATUS',
                updmstatusdata: updmstatusdata.data

            })

        }
    })

};



export const ListEmploymentstatus = (searchval) => dispatch => {
    let listempstatusdata = userService.ListEmploymentstatus(searchval);
    console.log(listempstatusdata);
    listempstatusdata.then(listempstatusdata => {
        if(listempstatusdata.data  !== undefined){
            dispatch({
                type: 'LIST_EMPLOYMENTSTATUS',
                listempstatusdata: listempstatusdata.data

            })

        }
    })

};
export const ListPostalCodes = (searchval) => dispatch => {
    let listpostalcodedata = userService.ListPostalCodes(searchval);
    console.log(listpostalcodedata);
    listpostalcodedata.then(listpostalcodedata => {
        if(listpostalcodedata.data  !== undefined){
            dispatch({
                type: 'LIST_POSTALCODES',
                listpostalcodedata: listpostalcodedata.data

            })

        }
    })

};
export const AddEmpstatus = (empstatus,isActive) => dispatch => {
    console.log(empstatus+" "+isActive);
    let newempstatusdata = userService.AddEmpstatus(empstatus,isActive);
    console.log(newempstatusdata);
    newempstatusdata.then(newempstatusdata => {
        console.log(newempstatusdata)

        console.log(newempstatusdata.data)
        if(newempstatusdata.data  !== undefined){
            dispatch({
                type: 'ADD_EMPLOYMENTSTATUS',
                newempstatusdata: newempstatusdata.data

            })

        }
    })

};
export const FetchEmpstatusDetails = (id) => dispatch => {
    console.log(id);
    let fetchempstatusdata = userService.FetchEmpstatusDetails(id);
    console.log(fetchempstatusdata);
    fetchempstatusdata.then(fetchempstatusdata => {
        console.log(fetchempstatusdata)

        console.log(fetchempstatusdata.data)
        if(fetchempstatusdata.data  !== undefined){
            dispatch({
                type: 'FETCH_EMPSTATUS',
                fetchempstatusdata: fetchempstatusdata.data

            })

        }
    })

};
export const FetchPostalDetails = (id) => dispatch => {
    console.log(id);
    let fetchpostalcodedata = userService.FetchPostalDetails(id);
    console.log(fetchpostalcodedata);
    fetchpostalcodedata.then(fetchpostalcodedata => {
        console.log(fetchpostalcodedata)

        console.log(fetchpostalcodedata.data)
        if(fetchpostalcodedata.data  !== undefined){
            dispatch({
                type: 'FETCH_POSTALCODES',
                fetchpostalcodedata: fetchpostalcodedata.data

            })

        }
    })

};
export const EditEmpstatusData = (empstatusid,empstatus,isActive) => dispatch => {
    console.log(empstatusid+" "+empstatus+" "+isActive);
    let updempstatusdata = userService.EditEmpstatus(empstatusid,empstatus,isActive);
    console.log(updempstatusdata);
    updempstatusdata.then(updempstatusdata => {
        console.log(updempstatusdata)

        console.log(updempstatusdata.data)
        if(updempstatusdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_EMPSTATUS',
                updempstatusdata: updempstatusdata.data

            })

        }
    })

};
export const ListUnemployedStatus = (searchval) => dispatch => {
    let listunemployedstatusdata = userService.ListUnemployedStatus(searchval);
    console.log(listunemployedstatusdata);
    listunemployedstatusdata.then(listunemployedstatusdata => {
        console.log(listunemployedstatusdata)

        console.log(listunemployedstatusdata.data)
        if(listunemployedstatusdata.data  !== undefined){
            dispatch({
                type: 'LIST_UNEMPLOYEDSTATUS',
                unemployedstatusdata: listunemployedstatusdata.data

            })

        }
    })

};


export const AddUnemployedstatuses = (title,isActive) => dispatch => {
    console.log(title+" "+isActive);
    let newmstatusdata = userService.AddUnemployedstatuses(title,isActive);
    console.log(newmstatusdata);
    newmstatusdata.then(newmstatusdata => {
        console.log(newmstatusdata)

        console.log(newmstatusdata.data)
        if(newmstatusdata.data  !== undefined){
            dispatch({
                type: 'ADD_UNEMPLOYEDSTATUS',
                addunemployedstatus: newmstatusdata.data

            })

        }
    })

};



export const FetchUnemployedStatus = (id) => dispatch => {
    console.log(id);
    let fetchunemployedstatusdata = userService.FetchUnemployedStatus(id);
    console.log(fetchunemployedstatusdata);
    fetchunemployedstatusdata.then(fetchunemployedstatusdata => {
        console.log(fetchunemployedstatusdata)

        console.log(fetchunemployedstatusdata.data)
        if(fetchunemployedstatusdata.data  !== undefined){
            dispatch({
                type: 'FETCH_UNEMPLOYEDSTATUS',
                fetchunemployedstatusdata: fetchunemployedstatusdata.data

            })

        }
    })

};


export const EditUnemployedStatus = (empstatusid,title,isActive) => dispatch => {
    console.log(empstatusid+" "+title+" "+isActive);
    let updunemployedstatusdata = userService.EditUnemployedStatus(empstatusid,title,isActive);
    console.log(updunemployedstatusdata);
    updunemployedstatusdata.then(updunemployedstatusdata => {
        console.log(updunemployedstatusdata)

        console.log(updunemployedstatusdata.data)
        if(updunemployedstatusdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_UNEMPLOYEDSTATUS',
                updunemployedstatusdata: updunemployedstatusdata.data

            })

        }
    })

};



export const ListUserRolesDropDown = () => dispatch => {
    let listrolesdropdowndata = userService.ListUserRolesDropDown();
    console.log(listrolesdropdowndata);
    listrolesdropdowndata.then(listrolesdropdowndata => {
        console.log(listrolesdropdowndata)

        console.log(listrolesdropdowndata.data)
        if(listrolesdropdowndata.data  !== undefined){
            dispatch({
                type: 'LISTDROPDOWN_ROLES',
                listrolesdropdowndata: listrolesdropdowndata.data

            })

        }
    })

};



export const ListDashboard = (filterType) => dispatch => {
    let dashboaddata = userService.ListDashboard(filterType);
    console.log(dashboaddata);
    dashboaddata.then(dashboad => {
       console.log(dashboad.data)
        if(dashboad.data  !== undefined) {
            dispatch({
                type: 'DASHBOARD',
                dashboarddata: dashboad.data

            })
        }
    })
};
export const ListNewCount = (uid) => dispatch => {
    let listcount = userService.ListNewCount(uid);
    console.log(listcount);
    listcount.then(listcounts => {
       console.log(listcounts.data)
        if(listcounts.data  !== undefined) {
            dispatch({
                type: 'COUNTOFLIST',
                listcounts: listcounts.data

            })
        }
    })
};




export const ListCustdata = (status,urole,uid,perpage,page,inputValues) => dispatch => {
    let listcustdata = userService.ListCustdata(status,urole,uid,perpage,page,inputValues);
    console.log(listcustdata);
    listcustdata.then(listcustdata => {
        console.log(listcustdata)

        console.log(listcustdata.data)
        if(listcustdata.data  !== undefined){
            dispatch({
                type: 'LIST_CUSTOMERS',
                listcustdata: listcustdata.data.data,
                listcustcount: listcustdata.data.count
            })

        }
    })

};
export const ListMoreCustdata = (status,urole,uid,perpage,page,inputValues) => dispatch => {
    let listmorecustdata = userService.ListCustdata(status,urole,uid,perpage,page,inputValues);
    console.log(listmorecustdata);
    listmorecustdata.then(listmorecustdata => {
        console.log(listmorecustdata)

        console.log(listmorecustdata.data)
        if(listmorecustdata.data  !== undefined){
            dispatch({
                type: 'LIST_MORE_CUSTOMERS',
                listmorecustdata: listmorecustdata.data.data,
                listcustcount: listmorecustdata.data.count
            })

        }
    })

};
export const LevelCustList = (level,perpage,page,searchval,searchby,inputValues) => dispatch => {
    let listlevelcust = userService.LevelCustList(level,perpage,page,searchval,searchby,inputValues) ;
    console.log(listlevelcust);
    listlevelcust.then(listlevelcust => {
        console.log(listlevelcust)

        console.log(listlevelcust.data)
        if(listlevelcust.data  !== undefined){
            dispatch({
                type: 'LIST_LEVEL_CUSTOMERS',
                listlevelcust: listlevelcust.data.data,
                listlevelcustcount: listlevelcust.data
            })

        }
    })

};
export const ekycLockUpdates = (recordid,uid,cid) => dispatch => {
    let lockupdates = userService.RecordLockUpdates(recordid,uid,cid) ;
    console.log(lockupdates);
    lockupdates.then(lockupdates => {
        console.log(lockupdates)

        console.log(lockupdates.data)
        if(lockupdates.data  !== undefined){
            dispatch({
                type: 'RECORD_LOCK_UPDATES',
                lockupdates: lockupdates.data,
            })

        }
    })

};
export const updateRecordLockStatus = (recordid,uid,cid,stat) => dispatch => {
    let recLockupdates = userService.UpdateRecordLockStatus(recordid,uid,cid,stat) ;
    console.log(recLockupdates);
    recLockupdates.then(recLockupdates => {
        console.log(recLockupdates)
        console.log(stat)

        console.log(recLockupdates.data)
        if(recLockupdates.data  !== undefined){
            dispatch({
                type: 'RECORD_LOCKSTATUS_UPDATES',
                recLockupdates: recLockupdates.data,
                recLockupdatesStatus: stat,
            })

        }
    })

};

export const LevelCustListMore = (level,perpage,page,searchval,searchby) => dispatch => {
    let listlevelcustmore = userService.LevelCustList(level,perpage,page,searchval,searchby) ;
    console.log(listlevelcustmore);
    listlevelcustmore.then(listlevelcustmore => {
        console.log(listlevelcustmore)

        console.log(listlevelcustmore.data)
        if(listlevelcustmore.data  !== undefined){
            dispatch({
                type: 'LIST_LEVEL_CUSTOMERS_MORE',
                listlevelcustmore: listlevelcustmore.data.data,
                listlevelcustmorecount: listlevelcustmore.data
            })

        }
    })

};





export const stpReports = ($search,$searchval,$searchby,$inputvalues) => dispatch => {
    let stpReportsdata = userService.stpReports($search,$searchval,$searchby,$inputvalues);
    stpReportsdata.then(stpReportdata => {

        if(stpReportdata.data  !== undefined){
            dispatch({
                type: 'STP_REPORTS',
                stpReportdata: stpReportdata.data

            })

        }
    })

};
export const lidReports = ($search,$searchval,$searchby,$inputvalues) => dispatch => {
    let lidReportsdata = userService.lidReports($search,$searchval,$searchby,$inputvalues);
    lidReportsdata.then(lidReportdata => {

        if(lidReportdata.data  !== undefined){
            dispatch({
                type: 'LID_REPORTS',
                lidReportdata: lidReportdata.data

            })

        }
    })

};





export const nonstpReports = ($search,$searchval,$searchby,$inputValues) => dispatch => {
    let nonstpReports = userService.nonstpReports($search,$searchval,$searchby,$inputValues);
    nonstpReports.then(nonstpReportdata => {

        if(nonstpReportdata.data  !== undefined){
            dispatch({
                type: 'NONSTP_REPORTS',
                nonstpReportdata: nonstpReportdata.data

            })

        }
    })

};

export const ListCustdetails = (custid) => dispatch => {
    let listcustdetails = userService.ListCustdetails(custid);
    console.log(listcustdetails);
    listcustdetails.then(listcustdetails => {
        console.log(listcustdetails)

        console.log(listcustdetails.data)
        if(listcustdetails.data  !== undefined){
            dispatch({
                type: 'LIST_CUSTOMERDETAILS',
                listcustdetails: listcustdetails.data

            })

        }
    })

};

export const ListSetting = (search) => dispatch => {
    let listsettingsdata = userService.ListSetting(search);
    console.log(listsettingsdata);
    listsettingsdata.then(listsettingsdata => {
        console.log(listsettingsdata)

        console.log(listsettingsdata.data)
        if(listsettingsdata.data  !== undefined){
            dispatch({
                type: 'LIST_SETTINGS',
                listsettingsdata: listsettingsdata.data

            })

        }
    })

};

export const AddSetting = (key,keyvalue) => dispatch => {
    console.log(key+" "+keyvalue);
    let newsettingsdata = userService.AddSetting(key,keyvalue);
    console.log(newsettingsdata);
    newsettingsdata.then(newsettingsdata => {
        console.log(newsettingsdata)

        console.log(newsettingsdata.data)
        if(newsettingsdata.data  !== undefined){
            dispatch({
                type: 'ADD_SETTINGS',
                newsettingsdata: newsettingsdata.data

            })

        }
    })

};

export const FetchSettings = (id) => dispatch => {
    console.log(id);
    let fetchsettingsdata = userService.FetchSettings(id);
    console.log(fetchsettingsdata);
    fetchsettingsdata.then(fetchsettingsdata => {
        console.log(fetchsettingsdata)

        console.log(fetchsettingsdata.data)
        if(fetchsettingsdata.data  !== undefined){
            dispatch({
                type: 'FETCH_SETTINGS',
                fetchsettingsdata: fetchsettingsdata.data

            })

        }
    })

};

export const EditSettingsData = (sid,key,value) => dispatch => {
    console.log(sid+" "+key+" "+value);
    let updsettingsdata = userService.EditSettingsData(sid,key,value);
    console.log(updsettingsdata);
    updsettingsdata.then(updsettingsdata => {
        console.log(updsettingsdata)

        console.log(updsettingsdata.data)
        if(updsettingsdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_SETTINGS',
                updsettingsdata: updsettingsdata.data

            })

        }
    })

};

export const ListMastersEntry = (status,filterby,perpage,page,type) => dispatch => {
    let listmastersdata = userService.ListMastersEntry(status,filterby,perpage,page,type);
    console.log(listmastersdata);
    listmastersdata.then(listmasterdata => {
        console.log(listmasterdata)

        console.log(listmasterdata.data)
        if(listmasterdata.data  !== undefined){
            dispatch({
                type: 'LIST_MASTERSLOG',
                listmasterdata: listmasterdata.data

            })

        }
    })

};
export const ListMoreMastersEntry = (status,filterby,perpage,page,type) => dispatch => {
    let listmoremastersdata = userService.ListMastersEntry(status,filterby,perpage,page,type);
    console.log(listmoremastersdata);
    listmoremastersdata.then(listmoremasterdata => {
        console.log(listmoremasterdata)

        console.log(listmoremasterdata.data)
        if(listmoremasterdata.data  !== undefined){
            dispatch({
                type: 'LIST_MORE_MASTERSLOG',
                listmoremasterdata: listmoremasterdata.data

            })

        }
    })

};



export const fetchMastersEntry = (id) => dispatch => {
    let selectedmastersdata = userService.fetchMastersEntry(id);
    console.log(selectedmastersdata);
    selectedmastersdata.then(selectedmasterdata => {
        console.log(selectedmasterdata)

        console.log(selectedmasterdata.data)
        if(selectedmasterdata.data  !== undefined){
            dispatch({
                type: 'FECTCH_MASTERSLOG',
                selectedmasterdata: selectedmasterdata.data

            })

        }
    })

};






export const updateStatusMastersLog = (id,status,section,action,user) => dispatch => {
    let updatedmastersdata = userService.updateStatusMastersLog(id,status,section,action,user);
    console.log(updatedmastersdata);
    updatedmastersdata.then(updatedmasterdata => {
        console.log(updatedmasterdata)

        console.log(updatedmasterdata.data)
        if(updatedmasterdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_MASTERSLOG',
                updatedmasterdata: updatedmasterdata.data

            })

        }
    })

};


export const ListStatuses = (searchval) => dispatch => {
    let liststatusesdata = userService.ListStatuses(searchval);
    console.log(liststatusesdata);
    liststatusesdata.then(liststatusesdata => {
        console.log(liststatusesdata)

        console.log(liststatusesdata.data)
        if(liststatusesdata.data  !== undefined){
            dispatch({
                type: 'LIST_STATUS',
                liststatusesdata: liststatusesdata.data

            })

        }
    })

};

export const FetchStatus = (id) => dispatch => {
    console.log(id);
    let fetchstatusdata = userService.FetchStatus(id);
    console.log(fetchstatusdata);
    fetchstatusdata.then(fetchstatusdata => {
        console.log(fetchstatusdata)

        console.log(fetchstatusdata.data)
        if(fetchstatusdata.data  !== undefined){
            dispatch({
                type: 'FETCH_STATUS',
                fetchstatusdata: fetchstatusdata.data

            })

        }
    })

};

export const EditStatusesData = (sid,status) => dispatch => {
    console.log(sid+" "+status);
    let updstatusesdata = userService.EditStatusesData(sid,status);
    console.log(updstatusesdata);
    updstatusesdata.then(updstatusesdata => {
        console.log(updstatusesdata)

        console.log(updstatusesdata.data)
        if(updstatusesdata.data  !== undefined){
            dispatch({
                type: 'UPDATE_STATUS',
                updstatusesdata: updstatusesdata.data

            })

        }
    })

};

export const AddStatuses = (status) => dispatch => {
    console.log(status);
    let newstatusesdata = userService.AddStatuses(status);
    console.log(newstatusesdata);
    newstatusesdata.then(newstatusesdata => {
        console.log(newstatusesdata)

        console.log(newstatusesdata.data)
        if(newstatusesdata.data  !== undefined){
            dispatch({
                type: 'ADD_STATUS',
                newstatusesdata: newstatusesdata.data

            })

        }
    })

};


export const getUploads = (jid) => dispatch => {
    let uploadResps = userService.getUploads(jid) ;
    console.log("get upload response: "+uploadResps);
        uploadResps.then(uploadResp => {
            if(uploadResp.data.message !== undefined){
                console.log("get upload response: "+uploadResp.data.message);
                dispatch({
                    type: 'GET_UPLOAD',
                    uploadRespdata: uploadResp.data

                })
            }
        })
};


export const changeStatusUserRecord = (cid,frmobj) => dispatch => {
    console.log("frmobj: "+frmobj)
    let cInfoResp = userService.updateCustomerStatus(cid,frmobj) ;
    cInfoResp.then(cInfoResp => {
        console.log("Customer status Update response: "+cInfoResp.data);
        dispatch({
            type: 'CUSTOMER_STATUS_UPDATE',
            updatestatusesdata: cInfoResp.data

        })
    })
};


export const ListCountrys = (searchval,searchby) => dispatch => {
    let listcountrydata = userService.ListCountrys(searchval,searchby);
    console.log(listcountrydata);
    listcountrydata.then(listcountrydata => {
        console.log(listcountrydata)

        console.log(listcountrydata.data)
        if(listcountrydata.data  !== undefined){
            dispatch({
                type: 'LIST_COUNTRY',
                listcountrydata: listcountrydata.data

            })

        }
    })

};
export const ListLockRecords = (cif) => dispatch => {
    let listlockdata = userService.ListLockRecords(cif);
    console.log(listlockdata);
    listlockdata.then(listlockdata => {
        console.log(listlockdata)

        console.log(listlockdata.data)
        if(listlockdata.data  !== undefined){
            dispatch({
                type: 'LIST_LOCKRECORD',
                listlockdata: listlockdata.data

            })

        }
    })

};
export const getSystemConfig = (value) => dispatch => {
    let systemconfigdata = userService.getConfigValue(value);
    console.log(systemconfigdata);
    systemconfigdata.then(systemconfigdata => {
        console.log(systemconfigdata)

        console.log(systemconfigdata.data)
        if(systemconfigdata.data  !== undefined){
            dispatch({
                type: 'CONFIG_SETTINGS',
                systemconfigdata: systemconfigdata.data

            })

        }
    })

};

export const FetchCountrys = (id) => dispatch => {
    console.log(id);
    let fetchcountrydata = userService.FetchCountrys(id);
    console.log(fetchcountrydata);
    fetchcountrydata.then(fetchcountrydata => {
        console.log(fetchcountrydata)

        console.log(fetchcountrydata.data)
        if(fetchcountrydata.data  !== undefined){
            dispatch({
                type: 'FETCH_COUNTRY',
                fetchcountrydata: fetchcountrydata.data

            })

        }
    })

};
export const ListRolesectionPermission = (id) => dispatch => {
    console.log(id);
    let rolesectiondata = userService.ListRolesectionPermission(id);
    console.log(rolesectiondata);
    rolesectiondata.then(rolesectiondata => {
        console.log(rolesectiondata)

        console.log(rolesectiondata.data)
        if(rolesectiondata.data  !== undefined){
            dispatch({
                type: 'ROLE_SECTION_PERMISSION',
                rolesectiondata: rolesectiondata.data

            })

        }
    })

};
export const FetchMastersLog = (section,masterid) => dispatch => {
    let fetchmasterslogdata = userService.FetchMastersLog(section,masterid);
    console.log(fetchmasterslogdata);
    fetchmasterslogdata.then(fetchmasterslogdata => {
        console.log(fetchmasterslogdata)

        console.log(fetchmasterslogdata.data)
        if(fetchmasterslogdata.data  !== undefined){
            dispatch({
                type: 'FETCH_MASTERSLOG',
                fetchmasterslogdata: fetchmasterslogdata.data

            })

        }
    })

};
export const setRolesectionPermission = (data,id) => dispatch => {
    let setrolepermission = userService.setRolesectionPermission(data,id);
    console.log(setrolepermission);
    setrolepermission.then(setrolepermission => {
        console.log(setrolepermission)

        console.log(setrolepermission.data)
        if(setrolepermission.data  !== undefined){
            dispatch({
                type: 'SAVE_ROLE_PERMISSION',
                setrolepermission: setrolepermission.data

            })

        }
    })

};
export const ListMainSections = () => dispatch => {
    let listsectiondata = userService.ListMainSections();
    console.log(listsectiondata);
    listsectiondata.then(listsectiondata => {
        console.log(listsectiondata)

        console.log(listsectiondata.data)
        if(listsectiondata.data  !== undefined){
            dispatch({
                type: 'SECTION_LIST',
                listsectiondata: listsectiondata.data

            })

        }
    })

};


export const ListActionTypes = () => dispatch => {
    let listactiontype = userService.ListActionTypes();
    console.log(listactiontype);
    listactiontype.then(listactiontype => {
        console.log(listactiontype)

        console.log(listactiontype.data)
        if(listactiontype.data  !== undefined){
            dispatch({
                type: 'LIST_ACTIONTYPE',
                listactiontype: listactiontype.data

            })

        }
    })

};
export const CreateAccessLog = (cif,sectionmsg,sessionid) => dispatch => {
    let creatactionlogs = userService.createAccessLog(cif,sectionmsg,sessionid);
    console.log(creatactionlogs);
    creatactionlogs.then(creatactionlogs => {
        console.log(creatactionlogs)

        console.log(creatactionlogs.data)
        if(creatactionlogs.data  !== undefined){
            dispatch({
                type: 'CREATE_ACESS_LOG',
                creatactionlogs: creatactionlogs.data

            })

        }
    })

};


