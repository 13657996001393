import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListStatuses,
    FetchStatus,
    EditStatusesData,
    AddPendingApprovals,
    FetchMastersLog, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListStatus = () => {
    const [hasError, setErrors] = useState(false);
    const [modalIn, setModalIn] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [selstatusid, setSelstatusid] = useState('');
    const [selectedmstatus, setSelectedmstatus] = useState([]);
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchval, setSearchVal] = useState('' );
    const [valuesloader, setValuesloader] = useState(false);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {status: '', updatedstatuses: {"status": "pending"}}
    );

    const statusesdata = useSelector(state => state.UserReducer.liststatusesdata) ;
    const selstatusesdata = useSelector(state => state.UserReducer.fetchstatusdata) ;
    const updstatusesdata = useSelector(state => state.UserReducer.updstatusesdata) ;
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        setValuesloader(true);
        dispatch(ListStatuses(searchval)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;
    },[dispatch]);

    useEffect(() => {
        setValuesloader(false);
        setStatuses(statusesdata)
    },[statusesdata]);

    useEffect(() => {
        setInputValues({ "updatedstatuses" : updstatusesdata });
    },[updstatusesdata]);

    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);
    useEffect(() => {

        console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);
            }
        }
    },[newdatarow]);

    useEffect(() => {
        setSelectedmstatus(selstatusesdata)
        if(selstatusesdata.length >0 ){
            setInputValues({ "status" : selstatusesdata[0].status,"updatedstatuses" : {"status": "pending"} });
        }

    },[selstatusesdata]);


    const toggleModal = (id) => {
        console.log(id) ;
        setStatus(false);
        setButtonStatus(false);
        setValuesloader(false);

        if(id == 0){
            dispatch(ListStatuses(searchval)) ;
        }else{
            setSelstatusid(id) ;
            setStatusloader(true);
            dispatch(FetchStatus(id)) ;
            dispatch(FetchMastersLog('Status',id)) ;

        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "status" : '', "updatedstatuses":[]});
    }
    const submitForm = () => {
        //dispatch(EditStatusesData(selstatusid,inputValues.status)) ;
        setStatusloader(true);
        const myObj = {
            "id": selstatusid,
            "status": inputValues.status
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('Status:'+inputValues.status,enc_myObjStr,user,'Update',selstatusid)) ;

    }



    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        setValuesloader(true);
        dispatch( ListStatuses(searchval));
    }

    console.log(statusesdata) ;
    return (
        <React.Fragment>
            <Pagesidebar menu="Status" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar}  searchValChange = {searchValChange} changeNav={changeNav}/>
                </navbar>

                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Statuses</h2>
                        {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                        <Link to="/add-status" className="btn btn--primaryG btn--capsule">(+) Status</Link>
                        }
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Status
                                </th>

                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { statuses && statuses.map((item,index) => {
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.status}
                                        </td>
                                        <td>
                                            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                            <span className="iconEdit" onClick={() => toggleModal(item.id)}/>
                                            }
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Status</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Status</label>
                                <input value={inputValues.status} name="status" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&
                            <div className="flexCC">
                                {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                    <span className="messageIn">Waiting for Approval</span>
                                }
                            </div>
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListStatus
