import {useEffect} from "react";


const Layout = (props) => {
    const userid = localStorage.getItem("AL_userid") ? localStorage.getItem("AL_userid") : '';
    useEffect(() => {
        if(!userid || !document.referrer){
            window.location.href = ('/');
        }
    });
    console.log();
    return (
        <>
        {userid !== '' &&
            props.children
        }
        </>
    )
}

export default Layout
