import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListMstatus,
    FetchMstatusDetails,
    EditMstatusData,
    AddPendingApprovals,
    FetchMastersLog, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";
import Loader from "../components/Loader";

const ListMaritalstatus = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": "1"},{"label": "No", "value": "0"}]);
    const [modalIn, setModalIn] = useState(false);
    const [mstatus, setMstatus] = useState([]);
    const [selmstatusid, setSelmstatusid] = useState('');
    const [selectedmstatus, setSelectedmstatus] = useState([]);

    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [filterlist, setFilterlist] = useState([]);

    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [selmastersdata, setSelMastersdata] = useState([]);
    const [statusloader, setStatusloader] = useState(false);
    const [searchval, setSearchVal] = useState('' );
    const [valuesloader, setValuesloader] = useState(false);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {mstatus: '', selactive:'' , updatedmstatus: {"status": "pending"}}
    );

    const mstatusdata = useSelector(state => state.UserReducer.listmstatusdata) ;
    const selmstatusdata = useSelector(state => state.UserReducer.fetchmstatusdata) ;
    const updmstatusdata = useSelector(state => state.UserReducer.updmstatusdata) ;

    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const selmasterlogdata = useSelector(state => state.UserReducer.fetchmasterslogdata) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;




    let history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(ListMstatus(searchval)) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;
    },[dispatch]);

    useEffect(() => {
        setValuesloader(false);
        setMstatus(mstatusdata)
    },[mstatusdata]);
    useEffect(() => {
        setStatusloader(false);
        setSelMastersdata(selmasterlogdata)
    },[selmasterlogdata]);

    useEffect(() => {
        setInputValues({ "updatedmstatus" : updmstatusdata });
    },[updmstatusdata]);


    useEffect(() => {

        //console.log("resched");
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
                setStatusloader(false);

            }
        }
    },[newdatarow]);


    useEffect(() => {
        setSelectedmstatus(selmstatusdata)
        console.log(selmstatusdata);
        if(selmstatusdata !== undefined){
            let mstatusAr = selmstatusdata[0].MStatus_Ar ? selmstatusdata[0].MStatus_Ar : '';

            setInputValues({ "mstatus" : selmstatusdata[0].MStatus, "mstatusar" : mstatusAr, "selactive" : selmstatusdata[0].IsActive, "updatedmstatus" : {"status": "pending"} });
        }

    },[selmstatusdata]);


    const toggleModal = (id) => {
        console.log(id) ;
        setStatus(false);
        setButtonStatus(false);
        if(id == 0){
            dispatch(ListMstatus(searchval)) ;
        }else{
            setSelmstatusid(id) ;
            setStatusloader(true);
            dispatch(FetchMstatusDetails(id)) ;
            dispatch(FetchMastersLog('MaritalStatus',id)) ;
        }


        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);

    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "mstatus" : '',"mstatusar" : '', "selactive" : '', "updatedmstatus":[]});
    }
    const submitForm = () => {
        //dispatch(EditMstatusData(selmstatusid,inputValues.mstatus,inputValues.selactive)) ;
        setStatusloader(true);
        const myObj = {
            "id": selmstatusid,
            "MStatus": inputValues.mstatus,
            "MStatusAr": inputValues.mstatusar,
            "IsActive":inputValues.selactive
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('MaritalStatus:'+inputValues.mstatus,enc_myObjStr,user,'Update',selmstatusid)) ;

    }


    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }
    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        setValuesloader(true);
        dispatch( ListMstatus(searchval));
    }

    console.log(updmstatusdata) ;
    console.log(inputValues) ;
    return (

        <React.Fragment>
            <Pagesidebar menu="Marital Status" mainmenu="Masters" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Masters"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar} searchValChange = {searchValChange}  changeNav={changeNav}/>
                </navbar>


                <div className="roundBox">
                    {valuesloader &&
                    <Loader/>
                    }
                    <div className="roundBox__head">
                        <h2>Marital Status</h2>
                        {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                        <Link to="/add-maritalstatus" className="btn btn--primaryG btn--capsule">(+) Marital
                            Status</Link>
                        }
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Status in Arabic
                                </th>
                                <th>
                                    Active/Inactive
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            <tbody>
                            { mstatus && mstatus.map((item,index) => {
                                let MStatusAr = item.MStatus_Ar ? item.MStatus_Ar : '';
console.log(MStatusAr);
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.MStatus}
                                        </td>
                                        <td>
                                            {MStatusAr}
                                        </td>
                                        <td>
                                            {item.IsActive === "1" ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>
                                            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '5' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                            <span className="iconEdit" onClick={() => toggleModal(item.id)}/>
                                            }
                                        </td>
                                    </tr>
                                )
                            } ) }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            {statusloader &&
                            <Loader/>
                            }
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit Marital Status</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Status</label>
                                <input value={inputValues.mstatus} name="mstatus" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>
                            <div className="csForm__item">
                                <label className="csForm__item__label">Status in Arabic</label>
                                <input value={inputValues.mstatusar} name="mstatusar" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />


                            { status === "success"  ? (
                                <div> <span className="messageIn">Sent for Approval</span></div>
                            ) : ''
                            }
                            {!btnstatus &&
                            <div className="flexCC">
                                {selmastersdata && selmastersdata.length === 0 ?
                                    <span className="btn btn--primaryG btn--sm btn--capsule"
                                          onClick={() => submitForm()}>UPDATE</span> :
                                    <span className="messageIn">Waiting for Approval</span>
                                }

                            </div>
                            }

                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListMaritalstatus
