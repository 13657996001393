let initialState = {
    Validdata: [],
}

const LoginReducer =  (state = {Validdata:[]}, action) => {
    switch (action.type) {
        case "LOGIN_FAILED":
            console.log(action);
            return {
                ...state,
                Validdata: action.validdataerrors,
            };
            break;

        default:
            return state;
    }
};


export default LoginReducer;
