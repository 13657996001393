import React from 'react';

import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';

const GridView = () => {
    return (
        <div className="container">
            <navbar className="pageNav">
                <div className="pageNav__left">
                    <select className="fieldSelect">
                        <option selected disabled>Select Field</option>
                        <option>Select</option>
                        <option>Select</option>
                        <option>Select</option>
                    </select>
                    <div className="searchBlock">
                        <input type="text" className="searchBlock__input" placeholder="Search something…" />
                        <span className="searchBlock__icon iconIn iconIn--search" />
                    </div>
                </div>
                <div className="pageNav__right">
                    <div className="userBlock">
                        <div className="userBlock__details">
                            <h6 className="userName">Shaheen Abdullah</h6>
                            <p className="userRole">Reviewer</p>
                        </div>
                        <div className="userBlock__image">
                            <img src={user} className="userBlock__image__in" alt="user" />
                        </div>
                    </div>
                </div>
            </navbar>
            <div className="roundBox">
                <div className="roundBox__head">
                    <div className="roundBox__filter">
                        <select className="roundBox__filter__select">
                            <option selected disabled>Filter by Status</option>
                            <option>Select</option>
                            <option>Select</option>
                        </select>

                        <select className="roundBox__filter__select">
                            <option selected disabled>Filter by Date</option>
                            <option>Select</option>
                            <option>Select</option>
                        </select>
                    </div>
                    <a href="#" className="btn btn--primaryG btn--capsule">Export Selected</a>
                </div>
                <div className="gridWrapper">
                    <div className="gridEach">
                        <input type="checkbox" className="gridInput" />
                        <span className="gridInputPlaceholder" />
                        <div className="gridEachBlock">
                            <div className="gridEachImage"></div>
                            <div className="gridEachContent">
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">First Name</label>
                                    <p className="gridEachContentEach__text">Ahmed</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Last Name</label>
                                    <p className="gridEachContentEach__text">Al Sherif</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">CIF</label>
                                    <p className="gridEachContentEach__text">98765432</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Reference ID</label>
                                    <p className="gridEachContentEach__text">REKYC1233344</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Date</label>
                                    <p className="gridEachContentEach__text">23-07-2021</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Time</label>
                                    <p className="gridEachContentEach__text">04:12:48 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gridEach">
                        <input type="checkbox" className="gridInput" />
                        <span className="gridInputPlaceholder" />
                        <div className="gridEachBlock">
                            <div className="gridEachImage"></div>
                            <div className="gridEachContent">
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">First Name</label>
                                    <p className="gridEachContentEach__text">Ahmed</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Last Name</label>
                                    <p className="gridEachContentEach__text">Al Sherif</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">CIF</label>
                                    <p className="gridEachContentEach__text">98765432</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Reference ID</label>
                                    <p className="gridEachContentEach__text">REKYC1233344</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Date</label>
                                    <p className="gridEachContentEach__text">23-07-2021</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Time</label>
                                    <p className="gridEachContentEach__text">04:12:48 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gridEach">
                        <input type="checkbox" className="gridInput" />
                        <span className="gridInputPlaceholder" />
                        <div className="gridEachBlock">
                            <div className="gridEachImage"></div>
                            <div className="gridEachContent">
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">First Name</label>
                                    <p className="gridEachContentEach__text">Ahmed</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Last Name</label>
                                    <p className="gridEachContentEach__text">Al Sherif</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">CIF</label>
                                    <p className="gridEachContentEach__text">98765432</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Reference ID</label>
                                    <p className="gridEachContentEach__text">REKYC1233344</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Date</label>
                                    <p className="gridEachContentEach__text">23-07-2021</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Time</label>
                                    <p className="gridEachContentEach__text">04:12:48 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gridEach">
                        <input type="checkbox" className="gridInput" />
                        <span className="gridInputPlaceholder" />
                        <div className="gridEachBlock">
                            <div className="gridEachImage"></div>
                            <div className="gridEachContent">
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">First Name</label>
                                    <p className="gridEachContentEach__text">Ahmed</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Last Name</label>
                                    <p className="gridEachContentEach__text">Al Sherif</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">CIF</label>
                                    <p className="gridEachContentEach__text">98765432</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Reference ID</label>
                                    <p className="gridEachContentEach__text">REKYC1233344</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Date</label>
                                    <p className="gridEachContentEach__text">23-07-2021</p>
                                </div>
                                <div className="gridEachContentEach">
                                    <label className="gridEachContentEach__label">Time</label>
                                    <p className="gridEachContentEach__text">04:12:48 PM</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GridView
