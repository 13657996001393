import React, {useState} from 'react'

const Selectbox = (props) => {

    const chooseInput = (e) => {
        props.onChange(e.target.value, e.target.name);
    }

    console.log(props);
    return (
        <div className="csForm__item">
            {props.labelStatus === 'No' ?
                <></> :
                <label className="csForm__item__label">{props.label}</label>
            }
            <select className="csForm__item__input" name={props.name}  onChange={chooseInput}>
                {props.labelStatus === 'No' ?
                    <option selected={props.selvalue === "" ? 'selected' : ""} disabled>{props.label}</option> :
                    <option selected={props.selvalue === "" ? 'selected' : ""} disabled>Select</option>
                }
                { props.values && props.values.map(item => {
                    return (
                        <option selected={props.selvalue === item.value ? 'selected' : "" } value={item.value}>{item.label} </option>
                    )
                })}
            </select>
        </div>

    )
}

export default Selectbox ;
