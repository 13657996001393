import React, {useState, useEffect, useReducer} from 'react';
import {Link, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    ListUser,
    FetchUserDetails,
    EditUserData,
    ListUserRolesDropDown,
    AddPendingApprovals,
    LevelCustList, ListRolesectionPermission
} from "../actions/User";
import Checkbox from '../components/Checkbox';
import user from '../assets/images/user.png';
import Sidebar from "../components/Sidebar";
import Selectbox from "../components/Selectbox";
import Pagesidebar from "../components/Pagsidebar";
import Navbar from "../components/Navbar";
import {getToken} from "../utilities/AuthService";

const ListUsers = () => {
    const [hasError, setErrors] = useState(false);
    const [yesnovalue, setYesnovalue] = useState([{"label": "Yes", "value": 1},{"label": "No", "value": 0}]);
    const [searchbyvalue, setSearchbyvalue] = useState([{"label": "User Name", "value": "name"},{"label": "User Email", "value": "email"}]);
    const [modalIn, setModalIn] = useState(false);
    const [users, setUsers] = useState([]);
    const [rolesvalues, setRolesvalues] = useState([]);
    const [seluserid, setSeluserid] = useState('');
    const [selecteduser, setSelecteduser] = useState([]);
    const [user, setUser] = useState(getToken().name);
    const [submit, setSubmit] = useState(false );
    const [status, setStatus] = useState('' );
    const [btnstatus, setButtonStatus] = useState(false );
    const [sidebar, setSidebar] = useState(true);
    const [showsidebar, setShowsidebar] = useState(true);
    const [searchval, setSearchVal] = useState('' );
    const [filterlist, setFilterlist] = useState([]);
    const [searchby, setInputSearchby] = useState('');

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {username: '', useremail:'' , userphone:'' , userempid:'' , userrole:'' , selactive:'' , updateduser: {"status": "pending"}}
    );

    const usersdata = useSelector(state => state.UserReducer.listusersdata) ;
    const rolesdata = useSelector(state => state.UserReducer.listrolesdropdowndata) ;
    const seluserdata = useSelector(state => state.UserReducer.fetchuserdata) ;
   /*  const upduserdata = useSelector(state => state.UserReducer.upduserdata) ;  */
    const newdatarow = useSelector(state => state.UserReducer.newdatarow) ;
    const rolesectiondata = useSelector(state => state.UserReducer.rolesectiondata) ;





    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ListUserRolesDropDown()) ;
        dispatch(ListRolesectionPermission(getToken().roleid)) ;

    },[dispatch]);

    useEffect(() => {
        setRolesvalues(rolesdata)
    },[rolesdata]);

    useEffect(() => {
        dispatch(ListUser(searchval,searchby)) ;
    },[dispatch]);

    useEffect(() => {
        setUsers(usersdata)
    },[usersdata]);


    useEffect(() => {
        if(submit === true){
            if(newdatarow.resp_status !== undefined){
                setStatus(newdatarow.resp_status) ;
                setButtonStatus(true);
            }
        }
    },[newdatarow]);

    useEffect(() => {
      setSelecteduser(seluserdata)
        if(seluserdata.length > 0 ){
            setInputValues({ "username" : seluserdata[0].UserName, "useremail" : seluserdata[0].UserEmail, "userphone" : seluserdata[0].UserPhoneNo, "userempid" : seluserdata[0].UserEmpId, "userrole": seluserdata[0].RoleId, "selactive": seluserdata[0].IsActive, "updateduser" : {"status": "pending"} });
        }

    },[seluserdata]);


    const toggleModal = (uniqueId) => {
        setButtonStatus(false);
        setStatus(false);
        if(uniqueId == 0){
            dispatch(ListUser(searchval,searchby)) ;
            resetForm();
        }else{
            setSeluserid(uniqueId) ;
            dispatch(FetchUserDetails(uniqueId)) ;
        }
        document.getElementById('root').classList.toggle('scrollOut');
        setModalIn(!modalIn);
    }

    const handleInput = (e) =>{
        setInputValues({ [e.target.name]: e.target.value }) ;
    }

    const handleChange = (value,name) =>{
        setInputValues({ [name]: value });
    }

    const resetForm = () => {
        setInputValues({ "username" : '', "useremail" : '', "userphone": '',"userempid" : '', "userrole" : '', "selactive": '', "updatedrole" : [], "updateduser": [] });
    }
    const submitForm = () => {
         const myObj = {
            "UserUniqueId": seluserid,
            "UserName": inputValues.username,
            "UserEmail": inputValues.useremail,
            "UserPhoneNo":inputValues.userphone,
            "UserEmpId": inputValues.userempid,
            "UserRole": inputValues.userrole,
            "IsActive": inputValues.selactive,
            "IsDeleted": inputValues.seldeleted
        };
        const myObjStr = JSON.stringify(myObj);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        setSubmit(true);
        dispatch(AddPendingApprovals('Users',enc_myObjStr,user,'Update',seluserid)) ;
    }


    const changeNav = () =>{
        setShowsidebar(!showsidebar)
    }

    const searchValChange =(searchval)=>{
        setSearchVal(searchval);
        dispatch(ListUser(searchval,searchby)) ;
    }
    const handleFilterChange = (value,name) =>{
        setInputSearchby(value);
    }

    return (
        <React.Fragment>

            <Pagesidebar menu="Users" mainmenu="Administration" showsidebar={showsidebar} />
            <div className="pageSidebarOffset">
                <navbar className="pageNav">
                    <Navbar pagettl="Administration"  showsidebar={showsidebar} filter="No" search="Yes"   filterlist={filterlist} sidebar={sidebar}  searchChange = {handleFilterChange} changeNav={changeNav} searchParam = {searchbyvalue} searchValChange = {searchValChange}/>
                </navbar>

                <div className="roundBox">
                    <div className="roundBox__head">
                        <h2>Users</h2>
                        {/*<Link to="/add-user" className="btn btn--primaryG btn--capsule">(+) User</Link>*/}
                    </div>

                    <div className="tableWrapper scrollable">
                        <table className="csTable">
                            <thead>
                            <tr>
                                <th>

                                    Sl No
                                </th>
                                <th>
                                    User Name
                                </th>
                                <th>
                                    User Email
                                </th>
                                <th>
                                    User Role
                                </th>


                                <th>
                                    Status
                                </th>
                                <th></th>

                            </tr>
                            </thead>
                            { users && users.length > 0 ?
                            <tbody>
                            {users.map((item,index) => {
                                return(
                                    <tr>
                                        <td>

                                            {index+1}
                                        </td>
                                        <td>
                                            {item.UserName}
                                        </td>
                                        <td>
                                            {item.UserEmail}
                                        </td>
                                        <td>
                                            {item.RoleName}
                                        </td>

                                        <td>
                                            {item.IsActive === "1" ? 'Active' : 'Inactive'}
                                        </td>
                                        <td>
                                            {(getToken().role === 'Admin' || (rolesectiondata.length > 0 && rolesectiondata.findIndex(item => item.sectionId === '4' && (item.actionId === '1' || item.actionId === '2')) > -1)) &&
                                            <span className="iconEdit" onClick={() => toggleModal(item.UserUniqueId)}/>
                                            }
                                            </td>
                                    </tr>
                                )
                            } ) }

                            </tbody> :
                            <tbody><tr><td colSpan="10" style={{textAlign:'center'}} >No record found</td></tr></tbody>
}
                        </table>
                    </div>
                </div>
            </div>
            {
                modalIn && (

                    <div className={`csModal csModal--sm csModal--active`}>
                        <div className="csModal__content">
                            <span className="csModal__out" onClick={() => toggleModal('0')}/>

                            <h3>Edit User</h3>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Name</label>
                                <input value={inputValues.username} name="username" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <div className="csForm__item">
                                <label className="csForm__item__label">Email</label>
                                <input value={inputValues.useremail} name="useremail" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <div className="csForm__item">
                                <label className="csForm__item__label">Phone Number</label>
                                <input value={inputValues.userphone} name="userphone" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>


                            <div className="csForm__item">
                                <label className="csForm__item__label">Employee ID</label>
                                <input value={inputValues.userempid} name="userempid" className="csForm__item__input" onChange={handleInput} type="text" />

                            </div>

                            <Selectbox  label="Role" name="userrole"  values={rolesvalues}   onChange={handleChange}  selvalue={inputValues.userrole} />

                            <Selectbox  label="Is Active" name="selactive"  values={yesnovalue}   onChange={handleChange}  selvalue={inputValues.selactive} />
                            <Selectbox  label="Is Deleted"  name="seldeleted" values={yesnovalue}  onChange={handleChange} selvalue={inputValues.seldeleted}  />



                            { status === "success"  ? (
                                <div> <span className="messageIn">Successfully updated the User</span></div>
                            ) : ''
                            }

                            {!btnstatus &&
                            <div className="flexCC">

                                <span className="btn btn--primaryG btn--sm btn--capsule"
                                      onClick={() => submitForm()}>UPDATE</span>


                            </div>
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default ListUsers
