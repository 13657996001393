import React, {Component} from 'react';
import IdleTimer from 'react-idle-timer';
import {getToken} from "../utilities/AuthService";
import * as userAction from "../actions/User";
import * as userAuth from "../actions/Authorization";

import {withRouter} from "react-router";
import {connect} from "react-redux";
import {UserLogout} from "../actions/Authorization";


// eslint-disable-next-line react-hooks/rules-of-hooks

export class IdleTimerComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            timeout: 1000 * 60 ,
            Newtimeout: 1000 * 60 ,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.systemconfig !== prevProps.systemconfig) {

            let settime = this.props.systemconfigdata && this.props.systemconfigdata;
            let actuallTime = settime && parseInt(settime)/2;
            let newTime = this.state.timeout * actuallTime
            this.setState({Newtimeout: newTime})

        }
        }
    _onAction(e) {

        console.log(this.props.custdetails);
        console.log(this.props.type);
        if(this.props.type == 'logout'){
            this.setState({isTimedOut: false})
        }else {
            this.setState({isTimedOut: false})
            let custDet = this.props.custdetails !== undefined ? this.props.custdetails : [];
            if (custDet.length > 0) {
                let uid = getToken().id;
                let rid = custDet[0].cust_refno;
                let cid = custDet[0].cust_cif;
                console.log(this.props.backtopage)
                if (!this.props.backtopage) {
                    this.props.updateRecordStatus(rid, uid, cid, '1');
                } else {
                    this.props.updateRecordStatus(rid, uid, cid, '0');
                }
            }
        }

    }

    _onActive(e) {
        this.setState({isTimedOut: false})

    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        if(this.props.type == 'logout'){
            console.log(isTimedOut);
            if (isTimedOut) {
                window.onbeforeunload = null;
                this.props.userLogout();
            } else {
                this.idleTimer.reset();
                this.setState({isTimedOut: true})
            }
        }else {
            if (isTimedOut) {
                window.onbeforeunload = null;
                let custDet = this.props.custdetails!== undefined ? this.props.custdetails : [];

                let uid = getToken().id;
                let rid = custDet[0].cust_refno;
                let cid = custDet[0].cust_cif;

                // eslint-disable-next-line react-hooks/rules-of-hooks
                this.props.updateRecordStatus(rid, uid, cid, '0');
                this.props.history.goBack();

            } else {
                this.idleTimer.reset();
                this.setState({isTimedOut: true})
            }
        }
    }
    render() {


        let settime = this.props.systemconfigdata && this.props.systemconfigdata;
console.log(this.state.Newtimeout);

        return (
            <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.Newtimeout } />
        )
    }
}
const mapStateToProps = state => ({
    systemconfig: state.UserReducer.systemconfigdata

});

export default withRouter(connect(mapStateToProps, {
    updateRecordStatus: userAction.updateRecordLockStatus,
    userLogout: userAuth.UserLogout

})(IdleTimerComponent));